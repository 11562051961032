import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b72d7860 = () => interopDefault(import('@/pages/auth.vue' /* webpackChunkName: "" */))
const _4a2e35f4 = () => interopDefault(import('@/pages/brands.vue' /* webpackChunkName: "" */))
const _cda11512 = () => interopDefault(import('@/pages/brands/index.vue' /* webpackChunkName: "" */))
const _49aa289a = () => interopDefault(import('@/pages/brands/_brand.vue' /* webpackChunkName: "" */))
const _4f6e6548 = () => interopDefault(import('@/pages/cart.vue' /* webpackChunkName: "" */))
const _94baa6be = () => interopDefault(import('@/pages/catalog.vue' /* webpackChunkName: "" */))
const _7c897664 = () => interopDefault(import('@/pages/catalog/index.vue' /* webpackChunkName: "" */))
const _c6292724 = () => interopDefault(import('@/pages/checkout.vue' /* webpackChunkName: "" */))
const _a19a4550 = () => interopDefault(import('@/pages/checkout/products/index.vue' /* webpackChunkName: "" */))
const _5ec5a85c = () => interopDefault(import('@/pages/checkout/services/index.vue' /* webpackChunkName: "" */))
const _4e89fb61 = () => interopDefault(import('@/pages/main.vue' /* webpackChunkName: "" */))
const _40958bdf = () => interopDefault(import('@/pages/favorites.vue' /* webpackChunkName: "" */))
const _186a4330 = () => interopDefault(import('@/pages/giftcard.vue' /* webpackChunkName: "" */))
const _e693c21a = () => interopDefault(import('@/pages/lookbooks.vue' /* webpackChunkName: "" */))
const _1d033994 = () => interopDefault(import('@/pages/lookbooks/index.vue' /* webpackChunkName: "" */))
const _1ee46d4b = () => interopDefault(import('@/pages/lookbooks/_lookbook.vue' /* webpackChunkName: "" */))
const _077ae39e = () => interopDefault(import('@/pages/profile.vue' /* webpackChunkName: "" */))
const _2b7debf4 = () => interopDefault(import('@/pages/profile/index.vue' /* webpackChunkName: "" */))
const _b952b572 = () => interopDefault(import('@/pages/profile/addresses/index.vue' /* webpackChunkName: "" */))
const _0ea05855 = () => interopDefault(import('@/pages/profile/bill.vue' /* webpackChunkName: "" */))
const _d8c4091c = () => interopDefault(import('@/pages/profile/bonuses/index.vue' /* webpackChunkName: "" */))
const _4c05ad8a = () => interopDefault(import('@/pages/profile/certificates.vue' /* webpackChunkName: "" */))
const _8f09428e = () => interopDefault(import('@/pages/profile/commission.vue' /* webpackChunkName: "" */))
const _7b38ddda = () => interopDefault(import('@/pages/profile/documents.vue' /* webpackChunkName: "" */))
const _5380ffcc = () => interopDefault(import('@/pages/profile/messages.vue' /* webpackChunkName: "" */))
const _7bce0b1d = () => interopDefault(import('@/pages/profile/messages/index.vue' /* webpackChunkName: "" */))
const _3eae30c5 = () => interopDefault(import('@/pages/profile/messages/_id.vue' /* webpackChunkName: "" */))
const _487882b3 = () => interopDefault(import('@/pages/profile/orders.vue' /* webpackChunkName: "" */))
const _0956f6f6 = () => interopDefault(import('@/pages/profile/orders/index.vue' /* webpackChunkName: "" */))
const _11023ede = () => interopDefault(import('@/pages/profile/orders/_id.vue' /* webpackChunkName: "" */))
const _7d4fa6e1 = () => interopDefault(import('@/pages/profile/orders/_id/index.vue' /* webpackChunkName: "" */))
const _5270e231 = () => interopDefault(import('@/pages/profile/orders/_id/return.vue' /* webpackChunkName: "" */))
const _150c00c3 = () => interopDefault(import('@/pages/profile/orders/_id/update-return.vue' /* webpackChunkName: "" */))
const _7e603a9d = () => interopDefault(import('@/pages/profile/preferences/index.vue' /* webpackChunkName: "" */))
const _59c15b9a = () => interopDefault(import('@/pages/profile/promo-products/index.vue' /* webpackChunkName: "" */))
const _568b59f0 = () => interopDefault(import('@/pages/profile/promocodes/index.vue' /* webpackChunkName: "" */))
const _220c3918 = () => interopDefault(import('@/pages/profile/promo/index.vue' /* webpackChunkName: "" */))
const _7b7274e0 = () => interopDefault(import('@/pages/profile/referal/index.vue' /* webpackChunkName: "" */))
const _49ef5d36 = () => interopDefault(import('@/pages/profile/returns.vue' /* webpackChunkName: "" */))
const _40697828 = () => interopDefault(import('@/pages/profile/returns/index.vue' /* webpackChunkName: "" */))
const _24c76490 = () => interopDefault(import('@/pages/profile/returns/_id.vue' /* webpackChunkName: "" */))
const _100b81da = () => interopDefault(import('@/pages/profile/returns/_id/index.vue' /* webpackChunkName: "" */))
const _3462607e = () => interopDefault(import('@/pages/profile/returns/_id/confirm.vue' /* webpackChunkName: "" */))
const _4e09d0e2 = () => interopDefault(import('@/pages/profile/returns/_id/create-return.vue' /* webpackChunkName: "" */))
const _c7469bd0 = () => interopDefault(import('@/pages/profile/subscriptions.vue' /* webpackChunkName: "" */))
const _f3bacf0a = () => interopDefault(import('@/pages/profile/referal/_id/index.vue' /* webpackChunkName: "" */))
const _3261ed6c = () => interopDefault(import('@/pages/promos.vue' /* webpackChunkName: "" */))
const _a12c9a22 = () => interopDefault(import('@/pages/promos/index.vue' /* webpackChunkName: "" */))
const _843201f4 = () => interopDefault(import('@/pages/promos/_promo/index.vue' /* webpackChunkName: "" */))
const _6643b060 = () => interopDefault(import('@/pages/search.vue' /* webpackChunkName: "" */))
const _5c3de386 = () => interopDefault(import('@/pages/services.vue' /* webpackChunkName: "" */))
const _65572d89 = () => interopDefault(import('@/pages/services/index.vue' /* webpackChunkName: "" */))
const _4347fb12 = () => interopDefault(import('@/pages/services/_service' /* webpackChunkName: "" */))
const _b7148c0e = () => interopDefault(import('@/pages/sets.vue' /* webpackChunkName: "" */))
const _bd79bc88 = () => interopDefault(import('@/pages/sets/index.vue' /* webpackChunkName: "" */))
const _11d2e329 = () => interopDefault(import('@/pages/sets/_set.vue' /* webpackChunkName: "" */))
const _6e2cf272 = () => interopDefault(import('@/pages/referrer.vue' /* webpackChunkName: "" */))
const _40dd6f04 = () => interopDefault(import('@/pages/socialLogin.vue' /* webpackChunkName: "" */))
const _60214522 = () => interopDefault(import('@/pages/thank-you/_id.vue' /* webpackChunkName: "" */))
const _1c397fe4 = () => interopDefault(import('@/pages/catalog/_product.vue' /* webpackChunkName: "" */))
const _6273ab92 = () => interopDefault(import('@/pages/_slug.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/auth",
    component: _b72d7860,
    name: "auth___ru"
  }, {
    path: "/brands",
    component: _4a2e35f4,
    children: [{
      path: "",
      component: _cda11512,
      name: "brands___ru"
    }, {
      path: ":entityCode",
      component: _49aa289a,
      children: [{
        path: "filters/*",
        component: _49aa289a,
        name: "brands.brand.filters___ru"
      }, {
        path: ":category",
        component: _49aa289a,
        name: "brands.brand.category___ru",
        children: [{
          path: "filters/*",
          component: _49aa289a,
          name: "brands.brand.category.filters___ru"
        }]
      }]
    }]
  }, {
    path: "/cart",
    component: _4f6e6548,
    name: "cart___ru"
  }, {
    path: "/catalog",
    component: _94baa6be,
    children: [{
      path: "",
      component: _7c897664,
      name: "catalog___ru"
    }, {
      path: "filters/*",
      component: _7c897664,
      name: "catalog.filters___ru"
    }]
  }, {
    path: "/checkout",
    component: _c6292724,
    meta: {"availableAuthorized":true},
    children: [{
      path: "/checkout/products",
      component: _a19a4550,
      name: "checkout.products___ru"
    }, {
      path: "/checkout/services",
      component: _5ec5a85c,
      name: "checkout.services___ru"
    }]
  }, {
    path: "/en",
    component: _4e89fb61,
    name: "main___en"
  }, {
    path: "/favorites",
    component: _40958bdf,
    meta: {"availableAuthorized":true},
    name: "favorites___ru"
  }, {
    path: "/giftcard",
    component: _186a4330,
    name: "giftcard___ru"
  }, {
    path: "/lookbooks",
    component: _e693c21a,
    children: [{
      path: "",
      component: _1d033994,
      name: "lookbooks___ru"
    }, {
      path: ":lookbook",
      component: _1ee46d4b,
      name: "lookbooks.lookbook___ru"
    }]
  }, {
    path: "/oz",
    component: _4e89fb61,
    name: "main___oz"
  }, {
    path: "/profile",
    component: _077ae39e,
    meta: {"availableAuthorized":true},
    children: [{
      path: "",
      component: _2b7debf4,
      name: "profile___ru"
    }, {
      path: "addresses",
      component: _b952b572,
      name: "profile.addresses___ru"
    }, {
      path: "bill",
      component: _0ea05855,
      meta: {"isReferalPartnersOnlyRoute":true},
      name: "profile.pro-bill___ru"
    }, {
      path: "bonuses",
      component: _d8c4091c,
      children: [{
        path: "",
        component: _d8c4091c,
        name: "profile.bonuses___ru"
      }]
    }, {
      path: "certificates",
      component: _4c05ad8a,
      name: "profile.certificates___ru"
    }, {
      path: "commission",
      component: _8f09428e,
      meta: {"isReferalPartnersOnlyRoute":true},
      name: "profile.pro-commission___ru"
    }, {
      path: "documents",
      component: _7b38ddda,
      name: "profile.documents___ru"
    }, {
      path: "messages",
      component: _5380ffcc,
      children: [{
        path: "",
        component: _7bce0b1d,
        name: "profile.messages___ru"
      }, {
        path: ":id",
        component: _3eae30c5,
        name: "profile.chat___ru"
      }]
    }, {
      path: "orders",
      component: _487882b3,
      children: [{
        path: "",
        component: _0956f6f6,
        name: "profile.orders___ru"
      }, {
        path: ":id",
        component: _11023ede,
        children: [{
          path: "",
          component: _7d4fa6e1,
          name: "profile.order___ru"
        }, {
          path: "return",
          component: _5270e231,
          name: "profile.order.return___ru"
        }, {
          path: "update-return",
          component: _150c00c3,
          name: "profile.order.update-return___ru"
        }]
      }]
    }, {
      path: "preferences",
      component: _7e603a9d,
      name: "profile.preferences___ru",
      children: [{
        path: ":name",
        component: _7e603a9d,
        meta: {"isReferalPartnersOnlyRoute":true},
        name: "profile.pro-preferences___ru"
      }]
    }, {
      path: "promo-products",
      component: _59c15b9a,
      meta: {"isReferalPartnersOnlyRoute":true},
      name: "profile.promo-products___ru"
    }, {
      path: "promocodes",
      component: _568b59f0,
      meta: {"isReferalPartnersOnlyRoute":true},
      name: "profile.pro-promocodes___ru"
    }, {
      path: "promopage",
      component: _220c3918,
      meta: {"isReferalPartnersOnlyRoute":true},
      name: "profile.pro-promo___ru"
    }, {
      path: "referal",
      component: _7b7274e0,
      meta: {"samePagePosition":true,"isReferalPartnersOnlyRoute":true},
      name: "profile.referal___ru"
    }, {
      path: "returns",
      component: _49ef5d36,
      children: [{
        path: "",
        component: _40697828,
        name: "profile.returns___ru"
      }, {
        path: ":id",
        component: _24c76490,
        children: [{
          path: "",
          component: _100b81da,
          name: "profile.return___ru"
        }, {
          path: "confirm",
          component: _3462607e,
          name: "profile.return.confirm___ru"
        }, {
          path: "create-return",
          component: _4e09d0e2,
          name: "profile.return.create-return___ru"
        }]
      }]
    }, {
      path: "subscriptions",
      component: _c7469bd0,
      name: "profile.subscriptions___ru"
    }, {
      path: "referal/:id",
      component: _f3bacf0a,
      meta: {"samePagePosition":true,"isReferalPartnersOnlyRoute":true},
      name: "profile.referal.id___ru"
    }]
  }, {
    path: "/promo",
    component: _3261ed6c,
    children: [{
      path: "",
      component: _a12c9a22,
      name: "promos___ru"
    }, {
      path: ":entityCode",
      component: _843201f4,
      name: "promos.promo___ru"
    }]
  }, {
    path: "/search",
    component: _6643b060,
    children: [{
      path: "",
      component: _6643b060,
      name: "search___ru"
    }, {
      path: "filters/*",
      component: _6643b060,
      name: "search.filters___ru"
    }, {
      path: ":category",
      component: _6643b060,
      children: [{
        path: "",
        component: _6643b060,
        name: "search.category___ru"
      }, {
        path: "filters/*",
        component: _6643b060,
        name: "search.category.filters___ru"
      }]
    }]
  }, {
    path: "/services",
    component: _5c3de386,
    children: [{
      path: "",
      component: _65572d89,
      name: "services___ru"
    }, {
      path: ":service",
      component: _4347fb12,
      name: "services.service___ru"
    }]
  }, {
    path: "/sets",
    component: _b7148c0e,
    children: [{
      path: "",
      component: _bd79bc88,
      name: "sets___ru"
    }, {
      path: ":entityCode",
      component: _11d2e329,
      name: "sets.set___ru"
    }]
  }, {
    path: "/uz",
    component: _4e89fb61,
    name: "main___uz"
  }, {
    path: "/en/auth",
    component: _b72d7860,
    name: "auth___en"
  }, {
    path: "/en/brands",
    component: _4a2e35f4,
    children: [{
      path: "",
      component: _cda11512,
      name: "brands___en"
    }, {
      path: ":entityCode",
      component: _49aa289a,
      children: [{
        path: "filters/*",
        component: _49aa289a,
        name: "brands.brand.filters___en"
      }, {
        path: ":category",
        component: _49aa289a,
        name: "brands.brand.category___en",
        children: [{
          path: "filters/*",
          component: _49aa289a,
          name: "brands.brand.category.filters___en"
        }]
      }]
    }]
  }, {
    path: "/en/cart",
    component: _4f6e6548,
    name: "cart___en"
  }, {
    path: "/en/catalog",
    component: _94baa6be,
    children: [{
      path: "",
      component: _7c897664,
      name: "catalog___en"
    }, {
      path: "filters/*",
      component: _7c897664,
      name: "catalog.filters___en"
    }]
  }, {
    path: "/en/checkout",
    component: _c6292724,
    meta: {"availableAuthorized":true},
    children: [{
      path: "/en/checkout/products",
      component: _a19a4550,
      name: "checkout.products___en"
    }, {
      path: "/en/checkout/services",
      component: _5ec5a85c,
      name: "checkout.services___en"
    }]
  }, {
    path: "/en/favorites",
    component: _40958bdf,
    meta: {"availableAuthorized":true},
    name: "favorites___en"
  }, {
    path: "/en/giftcard",
    component: _186a4330,
    name: "giftcard___en"
  }, {
    path: "/en/lookbooks",
    component: _e693c21a,
    children: [{
      path: "",
      component: _1d033994,
      name: "lookbooks___en"
    }, {
      path: ":lookbook",
      component: _1ee46d4b,
      name: "lookbooks.lookbook___en"
    }]
  }, {
    path: "/en/profile",
    component: _077ae39e,
    meta: {"availableAuthorized":true},
    children: [{
      path: "",
      component: _2b7debf4,
      name: "profile___en"
    }, {
      path: "addresses",
      component: _b952b572,
      name: "profile.addresses___en"
    }, {
      path: "bill",
      component: _0ea05855,
      meta: {"isReferalPartnersOnlyRoute":true},
      name: "profile.pro-bill___en"
    }, {
      path: "bonuses",
      component: _d8c4091c,
      children: [{
        path: "",
        component: _d8c4091c,
        name: "profile.bonuses___en"
      }]
    }, {
      path: "certificates",
      component: _4c05ad8a,
      name: "profile.certificates___en"
    }, {
      path: "commission",
      component: _8f09428e,
      meta: {"isReferalPartnersOnlyRoute":true},
      name: "profile.pro-commission___en"
    }, {
      path: "documents",
      component: _7b38ddda,
      name: "profile.documents___en"
    }, {
      path: "messages",
      component: _5380ffcc,
      children: [{
        path: "",
        component: _7bce0b1d,
        name: "profile.messages___en"
      }, {
        path: ":id",
        component: _3eae30c5,
        name: "profile.chat___en"
      }]
    }, {
      path: "orders",
      component: _487882b3,
      children: [{
        path: "",
        component: _0956f6f6,
        name: "profile.orders___en"
      }, {
        path: ":id",
        component: _11023ede,
        children: [{
          path: "",
          component: _7d4fa6e1,
          name: "profile.order___en"
        }, {
          path: "return",
          component: _5270e231,
          name: "profile.order.return___en"
        }, {
          path: "update-return",
          component: _150c00c3,
          name: "profile.order.update-return___en"
        }]
      }]
    }, {
      path: "preferences",
      component: _7e603a9d,
      name: "profile.preferences___en",
      children: [{
        path: ":name",
        component: _7e603a9d,
        meta: {"isReferalPartnersOnlyRoute":true},
        name: "profile.pro-preferences___en"
      }]
    }, {
      path: "promo-products",
      component: _59c15b9a,
      meta: {"isReferalPartnersOnlyRoute":true},
      name: "profile.promo-products___en"
    }, {
      path: "promocodes",
      component: _568b59f0,
      meta: {"isReferalPartnersOnlyRoute":true},
      name: "profile.pro-promocodes___en"
    }, {
      path: "promopage",
      component: _220c3918,
      meta: {"isReferalPartnersOnlyRoute":true},
      name: "profile.pro-promo___en"
    }, {
      path: "referal",
      component: _7b7274e0,
      meta: {"samePagePosition":true,"isReferalPartnersOnlyRoute":true},
      name: "profile.referal___en"
    }, {
      path: "returns",
      component: _49ef5d36,
      children: [{
        path: "",
        component: _40697828,
        name: "profile.returns___en"
      }, {
        path: ":id",
        component: _24c76490,
        children: [{
          path: "",
          component: _100b81da,
          name: "profile.return___en"
        }, {
          path: "confirm",
          component: _3462607e,
          name: "profile.return.confirm___en"
        }, {
          path: "create-return",
          component: _4e09d0e2,
          name: "profile.return.create-return___en"
        }]
      }]
    }, {
      path: "subscriptions",
      component: _c7469bd0,
      name: "profile.subscriptions___en"
    }, {
      path: "referal/:id",
      component: _f3bacf0a,
      meta: {"samePagePosition":true,"isReferalPartnersOnlyRoute":true},
      name: "profile.referal.id___en"
    }]
  }, {
    path: "/en/promo",
    component: _3261ed6c,
    children: [{
      path: "",
      component: _a12c9a22,
      name: "promos___en"
    }, {
      path: ":entityCode",
      component: _843201f4,
      name: "promos.promo___en"
    }]
  }, {
    path: "/en/search",
    component: _6643b060,
    children: [{
      path: "",
      component: _6643b060,
      name: "search___en"
    }, {
      path: "filters/*",
      component: _6643b060,
      name: "search.filters___en"
    }, {
      path: ":category",
      component: _6643b060,
      children: [{
        path: "",
        component: _6643b060,
        name: "search.category___en"
      }, {
        path: "filters/*",
        component: _6643b060,
        name: "search.category.filters___en"
      }]
    }]
  }, {
    path: "/en/services",
    component: _5c3de386,
    children: [{
      path: "",
      component: _65572d89,
      name: "services___en"
    }, {
      path: ":service",
      component: _4347fb12,
      name: "services.service___en"
    }]
  }, {
    path: "/en/sets",
    component: _b7148c0e,
    children: [{
      path: "",
      component: _bd79bc88,
      name: "sets___en"
    }, {
      path: ":entityCode",
      component: _11d2e329,
      name: "sets.set___en"
    }]
  }, {
    path: "/oz/auth",
    component: _b72d7860,
    name: "auth___oz"
  }, {
    path: "/oz/brands",
    component: _4a2e35f4,
    children: [{
      path: "",
      component: _cda11512,
      name: "brands___oz"
    }, {
      path: ":entityCode",
      component: _49aa289a,
      children: [{
        path: "filters/*",
        component: _49aa289a,
        name: "brands.brand.filters___oz"
      }, {
        path: ":category",
        component: _49aa289a,
        name: "brands.brand.category___oz",
        children: [{
          path: "filters/*",
          component: _49aa289a,
          name: "brands.brand.category.filters___oz"
        }]
      }]
    }]
  }, {
    path: "/oz/cart",
    component: _4f6e6548,
    name: "cart___oz"
  }, {
    path: "/oz/catalog",
    component: _94baa6be,
    children: [{
      path: "",
      component: _7c897664,
      name: "catalog___oz"
    }, {
      path: "filters/*",
      component: _7c897664,
      name: "catalog.filters___oz"
    }]
  }, {
    path: "/oz/checkout",
    component: _c6292724,
    meta: {"availableAuthorized":true},
    children: [{
      path: "/oz/checkout/products",
      component: _a19a4550,
      name: "checkout.products___oz"
    }, {
      path: "/oz/checkout/services",
      component: _5ec5a85c,
      name: "checkout.services___oz"
    }]
  }, {
    path: "/oz/favorites",
    component: _40958bdf,
    meta: {"availableAuthorized":true},
    name: "favorites___oz"
  }, {
    path: "/oz/giftcard",
    component: _186a4330,
    name: "giftcard___oz"
  }, {
    path: "/oz/lookbooks",
    component: _e693c21a,
    children: [{
      path: "",
      component: _1d033994,
      name: "lookbooks___oz"
    }, {
      path: ":lookbook",
      component: _1ee46d4b,
      name: "lookbooks.lookbook___oz"
    }]
  }, {
    path: "/oz/profile",
    component: _077ae39e,
    meta: {"availableAuthorized":true},
    children: [{
      path: "",
      component: _2b7debf4,
      name: "profile___oz"
    }, {
      path: "addresses",
      component: _b952b572,
      name: "profile.addresses___oz"
    }, {
      path: "bill",
      component: _0ea05855,
      meta: {"isReferalPartnersOnlyRoute":true},
      name: "profile.pro-bill___oz"
    }, {
      path: "bonuses",
      component: _d8c4091c,
      children: [{
        path: "",
        component: _d8c4091c,
        name: "profile.bonuses___oz"
      }]
    }, {
      path: "certificates",
      component: _4c05ad8a,
      name: "profile.certificates___oz"
    }, {
      path: "commission",
      component: _8f09428e,
      meta: {"isReferalPartnersOnlyRoute":true},
      name: "profile.pro-commission___oz"
    }, {
      path: "documents",
      component: _7b38ddda,
      name: "profile.documents___oz"
    }, {
      path: "messages",
      component: _5380ffcc,
      children: [{
        path: "",
        component: _7bce0b1d,
        name: "profile.messages___oz"
      }, {
        path: ":id",
        component: _3eae30c5,
        name: "profile.chat___oz"
      }]
    }, {
      path: "orders",
      component: _487882b3,
      children: [{
        path: "",
        component: _0956f6f6,
        name: "profile.orders___oz"
      }, {
        path: ":id",
        component: _11023ede,
        children: [{
          path: "",
          component: _7d4fa6e1,
          name: "profile.order___oz"
        }, {
          path: "return",
          component: _5270e231,
          name: "profile.order.return___oz"
        }, {
          path: "update-return",
          component: _150c00c3,
          name: "profile.order.update-return___oz"
        }]
      }]
    }, {
      path: "preferences",
      component: _7e603a9d,
      name: "profile.preferences___oz",
      children: [{
        path: ":name",
        component: _7e603a9d,
        meta: {"isReferalPartnersOnlyRoute":true},
        name: "profile.pro-preferences___oz"
      }]
    }, {
      path: "promo-products",
      component: _59c15b9a,
      meta: {"isReferalPartnersOnlyRoute":true},
      name: "profile.promo-products___oz"
    }, {
      path: "promocodes",
      component: _568b59f0,
      meta: {"isReferalPartnersOnlyRoute":true},
      name: "profile.pro-promocodes___oz"
    }, {
      path: "promopage",
      component: _220c3918,
      meta: {"isReferalPartnersOnlyRoute":true},
      name: "profile.pro-promo___oz"
    }, {
      path: "referal",
      component: _7b7274e0,
      meta: {"samePagePosition":true,"isReferalPartnersOnlyRoute":true},
      name: "profile.referal___oz"
    }, {
      path: "returns",
      component: _49ef5d36,
      children: [{
        path: "",
        component: _40697828,
        name: "profile.returns___oz"
      }, {
        path: ":id",
        component: _24c76490,
        children: [{
          path: "",
          component: _100b81da,
          name: "profile.return___oz"
        }, {
          path: "confirm",
          component: _3462607e,
          name: "profile.return.confirm___oz"
        }, {
          path: "create-return",
          component: _4e09d0e2,
          name: "profile.return.create-return___oz"
        }]
      }]
    }, {
      path: "subscriptions",
      component: _c7469bd0,
      name: "profile.subscriptions___oz"
    }, {
      path: "referal/:id",
      component: _f3bacf0a,
      meta: {"samePagePosition":true,"isReferalPartnersOnlyRoute":true},
      name: "profile.referal.id___oz"
    }]
  }, {
    path: "/oz/promo",
    component: _3261ed6c,
    children: [{
      path: "",
      component: _a12c9a22,
      name: "promos___oz"
    }, {
      path: ":entityCode",
      component: _843201f4,
      name: "promos.promo___oz"
    }]
  }, {
    path: "/oz/search",
    component: _6643b060,
    children: [{
      path: "",
      component: _6643b060,
      name: "search___oz"
    }, {
      path: "filters/*",
      component: _6643b060,
      name: "search.filters___oz"
    }, {
      path: ":category",
      component: _6643b060,
      children: [{
        path: "",
        component: _6643b060,
        name: "search.category___oz"
      }, {
        path: "filters/*",
        component: _6643b060,
        name: "search.category.filters___oz"
      }]
    }]
  }, {
    path: "/oz/services",
    component: _5c3de386,
    children: [{
      path: "",
      component: _65572d89,
      name: "services___oz"
    }, {
      path: ":service",
      component: _4347fb12,
      name: "services.service___oz"
    }]
  }, {
    path: "/oz/sets",
    component: _b7148c0e,
    children: [{
      path: "",
      component: _bd79bc88,
      name: "sets___oz"
    }, {
      path: ":entityCode",
      component: _11d2e329,
      name: "sets.set___oz"
    }]
  }, {
    path: "/uz/auth",
    component: _b72d7860,
    name: "auth___uz"
  }, {
    path: "/uz/brands",
    component: _4a2e35f4,
    children: [{
      path: "",
      component: _cda11512,
      name: "brands___uz"
    }, {
      path: ":entityCode",
      component: _49aa289a,
      children: [{
        path: "filters/*",
        component: _49aa289a,
        name: "brands.brand.filters___uz"
      }, {
        path: ":category",
        component: _49aa289a,
        name: "brands.brand.category___uz",
        children: [{
          path: "filters/*",
          component: _49aa289a,
          name: "brands.brand.category.filters___uz"
        }]
      }]
    }]
  }, {
    path: "/uz/cart",
    component: _4f6e6548,
    name: "cart___uz"
  }, {
    path: "/uz/catalog",
    component: _94baa6be,
    children: [{
      path: "",
      component: _7c897664,
      name: "catalog___uz"
    }, {
      path: "filters/*",
      component: _7c897664,
      name: "catalog.filters___uz"
    }]
  }, {
    path: "/uz/checkout",
    component: _c6292724,
    meta: {"availableAuthorized":true},
    children: [{
      path: "/uz/checkout/products",
      component: _a19a4550,
      name: "checkout.products___uz"
    }, {
      path: "/uz/checkout/services",
      component: _5ec5a85c,
      name: "checkout.services___uz"
    }]
  }, {
    path: "/uz/favorites",
    component: _40958bdf,
    meta: {"availableAuthorized":true},
    name: "favorites___uz"
  }, {
    path: "/uz/giftcard",
    component: _186a4330,
    name: "giftcard___uz"
  }, {
    path: "/uz/lookbooks",
    component: _e693c21a,
    children: [{
      path: "",
      component: _1d033994,
      name: "lookbooks___uz"
    }, {
      path: ":lookbook",
      component: _1ee46d4b,
      name: "lookbooks.lookbook___uz"
    }]
  }, {
    path: "/uz/profile",
    component: _077ae39e,
    meta: {"availableAuthorized":true},
    children: [{
      path: "",
      component: _2b7debf4,
      name: "profile___uz"
    }, {
      path: "addresses",
      component: _b952b572,
      name: "profile.addresses___uz"
    }, {
      path: "bill",
      component: _0ea05855,
      meta: {"isReferalPartnersOnlyRoute":true},
      name: "profile.pro-bill___uz"
    }, {
      path: "bonuses",
      component: _d8c4091c,
      children: [{
        path: "",
        component: _d8c4091c,
        name: "profile.bonuses___uz"
      }]
    }, {
      path: "certificates",
      component: _4c05ad8a,
      name: "profile.certificates___uz"
    }, {
      path: "commission",
      component: _8f09428e,
      meta: {"isReferalPartnersOnlyRoute":true},
      name: "profile.pro-commission___uz"
    }, {
      path: "documents",
      component: _7b38ddda,
      name: "profile.documents___uz"
    }, {
      path: "messages",
      component: _5380ffcc,
      children: [{
        path: "",
        component: _7bce0b1d,
        name: "profile.messages___uz"
      }, {
        path: ":id",
        component: _3eae30c5,
        name: "profile.chat___uz"
      }]
    }, {
      path: "orders",
      component: _487882b3,
      children: [{
        path: "",
        component: _0956f6f6,
        name: "profile.orders___uz"
      }, {
        path: ":id",
        component: _11023ede,
        children: [{
          path: "",
          component: _7d4fa6e1,
          name: "profile.order___uz"
        }, {
          path: "return",
          component: _5270e231,
          name: "profile.order.return___uz"
        }, {
          path: "update-return",
          component: _150c00c3,
          name: "profile.order.update-return___uz"
        }]
      }]
    }, {
      path: "preferences",
      component: _7e603a9d,
      name: "profile.preferences___uz",
      children: [{
        path: ":name",
        component: _7e603a9d,
        meta: {"isReferalPartnersOnlyRoute":true},
        name: "profile.pro-preferences___uz"
      }]
    }, {
      path: "promo-products",
      component: _59c15b9a,
      meta: {"isReferalPartnersOnlyRoute":true},
      name: "profile.promo-products___uz"
    }, {
      path: "promocodes",
      component: _568b59f0,
      meta: {"isReferalPartnersOnlyRoute":true},
      name: "profile.pro-promocodes___uz"
    }, {
      path: "promopage",
      component: _220c3918,
      meta: {"isReferalPartnersOnlyRoute":true},
      name: "profile.pro-promo___uz"
    }, {
      path: "referal",
      component: _7b7274e0,
      meta: {"samePagePosition":true,"isReferalPartnersOnlyRoute":true},
      name: "profile.referal___uz"
    }, {
      path: "returns",
      component: _49ef5d36,
      children: [{
        path: "",
        component: _40697828,
        name: "profile.returns___uz"
      }, {
        path: ":id",
        component: _24c76490,
        children: [{
          path: "",
          component: _100b81da,
          name: "profile.return___uz"
        }, {
          path: "confirm",
          component: _3462607e,
          name: "profile.return.confirm___uz"
        }, {
          path: "create-return",
          component: _4e09d0e2,
          name: "profile.return.create-return___uz"
        }]
      }]
    }, {
      path: "subscriptions",
      component: _c7469bd0,
      name: "profile.subscriptions___uz"
    }, {
      path: "referal/:id",
      component: _f3bacf0a,
      meta: {"samePagePosition":true,"isReferalPartnersOnlyRoute":true},
      name: "profile.referal.id___uz"
    }]
  }, {
    path: "/uz/promo",
    component: _3261ed6c,
    children: [{
      path: "",
      component: _a12c9a22,
      name: "promos___uz"
    }, {
      path: ":entityCode",
      component: _843201f4,
      name: "promos.promo___uz"
    }]
  }, {
    path: "/uz/search",
    component: _6643b060,
    children: [{
      path: "",
      component: _6643b060,
      name: "search___uz"
    }, {
      path: "filters/*",
      component: _6643b060,
      name: "search.filters___uz"
    }, {
      path: ":category",
      component: _6643b060,
      children: [{
        path: "",
        component: _6643b060,
        name: "search.category___uz"
      }, {
        path: "filters/*",
        component: _6643b060,
        name: "search.category.filters___uz"
      }]
    }]
  }, {
    path: "/uz/services",
    component: _5c3de386,
    children: [{
      path: "",
      component: _65572d89,
      name: "services___uz"
    }, {
      path: ":service",
      component: _4347fb12,
      name: "services.service___uz"
    }]
  }, {
    path: "/uz/sets",
    component: _b7148c0e,
    children: [{
      path: "",
      component: _bd79bc88,
      name: "sets___uz"
    }, {
      path: ":entityCode",
      component: _11d2e329,
      name: "sets.set___uz"
    }]
  }, {
    path: "/en/catalog/:category",
    component: _94baa6be,
    children: [{
      path: "",
      component: _7c897664,
      name: "catalog.category___en"
    }, {
      path: "filters/*",
      component: _7c897664,
      name: "catalog.category.filters___en"
    }]
  }, {
    path: "/en/referrer/:code",
    component: _6e2cf272,
    name: "referrer.promopage___en"
  }, {
    path: "/en/social-login/:driver",
    component: _40dd6f04,
    name: "social-login___en"
  }, {
    path: "/en/thank-you/:id",
    component: _60214522
  }, {
    path: "/oz/catalog/:category",
    component: _94baa6be,
    children: [{
      path: "",
      component: _7c897664,
      name: "catalog.category___oz"
    }, {
      path: "filters/*",
      component: _7c897664,
      name: "catalog.category.filters___oz"
    }]
  }, {
    path: "/oz/referrer/:code",
    component: _6e2cf272,
    name: "referrer.promopage___oz"
  }, {
    path: "/oz/social-login/:driver",
    component: _40dd6f04,
    name: "social-login___oz"
  }, {
    path: "/oz/thank-you/:id",
    component: _60214522
  }, {
    path: "/uz/catalog/:category",
    component: _94baa6be,
    children: [{
      path: "",
      component: _7c897664,
      name: "catalog.category___uz"
    }, {
      path: "filters/*",
      component: _7c897664,
      name: "catalog.category.filters___uz"
    }]
  }, {
    path: "/uz/referrer/:code",
    component: _6e2cf272,
    name: "referrer.promopage___uz"
  }, {
    path: "/uz/social-login/:driver",
    component: _40dd6f04,
    name: "social-login___uz"
  }, {
    path: "/uz/thank-you/:id",
    component: _60214522
  }, {
    path: "/en/catalog/:category/:product",
    component: _1c397fe4,
    name: "catalog.category.product___en"
  }, {
    path: "/oz/catalog/:category/:product",
    component: _1c397fe4,
    name: "catalog.category.product___oz"
  }, {
    path: "/uz/catalog/:category/:product",
    component: _1c397fe4,
    name: "catalog.category.product___uz"
  }, {
    path: "/catalog/:category",
    component: _94baa6be,
    children: [{
      path: "",
      component: _7c897664,
      name: "catalog.category___ru"
    }, {
      path: "filters/*",
      component: _7c897664,
      name: "catalog.category.filters___ru"
    }]
  }, {
    path: "/referrer/:code",
    component: _6e2cf272,
    name: "referrer.promopage___ru"
  }, {
    path: "/social-login/:driver",
    component: _40dd6f04,
    name: "social-login___ru"
  }, {
    path: "/thank-you/:id",
    component: _60214522
  }, {
    path: "/catalog/:category/:product",
    component: _1c397fe4,
    name: "catalog.category.product___ru"
  }, {
    path: "/en/:lang?/*",
    component: _6273ab92,
    name: "any___en"
  }, {
    path: "/uz/:lang?/*",
    component: _6273ab92,
    name: "any___uz"
  }, {
    path: "/oz/:lang?/*",
    component: _6273ab92,
    name: "any___oz"
  }, {
    path: "/",
    component: _4e89fb61,
    name: "main___ru"
  }, {
    path: "/:lang?/*",
    component: _6273ab92,
    name: "any___ru"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

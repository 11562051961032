<template>
  <nuxt-link
    :class="{ 'back-button--with-icon': withIcon }"
    :to="localeRoute(returnPath)"
    class="back-button font font_sm font_bold font_uppercase"
    data-test="profile-back"
  >
    <slot name="icon" />
    {{ getRouteTitle }}
  </nuxt-link>
</template>
<script>
export default {
  name: 'BackButtonComponent',
  props: {
    routes: {
      type: Object,
      default: null,
    },
    route: {
      type: Object,
      default: null,
    },
  },
  computed: {
    getRouteName() {
      if (this.routes) {
        return this.routes[this.$route.name.split('___')[0]]?.name;
      }

      return '';
    },
    getRouteParams() {
      if (this.routes) {
        return this.routes[this.$route.name.split('___')[0]]?.params;
      }

      return {};
    },
    getRouteTitle() {
      if (this.routes) {
        return this.routes[this.$route.name.split('___')[0]]?.title ?? this.$t('shared.back');
      }

      return this.route?.title ?? this.$t('shared.back');
    },
    returnPath() {
      const filteredPaths = this.$route.matched.filter((item) => {
        return item.path !== this.$route.path && item.name !== this.$route.name.split('___')[0];
      });
      const backItem = filteredPaths[filteredPaths.length - 1];
      const path = backItem ? backItem.path : '';

      return {
        path: this.getRouteName ? '' : path,
        name: this.getRouteName,
        params: this.getRouteParams,
      };
    },
    withIcon() {
      return this.$slots.icon;
    },
  },
};
</script>

<style scoped>
.back-button {
  display: flex;
  align-items: center;
  color: var(--color-basic-gray);
  padding: 6px 4px;

  transition: color var(--basic-animation-time) ease;
}

.back-button >>> path {
  transition: fill var(--basic-animation-time) ease;
}

.back-button--with-icon {
  padding: 2px 8px 2px 2px;
  column-gap: 2px;
}

.back-button:hover,
.back-button:focus,
.back-button:active {
  color: var(--color-basic);
}

.back-button:hover >>> path,
.back-button:focus >>> path,
.back-button:active path {
  fill: var(--color-basic);
}

.back-button:focus {
  outline: none;
}
</style>

<template>
  <CustomModal id="auth-modal" :empty="true" :visible="isOpenedAuthModal" @close="closeModal()">
    <template #content>
      <SignUpPhone v-if="getCurrentAuthModalPage === authModalPages.INDEX" @closeModal="closeModal()" />
      <SignUpCode v-else-if="getCurrentAuthModalPage === authModalPages.SIGN_UP_CODE" @closeModal="closeModal()" />
      <!-- вернуть если нужно дать пользователю выбор города-->
      <!--      <Location-->
      <!--        v-else-if="getCurrentAuthModalPage === authModalPages.SELECT_LOCATION"-->
      <!--        @closeModal="closeModal()"-->
      <!--      />-->
    </template>
  </CustomModal>
</template>

<script>
import './index.css';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { authModalPages } from '@/plugins/enums/auth';
import { MODAL_FADE_DURATION } from '@/plugins/config';
import CustomModal from '@/components/elements/CustomModal';
import SignUpPhone from '@/components/auth/Modal/SignUp/Phone';
import SignUpCode from '@/components/auth/Modal/SignUp/Code';
// вернуть если нужно дать пользователю выбор города
// import Location from '@/components/auth/Location';

export default {
  name: 'AuthModal',
  components: {
    CustomModal,
    SignUpPhone,
    SignUpCode,
    // вернуть если нужно дать пользователю выбор города
    // Location,
  },
  data() {
    return {
      modalFadeDuration: MODAL_FADE_DURATION,
      authModalPages,
    };
  },
  computed: {
    ...mapState('auth', ['isOpenedAuthModal']),
    ...mapGetters({
      getCurrentAuthModalPage: 'auth/getCurrentAuthModalPage',
    }),
  },
  methods: {
    ...mapMutations('auth', {
      setCurrentAuthModalPage: 'SET_CURRENT_AUTH_MODAL_PAGE',
      setIsOpenedAuthModal: 'SET_IS_OPENED_AUTH_MODAL',
    }),
    closeModal() {
      this.setIsOpenedAuthModal(false);
      setTimeout(this.resetCurrentAuthModalPage, this.modalFadeDuration);

      this.$emit('closeModal')
    },
    resetCurrentAuthModalPage() {
      this.setCurrentAuthModalPage(null);
    },
  },
};
</script>

<template>
  <div class="header-user__detail-wrapper">
    <ul class="header-user__detail">
      <li class="header-user__detail-item">
        <nuxt-link
          :to="localePath({ name: 'profile' })"
          class="header-user__detail-link font_m font_medium"
          data-test="profile-menu-personal"
        >
          {{ $t('header.profile') }}
        </nuxt-link>
      </li>

      <li class="header-user__detail-item">
        <nuxt-link
          :to="localePath({ name: 'profile.orders' })"
          class="header-user__detail-link font_m font_medium"
          data-test="profile-menu-orders"
        >
          {{ $t('header.orders') }}
        </nuxt-link>
      </li>

      <li class="header-user__detail-item">
        <nuxt-link
          :to="localePath({ name: 'profile.messages' })"
          class="header-user__detail-link font_m font_medium"
          data-test="profile-menu-messages"
        >
          {{ $t('header.messages') }}
        </nuxt-link>
      </li>

      <li v-if="isUserReferral" class="header-user__detail-item">
        <nuxt-link
          :to="localePath({ name: 'profile.referal' })"
          class="header-user__detail-link font_m font_medium"
          data-test="profile-menu-messages"
        >
          {{ $t('header.referral') }}
        </nuxt-link>
      </li>

      <li class="header-user__detail-item">
        <button class="header-user__detail-link font_m font_medium" data-test="profile-menu-logout" @click="onLogout">
          {{ $t('header.logout') }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'HeaderMainActionsAccountMenu',
  computed: {
    ...mapState('auth', ['user']),
    isUserReferral() {
      return this.user?.referralPartner;
    },
  },
  methods: {
    ...mapActions('auth', ['logout']),
    async onLogout() {
      this.isPendingLogout = true;
      await this.logout();
      this.isPendingLogout = false;
      if (this.$route.meta.availableAuthorized || this.$route.matched.some((item) => item.meta.availableAuthorized)) {
        this.$router.push(this.localePath({ name: 'main' }));
      }
    },
  },
};
</script>

<style scoped>
@media all and (min-width: 1024px) {
  .header-user__detail-wrapper {
    position: absolute;
    z-index: 1001;
    top: 100%;
    right: 50%;
    transform: translateX(50%);
    padding-top: 9px;

    visibility: hidden;
    opacity: 0;
  }

  .header-user__detail {
    list-style: none;
    width: 300px;
    background: var(--color-white);
    box-shadow: 0 4px 14px var(--color-shadow);
    border-radius: 8px;
    transition: opacity var(--fade-animation-time);
    padding: 0;
    overflow: hidden;
  }

  .header-user__detail-link {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 16px 20px;
    border-bottom: 1px solid var(--color-gray-soft);
    color: var(--color-basic-black);
  }
}
</style>

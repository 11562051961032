import area from '~/plugins/api/area'
import auth from '~/plugins/api/auth'
import brands from '~/plugins/api/brands'
import cart from '~/plugins/api/cart'
import catalog from '~/plugins/api/catalog'
import checkout from '~/plugins/api/checkout'
import favorites from '~/plugins/api/favorites'
import general from '~/plugins/api/general'
import geolocation from '~/plugins/api/geolocation'
import giftcard from '~/plugins/api/giftcard'
import locales from '~/plugins/api/locales'
import product from '~/plugins/api/product'
import profile from '~/plugins/api/profile'
import reviews from '~/plugins/api/reviews'
import search from '~/plugins/api/search'
import services from '~/plugins/api/services'
import currency from '~/plugins/api/currency'

const apiFactory = (axios) => ({
  cart: cart(axios),
  search: search(axios),
  catalog: catalog(axios),
  product: product(axios),
  profile: profile(axios),
  checkout: checkout(axios),
  auth: auth(axios),
  geolocation: geolocation(axios),
  general: general(axios),
  giftcard: giftcard(axios),
  services: services(axios),
  favorites: favorites(axios),
  reviews: reviews(axios),
  brands: brands(axios),
  area: area(axios),
  locales: locales(axios),
  currency: currency(axios),
})

export default ({ $axios }, inject) => {
  const api = apiFactory($axios)
  inject('api', api)
};

/**
 * Вычисляет контрольное число.
 *
 * @param  {string}   str          Исходная строка.
 * @param  {number[]} coefficients Коэффициенты контрольной суммы.
 * @return {number}                Контрольное число.
 */
export function countCheckdigit(str, coefficients) {
  const checksum = coefficients.reduce((sum, coefficient, index) => sum + coefficient * str[index], 0);

  return (checksum % 11) % 10;
}

export function profileRequisitesFactory(requisites) {
  const {
    name = null,
    address = null,
    inn = null,
    account = null,
    bank  = null,
    bik = null,
    correspondentAccount = null,
    // eslint-disable-next-line camelcase
    payment_city = null,
  } = requisites

  return {
    legal_info_company_name: name,
    legal_info_company_address: address,
    legal_info_inn: inn,
    legal_info_payment_account: account,
    legal_info_bik: bik,
    legal_info_bank: bank,
    legal_info_bank_correspondent_account: correspondentAccount,
    payment_city,
  }
}

export function getReferralLink(id) {
  return `${window.location.origin}?ref=${id}`
}

export function scrollToEl($el, margin = 64, headerSelector = '.header-bottom__container') {
  const $header = document.querySelector(headerSelector)
  const headerHeight = $header?.clientHeight || 0

  const rect = $el.getBoundingClientRect();
  const offsetTop = Math.round(rect.top + pageYOffset) - headerHeight - margin

  setTimeout(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: offsetTop,
      left: 0,
    });
  }, 0)
}

import { receiveMethods } from '@/plugins/enums/checkout';
import {
  businessTypeNameGenerator,
  cancelReasons,
  deliveryStatus,
  filterField,
  orderPaymentStatus,
  orderStatus,
} from '@/plugins/enums/order';
import { dateToString } from '@/plugins/formatting';
import { referralOrderSortFields, referralSourceNameGenerator } from '~/plugins/enums/profile';
import { sortDirections } from '~/plugins/enums/sort';
import { getLocaleDate } from '~/plugins/helpers/dates';

export function getOrderStatusName(status) {
  switch (status) {
    case orderStatus.PRE_ORDER:
      return this.$t('shared.orderStatus.0');
    case orderStatus.CREATED:
      return this.$t('shared.orderStatus.1');
    case orderStatus.AWAITING_CHECK:
    case orderStatus.CHECKING:
    case orderStatus.AWAITING_CONFIRMATION:
    case orderStatus.IN_PROCESSING:
    case orderStatus.TRANSFERRED_TO_DELIVERY:
      return this.$t('shared.orderStatus.2');
    case orderStatus.DELIVERING:
      return this.$t('shared.orderStatus.7');
    case orderStatus.READY_FOR_RECIPIENT:
      return this.$t('shared.orderStatus.8');
    case orderStatus.DONE:
      return this.$t('shared.orderStatus.9');
    case orderStatus.RETURNED:
      return this.$t('shared.orderStatus.10');
    case orderStatus.CANCELED:
      return this.$t('shared.orderStatus.1000');
  }
}

export function getOrderDeliveryMethodName(method) {
  switch (method) {
    case receiveMethods.DELIVERY:
      return this.$t('shared.delivery.courier');
    case receiveMethods.PICKUP:
      return this.$t('profile.orders.delivery.pickup');
    case receiveMethods.EXPRESS:
      return this.$t('profile.orders.delivery.express');
  }
}

export function generateThankPageUrl(orderId) {
  return `${document.location.origin}/thank-you/${orderId}`;
}

export function getCancelReasonName(reason) {
  switch (reason) {
    case cancelReasons.DELIVERY_VIOLATED:
      return this.$t('profile.orders.cancelReasons.1');
    case cancelReasons.CHANGE_MIND:
      return this.$t('profile.orders.cancelReasons.2');
    case cancelReasons.CHEAPER:
      return this.$t('profile.orders.cancelReasons.3');
    case cancelReasons.FASTER:
      return this.$t('profile.orders.cancelReasons.4');
    case cancelReasons.ANOTHER:
      return this.$t('profile.orders.cancelReasons.5');
  }
}

export function getPaymentStatusName(status) {
  switch (status) {
    case orderPaymentStatus.PAID:
    case orderPaymentStatus.HOLD:
      return this.$t('profile.orders.paymentStatus.2');
    case orderPaymentStatus.TIMEOUT:
      return this.$t('profile.orders.paymentStatus.3');
    case orderPaymentStatus.ERROR:
      return this.$t('profile.orders.paymentStatus.5');
    case orderPaymentStatus.WAITING:
      return this.$t('profile.orders.paymentStatus.6');
    case orderPaymentStatus.RETURNED:
      return this.$t('profile.orders.paymentStatus.7');
    default:
      return this.$t('profile.orders.paymentStatus.1');
  }
}

export function getDeliveryStatusName(status) {
  switch (status) {
    case deliveryStatus.STATUS_ON_POINT_IN:
      return this.$t('profile.orders.deliveryStatus.21');
    case deliveryStatus.STATUS_ARRIVED_AT_DESTINATION_CITY:
      return this.$t('profile.orders.deliveryStatus.22');
    case deliveryStatus.STATUS_ON_POINT_OUT:
      return this.$t('profile.orders.deliveryStatus.23');
    case deliveryStatus.STATUS_DELIVERING:
      return this.$t('profile.orders.deliveryStatus.24');
    case deliveryStatus.STATUS_READY_FOR_RECIPIENT:
      return this.$t('profile.orders.deliveryStatus.25');
    case deliveryStatus.STATUS_DONE:
      return this.$t('profile.orders.deliveryStatus.26');
    case deliveryStatus.STATUS_CANCELLATION_EXPECTED:
      return this.$t('profile.orders.deliveryStatus.41');
    case deliveryStatus.STATUS_RETURN_EXPECTED_FROM_CUSTOMER:
      return this.$t('profile.orders.deliveryStatus.42');
    case deliveryStatus.STATUS_RETURNED:
      return this.$t('profile.orders.deliveryStatus.43');
    case deliveryStatus.STATUS_ASSEMBLING:
      return this.$t('profile.orders.deliveryStatus.5');
    case deliveryStatus.STATUS_ASSEMBLED:
      return this.$t('profile.orders.deliveryStatus.6');
    case deliveryStatus.STATUS_CANCEL:
      return this.$t('profile.orders.deliveryStatus.1000');
    case deliveryStatus.STATUS_SHIPPED:
      return this.$t('profile.orders.deliveryStatus.7');
    default:
      return this.$t('profile.orders.deliveryStatus.1');
  }
}

export function getOrderFilterDate(field) {
  const getDate = (f) => {
    const today = new Date();
    switch (f) {
      case filterField.YEAR:
        return new Date(today.setFullYear(today.getFullYear() - 1));
      case filterField.MONTH:
        return new Date(today.setMonth(today.getMonth() - 1));
      case filterField.DAY:
        return new Date(today.setDate(today.getDate() - 1));
      case filterField.ALL_TIME:
      default:
        return null;
    }
  };

  return getDate(field) ? dateToString(getDate(field)) : null;
}

export function getReferralOrdersParams(query) {
  const {
    page = 1,
    orderField = referralOrderSortFields.ORDER_DATE,
    orderDirection = sortDirections.DESC,
    orderFilterField,
  } = query;

  const date = getOrderFilterDate(orderFilterField);

  return { page, orderField, orderDirection, orderFilterField, date };
}

export function getReferralOrders(items, isReferralId = true) {
  const businessTypeName = businessTypeNameGenerator.call(this);
  const referralSourceName = referralSourceNameGenerator.call(this);

  return items.map((i) => {
    // eslint-disable-next-line camelcase
    const { name, image, order_date, source, category_code, code, variantGroup } = i;
    // eslint-disable-next-line camelcase
    const date = order_date && getLocaleDate(order_date);

    let values = null;
    if (variantGroup) {
      const { characteristics = [], combinations = [] } = variantGroup;
      const { props } = combinations.find((c) => c.code === code);
      const keys = Object.keys(props);
      values = keys.map((k) => {
        const { options, name } = characteristics.find((c) => c.code === k);
        const option = options.find((o) => o.value === props[k]);
        return `${name}: ${option.name}`;
      });
    }

    const note = values && values.join(', ');

    return {
      id: i.id,
      source,
      product: {
        name,
        category_code,
        code,
        note,
        image: { ...image },
      },
      info: [
        { id: 0, name: `${Number(i.qty)} ${this.$t('shared.items')}`, link: null, thData: this.$t('shared.qty') },
        {
          id: 1,
          name: `${businessTypeName[i.business_type] ? businessTypeName[i.business_type] : '-'}`,
          link: null,
          thData: this.$t('profile.businessType'),
        },
        isReferralId && {
          id: 2,
          name: i.customer_id,
          thData: this.$t('profile.referralID'),
          link: {
            name: 'profile.referal.id',
            params: { id: i.customer_id },
          },
        },
        { id: 3, name: referralSourceName[source], link: null, thData: this.$t('shared.source') },
        { id: 4, name: date, link: null, thData: this.$t('shared.orderDate') },
        {
          id: 5,
          name: this.$options.filters.price(i.price_product),
          link: null,
          thData: this.$t('shared.amount'),
        },
        {
          id: 6,
          name: this.$options.filters.price(i.price_commission),
          link: null,
          thData: this.$t('shared.payout'),
        },
      ],
    };
  });
}

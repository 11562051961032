<template>
  <transition name="fade">
    <div
      :class="{
        'alert--centered': centered,
        'alert--error': type === 'error',
        'alert--success': type === 'success',
        'alert--card': icon === 'card',
        'alert--lg': lg,
        'alert--sm': sm,
        'alert--md': md,
      }"
      class="alert"
    >
      <p v-if="$slots.title" class="alert__text alert__title">
        <slot name="title" />
      </p>
      <div v-if="$slots.content" class="alert__text alert__content font font_m">
        <slot name="content" />
      </div>
      <button
        v-if="closable"
        :aria-label="$t('elements.alert.close')"
        class="alert__text alert__close"
        @click="close()"
      >
        <span class="visually-hidden">{{ $t('elements.alert.close') }}</span>
      </button>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    type: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    closable: {
      type: Boolean,
      default: true,
    },
    lg: {
      type: Boolean,
      default: false,
    },
    md: {
      type: Boolean,
      default: false,
    },
    sm: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.alert {
  display: grid;
  grid-template-areas:
    'ico title'
    'ico content';
  grid-template-columns: auto 1fr 20px;
  gap: 0 8px;
  padding: 16px;
  margin-bottom: 20px;
  position: relative;
  border: 1px solid var(--color-alert-info-border);
  background: var(--color-alert-info-bg);
  font-size: 14px;
  line-height: 120%;
  color: var(--color-black-basic);
}

.alert::before {
  content: '';
  grid-area: ico;
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.99984 2.29187C14.2573 2.29187 17.7082 5.74354 17.7082 10.0002C17.7082 14.2569 14.2573 17.7085 9.99984 17.7085C5.74317 17.7085 2.2915 14.2569 2.2915 10.0002C2.2915 5.74354 5.74317 2.29187 9.99984 2.29187Z' stroke='%2312121D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9.99577 6.83691V10.5194' stroke='%2312121D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9.996 13.1635H10.0043' stroke='%2312121D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
}

.alert--centered {
  grid-template-columns: auto auto;
  justify-content: center;
}

.alert__title {
  grid-area: title;
  position: relative;
  font-weight: 500;
  line-height: var(--font-text-m-height);
}

.alert__content {
  grid-area: content;
}

.alert__title + .alert__content {
  margin-top: 8px;
}

.alert__content >>> p:not(:last-child) {
  margin-bottom: 4px;
}

.alert__content >>> a {
  color: var(--color-basic);
  text-decoration: underline;
}

.alert__content >>> a:hover {
  text-decoration: none;
}

.alert__close {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.33339 5.28595C6.04411 4.99668 5.5751 4.99668 5.28582 5.28595C4.99655 5.57523 4.99654 6.04424 5.28582 6.33352L8.9523 10L5.28582 13.6665C4.99654 13.9558 4.99654 14.4248 5.28582 14.714C5.5751 15.0033 6.04411 15.0033 6.33339 14.714L9.99987 11.0476L13.6663 14.714C13.9556 15.0033 14.4246 15.0033 14.7139 14.714C15.0032 14.4248 15.0032 13.9558 14.7139 13.6665L11.0474 10L14.7139 6.33352C15.0032 6.04424 15.0032 5.57523 14.7139 5.28596C14.4246 4.99668 13.9556 4.99668 13.6663 5.28596L9.99987 8.95243L6.33339 5.28595Z' fill='%2399999E'/%3E%3C/svg%3E%0A");
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
}

.alert--error {
  background: var(--color-alert-error-bg);
  border-color: var(--color-alert-error-border);
}

.alert--error::before {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.37549 13.558C9.37549 13.213 9.65549 12.928 10.0005 12.928C10.3455 12.928 10.6255 13.2038 10.6255 13.5488V13.558C10.6255 13.903 10.3455 14.183 10.0005 14.183C9.65549 14.183 9.37549 13.903 9.37549 13.558Z' fill='%23E34F50'/%3E%3Cpath d='M3.99645 17.0149H16.0648C17.0206 16.9649 17.7556 16.1507 17.7065 15.1949C17.6956 15.0015 17.654 14.8107 17.5806 14.6324L11.5165 4.01903C11.0531 3.18153 9.99895 2.87819 9.16145 3.34236C8.87645 3.49903 8.64145 3.73403 8.48395 4.01903L2.41979 14.6324C2.05979 15.519 2.48645 16.529 3.37229 16.8899C3.55229 16.9624 3.74229 17.004 3.93562 17.0149' stroke='%23E34F50' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9.99186 11.1634V8.58008' stroke='%23E34F50' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.alert--success {
  background: var(--color-alert-success-bg);
  border-color: var(--color-alert-success-border);
}

.alert--success::before {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.0332 10.3109L9.01154 12.2884L12.9665 8.33337' stroke='%236D988C' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.99984 2.29187C14.2573 2.29187 17.7082 5.74354 17.7082 10.0002C17.7082 14.2569 14.2573 17.7085 9.99984 17.7085C5.74317 17.7085 2.2915 14.2569 2.2915 10.0002C2.2915 5.74354 5.74317 2.29187 9.99984 2.29187Z' stroke='%236D988C' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.alert--card::before {
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='16' viewBox='0 0 22 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='20' height='14' rx='2' fill='white' stroke='%2312121D' stroke-width='1.5'/%3E%3Cline x1='1' y1='5.5' x2='21' y2='5.5' stroke='%2312121D' stroke-width='2'/%3E%3Crect x='4' y='9' width='4' height='3.5' rx='1' fill='%2312121D'/%3E%3C/svg%3E%0A");
}

.alert--lg .alert__title {
  font-weight: 700;
  font-size: var(--font-title-m-size);
  line-height: var(--font-title-m-height);
  text-transform: uppercase;
}

.alert--md .alert__title {
  font-weight: 700;
  font-size: var(--font-text-m-size);
  line-height: var(--font-text-m-height);
}

.alert--sm {
  display: inline-grid;
  padding: 1px 4px 2px;
}

.alert--md .alert__content {
  font-size: var(--font-text-m-size);
  line-height: var(--font-text-m-height);
}

.alert--sm .alert__content {
  font-size: var(--font-text-sm-size);
  line-height: var(--font-text-sm-height);
}

.return-card__status.alert {
  margin-bottom: 0;
  padding: 16px;
}

.return-card__status > .alert__text {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
}

@media all and (min-width: 1280px) {
  .alert {
    margin-bottom: 24px;
    font-size: 16px;
  }

  .alert__title {
    font-weight: 700;
    font-size: 18px;
  }
}
</style>

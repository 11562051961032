<template>
  <div class="menu__catalog">
    <div class="menu__catalog-content">
      <div class="container">
        <ul class="menu__catalog-list">
          <li v-for="category in allCategories" :key="category.id" class="menu__catalog-item">
            <nuxt-link
              :to="
                localePath({
                  name: 'catalog.category',
                  params: { category: category.code },
                })
              "
              class="menu__catalog-category-title font font_m font_uppercase font_bold font_underline"
              @click.native="onLinkClick"
            >
              {{ category.name }}
            </nuxt-link>

            <ul v-if="category.items && category.items.length > 0" class="menu__catalog-subitems">
              <li v-for="subcategory in category.items" :key="subcategory.id" class="menu__catalog-subitem">
                <nuxt-link
                  :to="
                    localePath({
                      name: 'catalog.category',
                      params: { category: subcategory.code },
                    })
                  "
                  class="menu__catalog-subcategory-link font font_m"
                  @click.native="onLinkClick"
                >
                  {{ subcategory.name }}
                </nuxt-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <button :aria-label="$t('shared.closeMenu')" class="menu__overlay" type="button" @click="onLinkClick"></button>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'HeaderBottomCatalog',
  computed: {
    ...mapState('catalog', ['allCategories']),
  },
  methods: {
    onLinkClick() {
      this.$emit('linkClick');
    },
  },
};
</script>

<style scoped>
@media all and (min-width: 1024px) {
  .menu__catalog {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: var(--app-height);
    z-index: 1000;
  }

  .menu__catalog-content {
    width: 100%;
    background: var(--color-white);
    padding: 40px 0;
  }

  .menu__catalog-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 40px;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .menu__catalog-category-title {
    font-weight: 800;
    font-size: 16px;
    line-height: 141%;
    color: var(--color-basic-black);
  }

  .menu__catalog-subitems {
    list-style: none;
    padding: 0;
    margin: 12px 0 0;
  }

  .menu__catalog-subitem {
    margin-top: 12px;
  }

  .menu__catalog-subcategory-link {
    font-size: 14px;
    line-height: 141%;
    color: var(--color-basic-black);
  }

  .menu__catalog-subcategory-link:hover,
  .menu__catalog-subcategory-link:focus {
    text-decoration: underline;
  }

  .menu__overlay {
    width: 100%;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--color-overlay);
    z-index: -1;
  }
}
</style>

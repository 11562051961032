<template>
  <div class="search">
    <SearchInput v-if="!mqIsMobile" v-model="query" @onEnter="onEnterHandler" @onFocus="onFocusHandler" />

    <CustomPopup
      v-if="!mqIsMobile && !isScrollOffset"
      :pop-up-state="isOpenedSearch"
      class="search__popup"
      @closePopUp="modalStateHandler(false)"
    >
      <SearchResult
        :products="searchResult.products"
        :suggestions="searchResult.suggestions"
        @close="onCloseHandler"
        @suggestion="onSuggestionHandler"
      />
    </CustomPopup>
    <CustomModal
      v-else
      v-show="isOpenedSearch"
      :title="$t('shared.breadcrumbs.search')"
      class="search__modal"
      @close="modalStateHandler"
    >
      <template #content>
        <SearchInput v-model="query" @onFocus="onFocusHandler" />
        <SearchResult
          v-scroll-lock="isOpenedSearch"
          :products="searchResult.products"
          :suggestions="searchResult.suggestions"
          @close="onCloseHandler"
          @suggestion="onSuggestionHandler"
        />
      </template>
    </CustomModal>
  </div>
</template>

<script>
import { throttle } from 'lodash/function';
import { mapActions, mapState } from 'vuex';
import CustomModal from '~/components/elements/CustomModal';
import CustomPopup from '~/components/elements/CustomPopup';
import SearchInput from '~/layouts/components/Search/Input';
import SearchResult from '~/layouts/components/Search/Result';

export default {
  name: 'Search',
  components: {
    CustomModal,
    SearchResult,
    CustomPopup,
    SearchInput,
  },
  props: {
    isScrollOffset: { type: Boolean, required: true },
  },
  data() {
    return {
      query: '',
      searchResult: {
        suggestions: [],
        products: [],
        range: 0,
      },
      throttleSearch: null,
    };
  },
  computed: {
    ...mapState({ isOpenedSearch: 'isOpenedSearch' }),
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet';
    },
  },
  watch: {
    query() {
      if (this.query.length > 30) {
        this.query.length = 30;
      }

      if (this.query.length > 3) {
        this.throttleSearch(this.query);
      } else {
        this.clearSearchResult();
      }
    },
    $route() {
      if (this.isOpenedSearch) {
        this.onCloseHandler();
      }
    },
  },
  mounted() {
    this.throttleSearch = throttle(this.onSearch, 300);
  },
  beforeDestroy() {
    this.throttleSearch = null;
  },
  methods: {
    ...mapActions({
      setIsOpenedSearch: 'setIsOpenedSearch',
    }),
    async onSearch(searchString) {
      const { suggestions, products, range } = await this.$api.search.search(searchString);

      if (suggestions.length > 4) {
        suggestions.length = 4;
      }

      this.searchResult = {
        suggestions,
        products,
        range,
      };

      this.modalStateHandler(true);
    },
    modalStateHandler(state) {
      this.setIsOpenedSearch(state);
    },
    onFocusHandler() {
      if (this.query && !this.isOpenedSearch) {
        const value = this.query;

        this.query = '';

        this.$nextTick(() => {
          this.query = value;
        });
      }
    },
    onEnterHandler() {
      this.$router.push(this.localePath({
        name: 'search',
        query: { search_string: this.query },
      }));
    },
    onSuggestionHandler(suggestion) {
      this.query = suggestion;
    },
    onCloseHandler() {
      this.clearSearchResult(true);
      this.query = '';
    },
    clearSearchResult(close = false) {
      close && this.modalStateHandler(false);

      this.searchResult = {
        suggestions: [],
        products: [],
        range: 0,
      };
    },
  },
};
</script>

<template>
  <div class="loader-dots">
    <span class="loader-dots__dot"><br></span>
    <span class="loader-dots__dot"><br></span>
    <span class="loader-dots__dot"><br></span>
  </div>
</template>

<script>
export default {
  name: 'LoaderDotsComponent',
};
</script>

<style scoped>
.loader-dots {
  margin-top: 34px;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.loader-dots__dot {
  width: 10px;
  height: 10px;
  margin: 0 2px;
  border-radius: 50%;
  background: var(--color-basic-black);
  animation: loader-dots 1.4s infinite ease-in-out;
}

.loader-dots__dot:nth-child(2) {
  animation-delay: 0.1s;
}

.loader-dots__dot:nth-child(3) {
  animation-delay: 0.2s;
}

@keyframes loader-dots {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
</style>

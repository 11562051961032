import { verificationCodeType } from '@/plugins/enums/auth'
import { billingIdentificationStatus } from '~/plugins/enums/profile'
import { profileRequisitesFactory } from '~/plugins/helpers/utils'

export const state = () => ({
  profile: null,
  fullName: '',
  backPageParams: {},

  isProfileLoading: false,
})

export const getters = {
  getFullName: (state) => {
    if (!state.profile) return ''

    const profile = state.profile
    return [ profile.last_name, profile.first_name, profile.middle_name ].join(' ').trim()
  },
  getFirstName: (state) => {
    if (!state?.profile?.first_name) return ''

    return state.profile.first_name.trim()
  },
  getLastName: (state) => {
    if (!state?.profile?.last_name) return ''

    return state.profile.last_name.trim()
  },
  getMiddleName: (state) => {
    if (!state?.profile?.middle_name) return ''

    return state.profile.middle_name.trim()
  },
  getNameSurname: (state) => {
    if (state.profile) {
      const profile = state.profile
      let fullName = ''
      if (profile.first_name) fullName += `${ profile.first_name } `
      if (profile.last_name) fullName += profile.last_name
      return fullName.trim()
    }
    return ''
  },
  getUserPersonalDiscount: (state) => {
    return {
      discount: state.profile?.referral_personal_discount?.percent || null,
      promocode: state.profile?.referral_personal_discount?.promoCode || '',
    }
  },
  isOfferAccepted: (state) => state.profile?.is_offer_accepted,
}

export const mutations = {
  SET_PROFILE(state, payload) {
    state.profile = payload
  },
  SET_PROFILE_LOADING(state, payload) {
    state.isProfileLoading = payload
  },
  UPDATED_PROFILE(state, payload) {
    state.profile.first_name = payload.first_name
    state.profile.last_name = payload.last_name
    state.profile.middle_name = payload.middle_name
    state.profile.birthday = payload.birthday
    state.profile.gender = payload.gender
  },
  UPDATE_PHONE(state, payload) {
    state.profile.phone = payload
  },
  UPDATE_EMAIL(state, payload) {
    state.profile.email = payload
  },
  SET_BACK_PAGE_PARAMS(state, payload) {
    state.backPageParams = payload
  },
  UPDATE_REQUISITES(state, payload) {
    const requisites = profileRequisitesFactory(payload)

    state.profile = {
      ...state.profile,
      ...requisites,
    }
  },
  SET_OFFER_ACCEPT(state, payload) {
    state.profile.is_offer_accepted = payload
  },
}

export const actions = {
  async loadProfile({ commit }) {
    try {
      commit('SET_PROFILE_LOADING', true)
      const result = await this.$api.profile.loadProfile()
      commit('SET_PROFILE', result)
    } catch (error) {
      console.error(error)
      return false
    } finally {
      commit('SET_PROFILE_LOADING', false)
    }
  },
  async updatePersonalData({ commit }, payload) {
    try {
      await this.$api.profile.updatePersonalData(payload)
      commit('UPDATED_PROFILE', payload)
    } catch (error) {
      throw new Error(`API Request Error (updatePersonalData): ${ error }`)
    }
  },
  async sendCode({ commit }, payload) {
    try {
      await this.$api.profile.sendCode(payload)
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  async updateCredential({ commit }, payload) {
    switch (payload.type) {
      case verificationCodeType.PROFILE_PHONE:
        commit('UPDATE_PHONE', payload.value)
        break
      case verificationCodeType.PROFILE_EMAIL:
        commit('UPDATE_EMAIL', payload.value)
        break
    }

    try {
      await this.$api.profile.updateCredential(payload)
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  async updateAvatar({ commit, dispatch }, payload) {
    try {
      await this.$api.profile.changeProfileAvatar(payload)
      await dispatch('auth/updateUserNameAvatar', null, { root: true })
    } catch (error) {
      console.log(error)
    }
  },
  async deleteAvatar({ dispatch }) {
    try {
      await this.$api.profile.deleteProfileAvatar()
      await dispatch('auth/updateUserNameAvatar', null, { root: true })
    } catch (error) {
      console.log(error)
    }
  },
  async updateRequisites({ commit }, payload = {}) {
    try {
      await this.$api.profile.changeProfileRequisites(payload)
      commit('UPDATE_REQUISITES', payload)
    } catch (error) {
      console.log(error)
    }
  },
  async createCard(ctx, payload) {
    try {
      if (payload.identificationStatus === billingIdentificationStatus.SUCCESS) {
        await this.$api.profile.createBillCard(payload)
      }
    } catch (error) {
      console.log(error)
    }
  },
  async setOfferAccept({ commit }, payload) {
    try {
      await this.$api.profile.setUserOfferAccept(payload)
      commit('SET_OFFER_ACCEPT', payload)
    } catch (error) {
      console.log(error)
    }
  },
}

<template>
  <div class="check" :class="{ 'check--switch': isSwitch }">
    <input
      v-bind="$attrs"
      :id="id"
      ref="input"
      class="check__input"
      :class="{'check__input--radio': type === 'radio'}"
      :type="type"
      :checked="shouldBeChecked"
      :value="value"
      :name="name"
      :data-test="dataTest"
      @change="updateInput"
      @click="onCancelCheckedClick"
    />
    <label v-if="$slots.sublabel" class="check__label" :for="id">
      <p class="check__label-item">
        <slot />
      </p>
      <p class="check__sublabel">
        <slot name="sublabel" />
      </p>
    </label>
    <label v-else class="check__label" :for="id">
      <slot />
    </label>
  </div>
</template>

<script>
const validTypes = ['checkbox', 'radio'];

export default {
  name: 'CustomCheck',
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    modelValue: {
      type: [Number, String, Boolean],
      default: undefined,
    },
    checked: {
      type: [Boolean, Number, String],
      default: null,
    },
    id: {
      type: [Number, String],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [Number, String],
      default: '',
    },
    type: {
      type: String,
      default: 'checkbox',
      validator(value) {
        // Значение должно соответствовать одной из этих строк
        return validTypes.includes(value) !== -1;
      },
    },
    indeterminate: {
      type: Boolean,
    },
    isSwitch: {
      type: Boolean,
    },
    dataTest: {
      type: String,
      default: null,
    },
    isCancelableRadio: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      m_checked: undefined,
    };
  },
  computed: {
    shouldBeChecked() {
      if (this.modelValue !== undefined) {
        // radio
        if (this.type === 'radio') return this.modelValue === this.value;
        // checkbox
        else if (this.modelValue instanceof Array) return this.modelValue.includes(this.value);
        return typeof this.modelValue === 'string' ? true : !!this.modelValue;
      }
      // this.modelValue === undefined
      if (this.m_checked === undefined) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return (this.m_checked = typeof this.checked === 'string' ? true : !!this.checked);
      } else {
        return this.m_checked && !this.indeterminate;
      }
    },
  },
  watch: {
    checked(value, old) {
      // чтобы чекбокс правильно обновлялся
      this.$nextTick(() => (this.m_checked = value));
    },

    indeterminate(value) {
      const { input } = this.$refs;
      if (input) input.indeterminate = value;
    },
  },
  methods: {
    onCancelCheckedClick(event) {
      if (this.type === 'radio' && this.modelValue && this.isCancelableRadio) {
        this.$emit('onCancelCheckedClick', event);
      }
    },
    updateInput(event) {
      if (this.type === 'radio') {
        this.$emit('change', this.value);
        return;
      }
      const isChecked = event.target.checked;
      this.m_checked = isChecked;
      if (this.modelValue instanceof Array) {
        const newValue = [...this.modelValue];
        if (isChecked) newValue.push(this.value);
        else newValue.splice(newValue.indexOf(this.value), 1);

        this.$emit('change', newValue);
      } else this.$emit('change', isChecked);
    },
  },
};
</script>

<style scoped>
.check {
  position: relative;
}

.check__input {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.check__label {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  padding-left: 30px;
  line-height: var(--font-text-m-height);
  font-weight: 400;
  font-size: var(--font-text-m-size);
  color: var(--color-basic-secondary);
  cursor: pointer;
}

.check__label-item {
  font-size: var(--font-text-m-size);
  line-height: var(--font-text-m-height);
}

.check__sublabel {
  font-size: var(--font-text-sm-size);
  line-height: var(--font-text-sm-height);
  color: var(--color-gray-dimness);
}

.check__label::before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: 1px solid var(--color-basic-gray);
  background: var(--color-white-basic);
  box-sizing: border-box;
  transition: border 0.3s, background 0.3s;
}

.check__label::after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 18px;
  height: 18px;
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='9' viewBox='0 0 13 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4.5L4.6677 8L12 1' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 11px 7px;
  opacity: 0;
  transition: opacity 0.3s;
}

.check__input:checked + .check__label::after {
  opacity: 1;
}

.check__label:hover::before,
.check__input:focus + .check__label::before {
  border-color: var(--color-basic);
}

.check__input:checked + .check__label::before {
  border-color: var(--color-basic);
  background: var(--color-basic);
}

.check__input:checked + .check__label:hover::before,
.check__input:checked:focus + .check__label::before {
  border-color: var(--color-basic);
  background: var(--color-basic);
}

.check__input:disabled + .check__label {
  cursor: default;
  pointer-events: none;
  color: var(--color-basic-gray)
}

.check__input:disabled + .check__label::before {
  border-color: var(--color-checkbox-disabled);
  background: var(--color-checkbox-disabled);
}

.check__input:checked:disabled + .check__label::after {
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='9' viewBox='0 0 13 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4.5L4.6677 8L12 1' stroke='%2399999E' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.check__input--radio + .check__label::before {
  border-radius: 50%;
}

.check__input--radio + .check__label::after {
  top: 50%;
  transform: translateY(-50%);
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--color-basic);
  opacity: 0;
  transition: opacity 0.3s, background 0.3s;
}

.check__input--radio:checked + .check__label::before {
  background: none;
}

.check__input--radio:checked + .check__label:hover::before,
.check__input--radio:focus:checked + .check__label::before {
  background: none;
}

.check__input--radio:checked + .check__label:hover::after,
.check__input--radio:focus:checked + .check__label::after {
  background: var(--color-basic);
}

.check__input--radio:checked:disabled + .check__label::before {
  background: var(--color-checkbox-disabled);
}

.check__input--radio:checked:disabled + .check__label::after {
  background: var(--color-radio-disabled);
}

.check--switch .check__input + .check__label {
  position: relative;
  display: block;
  padding-left: 60px;
  line-height: 1.7;
  cursor: pointer;
}

.check--switch .check__input + .check__label::before {
  top: 0;
  left: 0;
  width: 48px;
  height: 24px;
  border-radius: 48px;
  background: var(--color-switch-bg);
  border: 2px solid transparent;
  transition: background 0.3s, border 0.3s;
}

.check--switch .check__input + .check__label::after {
  z-index: 1;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--color-white-basic);
  box-shadow: 0 4px 4px var(--color-black-light), 0 4px 24px var(--color-black-fade);
  transform: none;
  transition: transform 0.3s;
}

.check--switch .check__input + .check__label:hover::before {
  background: var(--color-basic-gray);
}
.check--switch .check__input:focus + .check__label::before {
  border-color: var(--color-basic-black);
}

.check--switch .check__input:disabled + .check__label::before {
  background: var(--color-checkbox-disabled);
}

.check--switch .check__input:checked + .check__label::before {
  background: var(--color-basic);
}

.check--switch .check__input:checked + .check__label::after {
  transform: translateX(24px);
}

.check--switch .check__input:checked + .check__label:hover::before {
  background: var(--color-basic);
}

.check--switch .check__input:checked:disabled + .check__label::before {
  background: var(--color-basic-green-light);
}

.check__input--radio + .check__label::before {
  border-radius: 50%;
}

.check__input--radio:checked + .check__label::before {
  background: none;
}

.check__input--radio:checked + .check__label:hover::before {
  background: none;
}

.check__input--radio:checked + .check__label:hover::after {
  background: var(--color-basic);
}

.check__input--radio:checked:disabled + .check__label::before {
  background: var(--color-checkbox-disabled);
}

.check__input--radio:checked:disabled + .check__label::after {
  background: var(--color-radio-disabled);
}

.check--center .check__label::before,
.check--center .check__label::after {
  top: 50%;
  transform: translateY(-50%);
}
</style>

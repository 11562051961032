<template>
  <div class="search__form">
    <label class="search__label">
      <svg-icon name="search" width="22" height="22" />
    </label>

    <input
      ref="searchInput"
      v-model="searchString"
      class="search__input"
      :class="{ 'search__input--active': searchString.length > 0 }"
      type="text"
      :placeholder="$t('search.input.placeholder')"
      @focus="onFocusHandler"
      @keyup.enter="onKeyEnter"
    />
    <button v-if="searchString" class="search__clear" :aria-label="$t('shared.clear')" @click="clearSearch">
      <span class="visually-hidden">{{ $t('shared.clear') }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'SearchInput',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    searchString: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  methods: {
    clearSearch() {
      this.searchString = '';
    },
    onFocusHandler() {
      this.$emit('onFocus');
    },
    onKeyEnter() {
      this.$emit('onEnter');
    },
  },
};
</script>

<style scoped></style>

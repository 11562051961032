<template>
  <button
    type="button"
    class="menu-toggle"
    :class="{
      'menu-toggle--opened': value,
      'menu-toggle--not-hide-text': !hideTextOnStart
    }"
    @click="toggleMenuOpened"
  >
    <span class="menu-toggle__icon">
      <span></span>
    </span>

    <span class="menu-toggle__text font_sm font_uppercase">
      <slot />
    </span>
  </button>
</template>

<script>
export default {
    name: 'MenuToggle',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      hideTextOnStart: {
        type: Boolean,
        default: true,
      },
    },
    methods: {
      toggleMenuOpened() {
        this.$emit('input', !this.value);
      },
    },
}
</script>

<style scoped>
.menu-toggle {
  display: flex;
  align-items: center;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: var(--color-white);
}

.menu-toggle__icon {
  display: block;
  width: 28px;
  height: 28px;
  position: relative;
}

.menu-toggle__icon span {
  position: absolute;
  top: 13px;
  left: 5px;
  width: 10px;
  height: 2px;
  background-color: var(--color-white);
  transition: background 0.15s 0.15s;
}

.menu-toggle__icon span::before, .menu-toggle__icon span::after {
  content: '';
  position: absolute;
  left: 0;
  width: 18px;
  height: 2px;
  border-radius: 2px;
  background-color: var(--color-white);
  transition-duration: 0.3s, 0.3s, 0.3s;
  transition-delay: 0.3s, 0s, 0s;
}

.menu-toggle__icon span::before {
  top: -5px;
  transition-property: top, transform, background;
}

.menu-toggle--opened .menu-toggle__icon span {
  background: transparent;
}

.menu-toggle--opened .menu-toggle__icon span::before,
.menu-toggle--opened .menu-toggle__icon span::after {
  background: var(--color-white);
  transition-delay: 0s, 0.3s, 0s;
}

.menu-toggle--opened .menu-toggle__icon span::before {
  top: 0;
  transform: rotate(45deg);
}

.menu-toggle--opened .menu-toggle__icon span::after {
  bottom: 0;
  width: 18px;
  transform: rotate(-45deg);
}

.menu-toggle__icon span::after {
  bottom: -5px;
  width: 14px;
  transition-property: bottom, transform, background;
}

.menu-toggle__text {
  display: inline-block;
  margin-left: 4px;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  opacity: 0;
  transition: opacity 0.3s;
}

.menu-toggle--opened .menu-toggle__text,
.menu-toggle--not-hide-text .menu-toggle__text {
  opacity: 1;
}
</style>

<template>
  <div class="social">
    <ul class="social__list">
      <li v-for="(itemSocial, itemSocialIndex) in social" :key="`item-social-${itemSocialIndex}`" class="social__item">
        <a
          :href="itemSocial.href"
          :title="itemSocial.title"
          class="social__link"
          rel="nofollow noreferrer noopener"
          target="_blank"
        >
          <SvgIcon :name="`socials/${itemSocial.icon}`" class="social__ico" height="24" width="24" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import env from '@/.env.json';

export default {
  name: 'Support',
  data() {
    return {
      env,
    };
  },
  computed: {
    social() {
      const social = [];

      if (this.env.FOOTER_YOUTUBE) {
        social.push({
          id: 1,
          icon: 'youtube',
          title: this.$t('elements.social.youtube'),
          href: this.env.FOOTER_YOUTUBE,
        });
      }

      if (this.env.FOOTER_TELEGRAM) {
        social.push({
          id: 2,
          icon: 'telegram',
          title: this.$t('elements.social.telegram'),
          href: this.env.FOOTER_TELEGRAM,
        });
      }

      if (this.env.FOOTER_INSTAGRAM) {
        social.push({
          id: 3,
          icon: 'instagram',
          title: this.$t('elements.social.instagram'),
          href: this.env.FOOTER_INSTAGRAM,
        });
      }

      return social;
    },
  },
};
</script>

<style scoped>
.social__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-flex;
  gap: 20px;
}

.social__ico {
  display: block;
  fill: var(--color-white);
}
</style>

<template>
  <nav :class="{ 'footer-nav--opened': isOpenedMenu }" class="footer-nav font">
    <p v-if="!info.url" class="footer-nav__title font_sm font_bold font_uppercase">
      {{ info.name }}
    </p>
    <nuxt-link v-else :to="localePath(info.url)" class="footer-nav__title font_sm font_bold font_uppercase">
      {{ info.name }}
    </nuxt-link>
    <button class="footer-nav__button" type="button" @click="toggleMenu()">
      {{ info.name }}
      <span class="footer-nav__button-ico"></span>
    </button>
    <transition @enter="enter" @leave="leave" @before-enter="beforeEnter">
      <ul v-show="isOpenedMenu" ref="menu" class="footer-nav__menu">
        <li v-for="(item, index) in info.children" :key="index" class="footer-nav__menu-item">
          <nuxt-link :to="localePath(item.url)" class="footer-nav__link font_m">
            {{ item.name }}
          </nuxt-link>
        </li>
      </ul>
    </transition>
  </nav>
</template>

<script>
import './index.css';

export default {
  name: 'FooterNav',
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isOpenedMenu: false,
    };
  },
  computed: {
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet';
    },
  },
  watch: {
    mqIsMobile(value) {
      if (!value) {
        this.isOpenedMenu = true;
        this.$refs.menu.style.height = 'auto';
      } else {
        this.isOpenedMenu = false;
      }
    },
  },
  mounted() {
    this.isOpenedMenu = !this.mqIsMobile;
  },
  methods: {
    toggleMenu() {
      this.isOpenedMenu = !this.isOpenedMenu;
    },
    beforeEnter(el) {
      el.style.height = 0;
    },
    enter(el) {
      el.style.height = `${el.scrollHeight}px`;
    },
    leave(el, done) {
      this.$nextTick(() => {
        el.style.height = 0;
        setTimeout(done, 300);
      });
    },
  },
};
</script>

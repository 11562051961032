<template>
  <div v-click-outside="closeSupportModal" class="header-top__help">
    <button
      :class="{ 'header-top__link--open': isOpenedSupportModal }"
      class="header-top__link header-top__link--toggle font font_m"
      type="button"
      @click="toggleSupportModal"
    >
      {{ $t('header.support') }}
    </button>

    <transition name="fade">
      <Support
        v-if="isOpenedSupportModal"
        :menu="menuHelp"
        class="header-top__help-popup"
      />
    </transition>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import { mapGetters } from 'vuex'
import Support from '~/layouts/components/Header/Support'

export default {
  name: 'HeadereTopHelp',
  components: { Support },
  directives: { ClickOutside },

  data: () => ({
    isOpenedSupportModal: false,
  }),

  computed: {
    ...mapGetters({ menuHelp: 'getMenuHelp' }),
  },

  methods: {
    toggleSupportModal() {
      this.isOpenedSupportModal = !this.isOpenedSupportModal
    },
    closeSupportModal() {
      this.isOpenedSupportModal = false
    },
  },
}
</script>

<style scoped>
.header-top__help {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.header-top__help-popup {
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  z-index: 1001;
  width: 320px;
  background: var(--color-white);
  box-shadow: 0 4px 14px var(--color-shadow);
}
</style>

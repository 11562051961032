<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__main">
        <div v-if="customLogo" class="footer__logo">
          <Logo :custom-logo="customLogo" :is-text="false" />
        </div>
        <FooterNav v-for="(item, index) in menuFooter" :key="index" :info="item" class="footer__nav" />
        <div class="footer__social">
          <p class="footer__social-title font font_sm font_bold font_uppercase">
            {{ $t('footer.social') }}
          </p>
          <Social class="footer__social-list" />
        </div>
      </div>
      <div class="footer__bottom font">
        <ul class="footer__links">
          <li>
            <nuxt-link :to="localePath('/personal_records')" class="footer__link font_sm">
              {{ $t('footer.personalRecords') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link :to="localePath('/public_offer')" class="footer__link font_sm">
              {{ $t('footer.offer') }}
            </nuxt-link>
          </li>
        </ul>
        <p class="footer__copy font_sm">
          &copy; {{ year }} «{{ footerCopy }}»
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';
import env from '@/.env.json';
import Social from '@/components/elements/Social';
import FooterNav from '@/layouts/components/Footer/FooterNav';
import Logo from '~/components/elements/Logo';
import './index.css';

export default {
  name: 'Footer',
  components: {
    Logo,
    Social,
    FooterNav,
  },
  data() {
    return {
      footerCopy: env.FOOTER_COPY,
      customLogo: env.FOOTER_LOGO ? `/assets/images/logo/${env.FOOTER_LOGO}` : '',
    };
  },
  computed: {
    ...mapGetters({
      menuFooter: 'getMenuFooter',
    }),
    year() {
      const today = new Date();
      return today.getFullYear();
    },
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet';
    },
    isMainPage() {
      return this.$route.name.split('___')[0] === 'main';
    },
  },
};
</script>

<template>
  <CustomScroll>
    <div class="search-result">
      <SearchResultSuggestions
        v-if="suggestions.length"
        :suggestions="suggestions"
        @suggestion="onClickSuggestion"
      />
      <SearchResultProducts
        v-if="products.length"
        :products="products"
        @close="onCloseHandler"
      />
    </div>
  </CustomScroll>
</template>

<script>
import SearchResultSuggestions from '~/layouts/components/Search/Result/Suggestions';
import SearchResultProducts from '~/layouts/components/Search/Result/Products';
import CustomScroll from '~/components/elements/CustomScroll';

export default {
  name: 'SearchResult',
  components: {CustomScroll, SearchResultProducts, SearchResultSuggestions},
  props: {
    suggestions: {
      type: Array,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
  },
  methods: {
    onClickSuggestion(suggestion) {
      this.$emit('suggestion', suggestion)
    },
    onCloseHandler() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>

</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input",class:[
    { 'input--float': _vm.float },
    { 'input--active': _vm.active },
    { 'input--invalid': _vm.error },
    { 'input--disabled': _vm.disabled },
    { 'input--white': _vm.styleLight },
  ]},[(_vm.label)?_c('label',{staticClass:"input__label",class:_vm.labelClass,attrs:{"for":_vm.inputId}},[_vm._t("default")],2):_vm._e(),_vm._v(" "),_c('p',{staticClass:"input__container"},[_c('input',_vm._b({ref:"input",staticClass:"input__input",class:_vm.inputClass,attrs:{"id":_vm.inputId,"type":_vm.type,"disabled":_vm.disabled,"aria-describedby":_vm.id ? `${_vm.inputId}-alert` : null,"data-test":_vm.dataTest.length > 0 ? _vm.dataTest : false,"autocomplete":_vm.type === 'password' ? 'off' : 'on'},on:{"focus":function($event){_vm.focus = true},"blur":function($event){_vm.focus = false}}},'input',_vm.$attrs,false)),_vm._v(" "),_vm._t("after")],2),_vm._v(" "),(_vm.showError && _vm.error)?_c('p',{staticClass:"input__message",attrs:{"id":`${_vm.inputId}-alert`,"role":"alert"}},[_vm._t("error",function(){return [_vm._v("\n      "+_vm._s(_vm.error)+"\n    ")]},{"error":_vm.error})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
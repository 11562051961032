var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-select",class:{
    'custom-select--numeric': _vm.isNumeric,
    'custom-select--float': _vm.float,
    'custom-select--active': _vm.$attrs.value,
    'custom-select--white': _vm.styleLight,
    'custom-select--invalid': _vm.error,
    'custom-select--sm': _vm.small,
  }},[(_vm.$slots.default)?_c('label',{staticClass:"custom-select__label",attrs:{"for":_vm.selectId}},[_vm._t("default")],2):_vm._e(),_vm._v(" "),(_vm.error)?_c('p',{staticClass:"custom-select__message",attrs:{"role":"alert"}},[_vm._t("error",function(){return [_vm._v("\n      "+_vm._s(_vm.error)+"\n    ")]},{"error":_vm.error})],2):_vm._e(),_vm._v(" "),_c('client-only',[_c('v-select',{class:_vm.className,attrs:{"id":_vm.selectId,"options":_vm.options,"label":_vm.labelOptionName,"disabled":_vm.disabled,"clearable":false,"searchable":false},on:{"input":function($event){return _vm.$emit('input', _vm.$attrs.value)}},scopedSlots:_vm._u([{key:"open-indicator",fn:function({ attributes }){return [(!_vm.disabled)?_c('span',_vm._b({},'span',attributes,false),[_c('svg-icon',{attrs:{"name":"arrow-down","width":"9","height":"6"}})],1):_vm._e(),_vm._v(" "),(_vm.disabled)?_c('svg',_vm._b({attrs:{"width":"8","height":"6","viewBox":"0 0 8 6","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},'svg',attributes,false),[_c('path',{attrs:{"d":"M1.7766 0.5H6.22356C7.03693 0.5 7.51006 1.41937 7.03729 2.08124L4.81381 5.19411C4.41499 5.75246 3.58517 5.75246 3.18635 5.19411L0.962871 2.08124C0.490108 1.41937 0.963232 0.5 1.7766 0.5Z","fill":"#b2b2b2"}})]):_vm._e()]}}]),model:{value:(_vm.$attrs.value),callback:function ($$v) {_vm.$set(_vm.$attrs, "value", $$v)},expression:"$attrs.value"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <picture ref="picture">
    <template v-if="media">
      <source
        v-for="(item, index) in imageMedia"
        :key="index"
        v-lazy-load
        :srcset="item.image"
        :media="item.media"
      >
    </template>
    <template v-if="isLazyLoad">
      <source v-lazy-load :data-srcset="imageWEBP">
      <img v-lazy-load :alt="alt" :data-src="image" @load="$emit('load')">
    </template>
    <template v-else>
      <source :srcset="imageWEBP">
      <img class="lazyLoad isLoaded" :alt="alt" :src="image" @load="$emit('load')">
    </template>
  </picture>
</template>

<script>
export default {
  name: 'CustomImagePicture',
  props: {
    webp: {
      type: [Array, String],
      required: true,
    },
    src: {
      type: [Array, String],
      required: true,
    },
    retina: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    loading: {
      type: String,
      default: 'lazy',
    },
    media: {
      type: [Array, null],
      default: null,
    },
  },
  data() {
    return {
      pixelRatio: 0,
      breakpoints: [],
    }
  },
  computed: {
    image() {
      return this.getImage(this.src)
    },
    imageWEBP() {
      return this.getImage(this.webp)
    },
    imageMedia() {
      return this.media.map(item => {
        return {
          image: this.getImage(item.image),
          media: item.media,
        }
      })
    },
    isLazyLoad() {
      return this.loading === 'lazy'
    },
  },
  mounted() {
    this.pixelRatio = window.devicePixelRatio
  },
  beforeDestroy() {
    this.breakpoints.length = 0
  },
  methods: {
    getImage(arr) {
      if (this.loading === 'lazy' && !this.pixelRatio) {
        return '/assets/images/lazyimage.png'
      }

      if (typeof arr === 'string') {
        return arr
      }

      const index = this.getRetinaIndex(arr)

      return arr[index]
    },
    getRetinaIndex(arr) {
      const isRetina = arr.length > 1

      return this.pixelRatio > 1 && isRetina ? 1 : 0
    },
  },
}
</script>

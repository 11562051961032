<template>
  <div class="header__mobile-menu">
    <div class="mobile-menu">
      <HeaderMobileMenuList
        v-show="isShowCategories"
        :active-categories="activeCategories"
        @showCategories="showCategories"
        @linkClick="onLinkClick"
      />
      <HeaderMobileMenuFirst v-show="!isShowCategories" @showCategories="showCategories" />
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';

import HeaderMobileMenuList from '~/layouts/components/Header/MobileMenu/List';
import HeaderMobileMenuFirst from '~/layouts/components/Header/MobileMenu/First';

export default {
  name: 'HeaderMobileMenu',
  components: {HeaderMobileMenuFirst, HeaderMobileMenuList},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      activeCategories: {},
    }
  },
  computed: {
    ...mapState('catalog', ['allCategories']),
    ...mapGetters('catalog', ['formattedAllCategories']),
    isShowCategories: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    showCategories(evt, parent = 'root') {
      if (parent === 'root') {
        this.isShowCategories = true
        this.activeCategories = Object.assign({}, this.allCategories);
      } else {
        const parentCategory = this.formattedAllCategories[parent];
        if (parentCategory) {
          this.activeCategories = Object.assign({}, parentCategory.items);
        }
      }
    },
    onLinkClick() {
      this.$emit('linkClick')
    },
  },
}
</script>

<style>
.header__mobile-menu {
  height: calc(var(--app-height) - var(--height-nav-mobile));
  overflow: auto;
}

.mobile-menu {
  overflow-y: auto;
  background: var(--color-white);
  height: 100%;
}

.mobile-menu__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-menu__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-shadow);
}

.mobile-menu__link {
  display: flex;
  align-items: center;
  gap: 10px;
  min-height: 62px;
  padding: 16px;
  font-weight: 800;
  font-size: 17px;
  line-height: 23px;
  color: var(--color-basic-black);
  cursor: pointer;

  flex-grow: 1;
}

.mobile-menu__link-account {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 3.6rem;
  height: 3.6rem;

  border: 2px solid var(--color-basic);
  border-radius: 50%;

  font-size: 1.4rem;
}

.mobile-menu__link--chevron {
  flex-shrink: 1;
  flex-grow: 3;
}

.mobile-menu__link--chevron::after {
  content: '';
  flex-shrink: 0;
  height: 30px;
  width: 30px;
  margin-left: auto;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.737304 1.99138L6.4623 8.06311L0.737305 14.1348L2.4998 16L9.99981 8.06311L2.4998 0.126213L0.737304 1.99138Z' fill='%231E1937'/%3E%3C/svg%3E%0A");
  background-size: 10px 16px;
  background-repeat: no-repeat;
  background-position: center;
}

.mobile-button-open-menu--sticky-small-block.button {
  bottom: 84px;
}

@media all and (min-width: 1024px) {
  .mobile-menu {
    display: none;
  }
}
</style>

export default axios => ({
  getAreasByParentCode(code) {
    try {
      return axios.$get(`/v1/lk/address/codes/${code}`);
    } catch (error) {
      throw new Error(`API Request Error (getAreasByParentCode): ${error}`);
    }
  },
  getAreasByParentId(payload) {
    try {
      return axios.$post('/v1/lk/address/load-areas-by-parent', payload);
    } catch (error) {
      throw new Error(`API Request Error (getAreasByParentId): ${error}`);
    }
  },
})

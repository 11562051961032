<template>
  <div class="layout">
    <Header />
    <div class="container layout__content">
      <main class="layout__main">
        <Nuxt />
      </main>
    </div>
    <CookiesAccept />
  </div>
</template>

<script>
import Header from './components/Header';
import login from '~/mixins/login';
import CookiesAccept from '~/components/elements/CookiesAccept.vue';


export default {
  name: 'CartLayout',
  components: {CookiesAccept, Header },
  mixins: [login],
};
</script>

<style scoped>
.layout__content {
  min-height: 70vh;
}

.layout__main {
  padding-top: 1.6rem;
  flex-shrink: 0;
}

@media all and (min-width: 1280px) {
  .layout__content {
    margin-top: 96px;
    padding: 0 var(--container-padding-desktop);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  .layout__main {
    padding-top: 1.6rem;
    flex-grow: 1;
    flex-shrink: 1;
  }
}
</style>

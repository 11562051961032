<template>
  <div
    class="layout"
    :class="{
      'layout--searching': isOpenedSearch
    }"
  >
    <Header :is-show-hamburger-button="false" />
    <div class="container layout__content">
      <main class="layout__main">
        <Nuxt />
      </main>
    </div>
    <Footer />
    <LocationModal v-if="visibleLocationModal" />
    <CookiesAccept />
  </div>
</template>

<script>
import { mapState } from 'vuex';


import Header from './components/Header';
import Footer from './components/Footer';
import LocationModal from '@/layouts/components/LocationModal';

import {Schema} from '~/Service/schema/Schema';
import {generateFileOriginalPath} from '~/plugins/helpers/file';
import login from '~/mixins/login';
import CookiesAccept from '~/components/elements/CookiesAccept.vue';

export default {
  name: 'MainLayout',
  components: {
    CookiesAccept,
    Header,
    Footer,
    LocationModal,
  },
  mixins: [login],
  head() {
    return {
      script: [
        Schema.organization(this.logoImage),
      ],
    }
  },
  computed: {
    ...mapState({logo: 'logo'}),
    ...mapState({
      isOpenedSidebar: 'isOpenedSidebar',
      isOpenedSearch: 'isOpenedSearch',
    }),
    ...mapState('geolocation', ['visibleLocationModal']),
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet' || this.$mq === 'laptop';
    },
    logoPC() {
      return this.logo?.pc
    },
    logoImage() {
      return generateFileOriginalPath(this.logoPC?.id)
    },
  },
}
</script>

<style>
.layout {
  overflow: hidden;
}

.layout__content {
  min-height: 70vh;
}

.layout__main {
  padding-top: 1.6rem;
  flex-shrink: 0;
}

@media all and (min-width: 1280px) {
  .layout {
    overflow: initial;
  }

  .layout__sidebar {
    position: relative;
    height: auto;
    overflow: initial;
  }

  .layout__sidebar >>> .main-sidebar__wrapper {
    position: sticky;
    top: 28px;
    padding-bottom: 20px;
  }

  .layout__content {
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    padding: 0 var(--container-padding-desktop);
  }

  .layout__main {
    padding-top: 1.6rem;
    width: 100%;
  }
}

@media all and (max-width: 1023px) {
  .layout__content {
    padding-top: 5rem;
  }
}

</style>

<template>
  <div
    class="input"
    :class="[
      { 'input--float': float },
      { 'input--active': active },
      { 'input--invalid': error },
      { 'input--disabled': disabled },
      { 'input--white': styleLight },
    ]"
  >
    <label v-if="label" class="input__label" :class="labelClass" :for="inputId">
      <slot />
    </label>
    <p class="input__container">
      <input
        v-bind="$attrs"
        :id="inputId"
        ref="input"
        class="input__input"
        :class="inputClass"
        :type="type"
        :disabled="disabled"
        :aria-describedby="id ? `${inputId}-alert` : null"
        :data-test="dataTest.length > 0 ? dataTest : false"
        :autocomplete="type === 'password' ? 'off' : 'on'"
        @focus="focus = true"
        @blur="focus = false"
      />
      <slot name="after" />
    </p>
    <p v-if="showError && error" :id="`${inputId}-alert`" class="input__message" role="alert">
      <slot name="error" :error="error">
        {{ error }}
      </slot>
    </p>
  </div>
</template>

<script>
import Cleave from 'cleave.js';

export default {
  name: 'InputMask',
  inheritAttrs: false,
  props: {
    value: {
      default: null,
      required: true,
      validator(value) {
        return value === null || typeof value === 'string' || value instanceof String || typeof value === 'number';
      },
    },
    // https://github.com/nosir/cleave.js/blob/master/doc/options.md
    options: {
      type: Object,
      default: () => ({}),
    },
    // Set this prop to false to emit masked value
    labelClass: {
      type: String,
      default: '',
    },
    inputClass: {
      type: String,
      default: '',
    },
    raw: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    float: {
      type: Boolean,
      default: false,
    },
    showError: {
      type: Boolean,
      default: true,
    },
    error: {
      type: [String, Boolean],
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    label: {
      type: Boolean,
      default: true,
    },
    dataTest: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    styleLight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: null,
      focus: false,
      engine: null,
      isInitialized: false,
      onValueChangedFn: null,
    };
  },
  computed: {
    inputId() {
      return `input-id-${this.id}`;
    },
    active() {
      return this.focus || this.value;
    },
    handlers() {
      const keys = Object.keys(this.$listeners);
      const handlers = {};
      keys.forEach((k) => {
        handlers[k] = (e) => this.$emit(k, e);
      });
      handlers.input = this.input;
      return handlers;
    },
    inputListeners() {
      return Object.assign({}, this.$listeners, {
        input: this.input,
      });
    },
  },
  watch: {
    /**
     * Watch for any changes in options and redraw
     *
     * @param newOptions Object
     */
    options: {
      deep: true,
      handler(newOptions) {
        if (this.engine) this.engine.destroy();
        this.engine = new Cleave(this.$refs.input, this.getOptions(newOptions));
        this.engine.setRawValue(this.value);
      },
    },
    /**
     * Watch for changes from parent component and notify cleave instance
     *
     * @param newValue
     */
    value(newValue) {
      /* istanbul ignore if */
      if (!this.engine) return;

      // when v-model is not masked (raw)
      if (this.raw && newValue === this.engine.getRawValue()) return;
      //  when v-model is masked (NOT raw)
      if (!this.raw && newValue === this.$refs.input.value) return;

      // Lastly set newValue
      this.engine.setRawValue(newValue);
    },
  },
  mounted() {
    this.id = this._uid;

    /* istanbul ignore if */
    if (this.engine) return;
    this.engine = new Cleave(this.$refs.input, this.getOptions(this.options));
    this.engine.setRawValue(this.value);
    this.isInitialized = true;
  },
  /**
   * Free up memory
   */
  beforeDestroy() {
    /* istanbul ignore if */
    if (!this.engine) return;

    this.engine.destroy();
    this.engine = null;
    this.onValueChangedFn = null;
  },
  methods: {
    getOptions(options) {
      // Preserve original callback
      this.onValueChangedFn = options.onValueChanged;

      return Object.assign({}, options, {
        onValueChanged: this.onValueChanged,
      });
    },
    /**
     * Watch for value changed by cleave and notify parent component
     *
     * @param event
     */
    onValueChanged(event) {
      if (!this.isInitialized) return;
      const value = this.raw ? event.target.rawValue : event.target.value;
      this.$emit('input', value);

      // Call original callback method
      // if (typeof this.onValueChangedFn === 'function') {
      //   this.onValueChangedFn.call(this, event);
      // }
    },
    input(e) {
      this.$emit('input', e.target.value);
    },
  },
};
</script>

<style scoped>
.input {
  position: relative;
}

.input__label {
  display: inline-block;
  margin-bottom: 5px;
}

.input__label:empty {
  display: none;
}

.input__input {
  border: 1px solid var(--color-gray-basic);
  background: var(--color-gray-light);
  height: 56px;
  width: 100%;
  font-size: 14px;
  padding: 0 20px;
  box-sizing: border-box;
  -moz-appearance: textfield;
  transition: border 0.3s linear;
}

.input__input[type="password"] {
  font-size: 28px;
  color: var(--color-basic-gray);
  letter-spacing: 8px;
  text-align: center;
}

.input__input[type="password"]::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  position: absolute;
  right: 0;
}

.input__input::-webkit-outer-spin-button,
.input__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input__input:hover {
  border-color: var(--color-gray-ligthfaint);
}

.input__input:focus {
  border-color: var(--color-basic);
  outline: none !important;
}

.input__message {
  margin-top: 6px;
  font-size: var(--font-text-sm-size);
  line-height: var(--font-text-sm-height);
  letter-spacing: 0.07px;
  color: var(--color-basic-secondary-light);
}

.input--invalid .input__input {
  border-color: var(--color-accent-third);
}

.input--invalid .input__message {
  color: var(--color-accent-third);
}

.input--secondary .input__label {
  display: inline-block;
  color: var(--color-basic-gray);
  margin-bottom: 8px;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 21px;
  font-weight: 400;
}

.input--float > .input__label {
  color: var(--color-basic-gray);
}

.input--float.input--invalid >>> .input__message,
.input--float > .input__label {
  position: absolute;
  top: 8px;
  left: 20px;
  font-size: var(--font-text-sm-size);
  letter-spacing: 0.02em;
  line-height: 16px;
  margin: 0;
  white-space: nowrap;
}

.input--float.input--invalid > .input__label {
  display: none;
}

.input--float >>> .input__input {
  padding-top: 24px;
  padding-bottom: 8px;
  font-size: var(--font-text-m-size);
  line-height: var(--font-text-m-height);
  -moz-appearance: textfield;
  transition: border 0.3s linear;
}

@media all and (min-width: 1280px) {
  .input__input {
    border: 1px solid transparent;
  }
}
</style>

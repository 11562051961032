<template>
  <div class="header-bottom" :class="{ 'header-bottom--scroll' : isScrollOffset }">
    <div class="container header-bottom__container">
      <nav class="menu font">
        <HeaderBottomMenu v-model="isShowCategories" />

        <transition name="fade">
          <HeaderBottomCatalog
            v-show="isShowCategories"
            @linkClick="onLinkClick"
          />
        </transition>
      </nav>
    </div>
  </div>
</template>

<script>
import HeaderBottomMenu from '~/layouts/components/Header/Bottom/Menu.vue';
import HeaderBottomCatalog from '~/layouts/components/Header/Bottom/Catalog.vue';

export default {
  name: 'HeaderBottom',
  components: {
    HeaderBottomCatalog,
    HeaderBottomMenu,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    isScrollOffset: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    isShowCategories: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    onLinkClick() {
      this.$emit('linkClick')
    },
  },
}
</script>

<style scoped>
.header-bottom {
  display: none;
}

@media all and (min-width: 1024px) {
  .header-bottom {
    position: relative;
    display: block;
    background: var(--color-basic);
  }

  .header-bottom--scroll {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }
}

</style>

<template>
  <div v-click-outside="closeLangModal" class="header-top-select">
    <button
      :class="{ 'header-top__link--open': isShowLanguages }"
      class="header-top__link header-top__link--toggle font font_m"
      type="button"
      @click="toggleLangModal"
    >
      {{ $i18n.localeProperties.shortName }}
    </button>

    <transition name="fade">
      <Language
        v-if="isShowLanguages && availableLocales.length"
        :locales="availableLocales"
        class="header-top-select__popup"
        @close="closeLangModal"
      />
    </transition>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import Language from '~/layouts/components/Header/Language'

export default {
  name: 'HeaderTopLang',
  components: {
    Language,
  },
  directives: { ClickOutside },

  data: () => ({
    availableLocales: [],
    isShowLanguages: false,
  }),

  methods: {
    async fetchAvailableLocales() {
      this.availableLocales = await this.$api.locales.getLocales()
    },
    toggleLangModal() {
      !this.isShowLanguages && this.fetchAvailableLocales()
      this.isShowLanguages = !this.isShowLanguages
    },
    closeLangModal() {
      this.isShowLanguages = false
    },
  },
}
</script>

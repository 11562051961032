<template>
  <div class="mobile-menu__first">
    <ul class="mobile-menu__list">
      <li class="mobile-menu__item" @click="showCategories">
        <div class="mobile-menu__link">
          {{ $t('shared.breadcrumbs.catalog') }}
        </div>
        <div class="mobile-menu__link mobile-menu__link--chevron"></div>
      </li>

      <li v-for="(menuItem, menuIdx) in menuMain" :key="`mm-${menuIdx}`" class="mobile-menu__item">
        <a v-if="isCurrentRoute(menuItem.url)" class="mobile-menu__link">
          {{ menuItem.name }}
        </a>

        <nuxt-link v-else :to="localePath(menuItem.url)" class="mobile-menu__link">
          {{ menuItem.name }}
        </nuxt-link>
      </li>
    </ul>

    <ul class="mobile-menu__list">
      <li class="mobile-menu__item">
        <button class="mobile-menu__link" @click="accountHandler">
          <span v-if="userText" class="mobile-menu__link-account">
            {{ userText }}
          </span>
          <svg-icon v-else height="24" name="account-middle" width="24" />
          {{ $t('header.account') }}
        </button>
      </li>

      <client-only>
        <li v-if="hasSession" class="mobile-menu__item">
          <nuxt-link :to="localePath({ name: 'favorites' })" class="mobile-menu__link">
            <span v-if="getFavoritesCount" class="header__button-counter">
              {{ getFavoritesCount }}
            </span>
            <svg-icon v-else height="22" name="favorite" width="23" />
            {{ $t('shared.breadcrumbs.favorites') }}
          </nuxt-link>
        </li>
      </client-only>

      <li class="mobile-menu__item">
        <button class="mobile-menu__link" @click="openLocationModal">
          <svg-icon height="24" name="geo" width="24" />
          {{ getSelectedCity }}
        </button>
      </li>

      <li class="mobile-menu__item">
        <custom-select
          :options="langOptions"
          :value="currentLangOption"
          class="languages-select-wrapper"
          class-name="languages-select"
          label-option-name="label"
          @input="$i18n.setLocale($event.value)"
        ></custom-select>
      </li>
    </ul>

    <ul class="mobile-menu__list">
      <li v-for="(menuItem, menuIdx) in menuHelp" :key="`mh-${menuIdx}`" class="mobile-menu__item">
        <a v-if="isCurrentRoute(menuItem.url)" class="mobile-menu__link">
          {{ menuItem.name }}
        </a>

        <nuxt-link v-else :to="localePath(menuItem.url)" class="mobile-menu__link">
          {{ menuItem.name }}
        </nuxt-link>
      </li>
    </ul>

    <Support />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import CustomSelect from '~/components/elements/CustomSelect.vue';
import Support from '~/layouts/components/Header/Support';

export default {
  name: 'HeaderMobileMenuFirst',
  components: { CustomSelect, Support },
  computed: {
    ...mapState('auth', ['hasSession']),
    ...mapGetters({
      menuMain: 'getMenuMain',
      menuHelp: 'getMenuHelp',
      getFavoritesCount: 'favorites/getFavoritesCount',
    }),
    ...mapGetters('auth', ['getAvatarPlaceholder']),
    getSelectedCity() {
      return this.$store.getters['geolocation/getSelectedCity'](this.$i18n);
    },
    userText() {
      return this.getAvatarPlaceholder || '';
    },
    langOptions() {
      return this.$i18n.locales
        .filter((i) => i.code !== this.$i18n.locale)
        .map((lang) => ({ label: lang.name, value: lang.code }));
    },
    currentLangOption() {
      return this.$i18n.locales
        .filter((i) => i.code === this.$i18n.locale)
        .map((lang) => ({ label: lang.name, value: lang.code }))[0];
    },
  },
  methods: {
    ...mapMutations('auth', {
      setIsOpenedAuthModal: 'SET_IS_OPENED_AUTH_MODAL',
    }),
    ...mapMutations('geolocation', {
      setVisibleLocationModal: 'SET_VISIBLE_LOCATION_MODAL',
    }),
    isCurrentRoute(url) {
      const isCatalogUrl = url === '/catalog';

      return (
        url === this.$route.path || (isCatalogUrl && this.$route.path.includes(url) && !this.$route.params.product)
      );
    },
    showCategories(event) {
      this.$emit('showCategories', event);
    },
    accountHandler() {
      if (!this.hasSession) {
        this.setIsOpenedAuthModal(true);
      } else {
        this.$router.push(this.localePath({ name: 'profile' }));
      }
    },

    openLocationModal() {
      this.setVisibleLocationModal(true);
    },
  },
};
</script>

<style scoped>
.mobile-menu__item--chevron .mobile-menu__link {
  width: 100%;
}

.languages-select-wrapper {
  width: 100%;

  & >>> .languages-select > div {
    border: none;
  }
}
</style>

export const getProductDemensions = (product) => {
  if (product?.width && product?.height) {
    let value = `${product.width} × ${product.height}`;

    if (product.length) value += ` × ${product.length}`;

    return {
      name: 'shared.size',
      value: ['product.sizeCm', { value }],
    };
  }

  return null;
};

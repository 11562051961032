<template>
  <CustomModal
    id="location-modal"
    :empty="true"
    :visible="visibleLocationModal"
    @close="closeModal()"
  >
    <template #content>
      <LocationSelection @close="closeModal()" />
    </template>
  </CustomModal>
</template>

<script>
import './index.css';
import { mapMutations, mapState } from 'vuex';
import CustomModal from '@/components/elements/CustomModal';
import LocationSelection from '@/components/auth/LocationSelection';

export default {
  name: 'LocationModal',

  components: {
    CustomModal, LocationSelection,
  },

  data() {
    return {
      scrollLock: false,
    }
  },

  computed: {
    ...mapState('geolocation', [
      'visibleLocationModal',
    ]),
  },

  methods: {
    ...mapMutations('geolocation', {
      setVisibleLocationModal: 'SET_VISIBLE_LOCATION_MODAL',
    }),

    closeModal() {
      this.setVisibleLocationModal(false);
    },
  },
};
</script>

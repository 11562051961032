<template>
  <ul class="search-result__products search-products">
    <li v-for="(item, index) in products" :key="index">
      <nuxt-link
        :to="
          localePath({
            name: 'catalog.category.product',
            params: {
              category: item.categoryCodes[0],
              product: item.code,
            },
          })
        "
        @click.native="onClickHandler"
      >
        <SearchResultProductsItem :item="item" />
      </nuxt-link>
    </li>
  </ul>
</template>

<script>
import SearchResultProductsItem from '~/layouts/components/Search/Result/Products/Item';

export default {
  name: 'SearchResultProducts',
  components: { SearchResultProductsItem },
  props: {
    products: {
      type: Array,
      required: true,
    },
  },
  methods: {
    onClickHandler(item) {
      this.$emit('close');
    },
  },
};
</script>

<style scoped></style>

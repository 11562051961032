<template>
  <ul class="search-result__suggestions search-suggestions">
    <li
      v-for="(item, index) in suggestions"
      :key="index"
      class="search-suggestions__item font font_m font_grey"
      @click="onClickSuggestion(item)"
    >
      {{ item }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SearchResultSuggestions',
  props: {
    suggestions: {
      type: Array,
      required: true,
    },
  },
  methods: {
    onClickSuggestion(item) {
      this.$emit('suggestion', item)
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <div class="support">
    <div v-if="supportPhone || supportEmail" class="support__content">
      <p v-if="supportTime" class="support__time font font_m font_grey">
        {{ supportTime }}
      </p>

      <a
        v-if="supportPhone"
        :href="`tel:${supportPhone}`"
        class="support__phone font font_primary font_bold font_title-l"
      >
        {{ phone }}
      </a>

      <!-- <ul class="support__social">
        <li class="support__social-item">
          <Button
            href="https://vk.me/#"
            target="_blank"
            class="support__social-link"
            aria-label="Связаться с нами в VK"
            rel="nofollow noreferrer noopener"
            round
            only-icon
          />
        </li>

        <li class="support__social-item">
          <Button
            href="tg://resolve?domain=#"
            target="_blank"
            class="support__social-link"
            aria-label="Связаться с нами в Telegram"
            rel="nofollow noreferrer noopener"
            round
            only-icon
          />
        </li>

        <li class="support__social-item">
          <Button
            href="#"
            target="_blank"
            class="support__social-link"
            :aria-label="$t('header.chatWithUs')"
            rel="nofollow noreferrer noopener"
            round
            only-icon
          />
        </li>
      </ul> -->

      <template v-if="supportEmail">
        <div class="support__item-wrapper">
          <p class="support__label">
            {{ $t('shared.email') }}:
          </p>
          <a :href="`mailto:${supportEmail}`" class="support__mail font font_m font_medium font_primary">
            {{ supportEmail }}
          </a>
        </div>
        <p class="support__time font font_m font_grey">
          {{ $t('header.supportMessage') }}
        </p>
      </template>
    </div>

    <ul v-if="menu.length" class="support__links">
      <li v-for="(link, idx) in menu" :key="`menu${idx}`" class="support__links-item">
        <!--TODO check-->
        <nuxt-link :to="localePath(link.url)" class="support__link font font_m font_underscore">
          {{ link.name }}
        </nuxt-link>
      </li>
    </ul>
  </div>
</template>

<script>
import env from '@/.env.json';
import { phoneFormat } from '~/plugins/formatting';
import './index.css';

export default {
  name: 'HeaderSupport',
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      env,
    };
  },
  computed: {
    supportPhone() {
      return this.env.SUPPORT_PHONE;
    },
    supportEmail() {
      return this.env.SUPPORT_EMAIL;
    },
    supportTime() {
      return this.env.SUPPORT_TIME;
    },
    phone() {
      return this.phoneFormat(this.supportPhone);
    },
  },
  methods: {
    phoneFormat,
  },
};
</script>

export const sortOptions = Object.freeze({
  POPULARITY: 'popularity', // default
  PRICE: 'price',
  NEW: 'new',
  DISCOUNT: 'discount',
  RELEVANCE: 'relevance', // default for search
});

export const sortDirections = Object.freeze({
  DESC: 'desc',
  ASC: 'asc',
});

export function sortOptionsListGenerator() {
  return [
    {
      id: 1,
      title: this.$t('shared.sort.popular'),
      value: sortOptions.POPULARITY,
      direction: sortDirections.DESC,
    },
    {
      id: 2,
      title: this.$t('shared.sort.moreExpensiveFirst'),
      value: sortOptions.PRICE,
      direction: sortDirections.DESC,
    },
    {
      id: 3,
      title: this.$t('shared.sort.cheaperFirst'),
      value: sortOptions.PRICE,
      direction: sortDirections.ASC,
    },
    {
      id: 4,
      title: this.$t('shared.novelty'),
      value: sortOptions.NEW,
      direction: sortDirections.DESC,
    },
    {
      id: 5,
      title: this.$t('shared.sort.byDiscountAmount'),
      value: sortOptions.DISCOUNT,
      direction: sortDirections.DESC,
    },
    {
      id: 6,
      title: this.$t('shared.sort.relevance'),
      value: sortOptions.RELEVANCE,
      direction: sortDirections.DESC,
    },
  ];
}

export function sortOptionsListFavoritesGenerator() {
  return [
    {
      id: 1,
      title: this.$t('shared.sort.moreExpensiveFirst'),
      value: sortOptions.PRICE,
      direction: sortDirections.DESC,
    },
    {
      id: 2,
      title: this.$t('shared.sort.cheaperFirst'),
      value: sortOptions.PRICE,
      direction: sortDirections.ASC,
    },
  ];
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu__catalog"},[_c('div',{staticClass:"menu__catalog-content"},[_c('div',{staticClass:"container"},[_c('ul',{staticClass:"menu__catalog-list"},_vm._l((_vm.allCategories),function(category){return _c('li',{key:category.id,staticClass:"menu__catalog-item"},[_c('nuxt-link',{staticClass:"menu__catalog-category-title font font_m font_uppercase font_bold font_underline",attrs:{"to":_vm.localePath({
                name: 'catalog.category',
                params: { category: category.code },
              })},nativeOn:{"click":function($event){return _vm.onLinkClick.apply(null, arguments)}}},[_vm._v("\n            "+_vm._s(category.name)+"\n          ")]),_vm._v(" "),(category.items && category.items.length > 0)?_c('ul',{staticClass:"menu__catalog-subitems"},_vm._l((category.items),function(subcategory){return _c('li',{key:subcategory.id,staticClass:"menu__catalog-subitem"},[_c('nuxt-link',{staticClass:"menu__catalog-subcategory-link font font_m",attrs:{"to":_vm.localePath({
                    name: 'catalog.category',
                    params: { category: subcategory.code },
                  })},nativeOn:{"click":function($event){return _vm.onLinkClick.apply(null, arguments)}}},[_vm._v("\n                "+_vm._s(subcategory.name)+"\n              ")])],1)}),0):_vm._e()],1)}),0)])]),_vm._v(" "),_c('button',{staticClass:"menu__overlay",attrs:{"aria-label":_vm.$t('shared.closeMenu'),"type":"button"},on:{"click":_vm.onLinkClick}})])
}
var staticRenderFns = []

export { render, staticRenderFns }
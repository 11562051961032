export default axios => ({
  getLocales() {
    try {
      return axios.$get('/v1/langs')
    } catch (error) {
      throw new Error(`API Request Error (getLocales): ${ error }`)
    }
  },
  getTranslations(lang) {
    try {
      return axios.$get(`/admin/front/translations/${ lang }.json`)
    } catch (error) {
      throw new Error(`API Request Error (getTranslations): ${ error }`)
    }
  },
  setLocaleToProfile(contentLang) {
    try {
      return axios.$put('/v1/lk/profile/personal', { contentLang })
    } catch (error) {
      throw new Error(`API Request Error (setLocaleToProfile): ${ error }`)
    }
  },
})

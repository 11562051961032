<template>
  <transition name="fade">
    <div v-scroll-lock="scrollLock" :class="{ 'modal--small': small }" class="modal">
      <div class="modal__wrap">
        <button :aria-label="$t('shared.close').toString()" class="modal__overlay" type="button" @click="close">
          <span class="visually-hidden">{{ $t('elements.modal.close') }}</span>
        </button>
        <div :class="{ 'modal__body--empty': empty, 'modal__body--content-size': contentSize }" class="modal__body">
          <header v-if="!empty" class="modal__header">
            <h3 v-if="title" class="modal__title">
              {{ title }}
            </h3>
            <button :aria-label="$t('shared.close').toString()" class="modal__close" type="button" @click="close">
              <svg-icon :name="closeIcon" height="15" width="15" />
              <div v-if="closeTitle" class="modal__close-title">
                {{ closeTitle }}
              </div>
            </button>
          </header>
          <div class="modal__content">
            <slot name="content" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CustomModal',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    empty: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    contentSize: {
      type: Boolean,
      default: false,
    },
    closeIcon: {
      type: String,
      default: 'close',
    },
    closeTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      scrollLock: false,
    };
  },
  watch: {
    visible(value) {
      this.scrollLock = value;
    },
  },
  mounted() {
    this.scrollLock = this.visible;
  },
  beforeDestroy() {
    this.scrollLock = false;
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style>
.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 9999;
  overflow: auto;
}

.modal__loader {
  margin-top: 30px;
}

.modal__content {
  height: 100%;
}

.modal__overlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background: var(--color-overlay);
}

.modal__wrap {
  min-height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__body {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 30px 16px;
  overflow-y: scroll;
  background: #fff;
}

.modal__body--empty {
  padding: 0;
}

.modal__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  column-gap: 30px;
}

.modal__title {
  margin: 0;
  font-size: var(--font-text-m-height);
  line-height: var(--font-text-m-height);
  font-weight: 700;
  text-transform: uppercase;
}

.modal__close {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__close-title {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  margin-left: 8px;
}

.personal .modal__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: flex-start;
}

.personal .modal__text {
  margin-bottom: 20px;
}

.modal__recall-time {
  margin-top: 20px;
}

.personal .modal__recall-time {
  margin-top: 0;
  margin-bottom: 20px;
}

.personal .modal__submit-button {
  width: 100%;
}

.modal__recall-time-button {
  text-decoration: underline;
}

.modal__form-control {
  margin-bottom: 10px;
}

.modal--small .modal__body {
  position: relative;
  border-radius: 4px;
  width: 335px;
  max-width: 100%;
}

@media all and (min-width: 480px) {
  .modal__body {
    position: relative;
    width: 440px;
    max-width: 100%;
    margin: 34px;
    overflow-y: initial;
  }

  .modal__body--content-size {
    width: fit-content;
  }
}
</style>

export default ({ app }) => {
  const locales = [ {
    locale: app.i18n.defaultLocale,
    promise: app.$api.locales.getTranslations(app.i18n.defaultLocale),
  } ]

  if (app.i18n.locale !== app.i18n.defaultLocale) {
    locales.push({ locale: app.i18n.locale, promise: app.$api.locales.getTranslations(app.i18n.locale) })
  }

  Promise.allSettled(locales.map(el => el.promise))
    .then(results => results.forEach((result, idx) => {
      if (result.status === 'fulfilled') app.i18n.setLocaleMessage(locales[idx].locale, result.value)
      if (result.status === 'rejected') console.error(`${ locales[idx].locale }: ${ result.reason }`)
    }))

  app.i18n.onLanguageSwitched = async (oldLocale, newLocale) => {
    if (!app.i18n.messages[newLocale]) {
      app.i18n.setLocaleMessage(newLocale, await app.$api.locales.getTranslations(newLocale))
    }

    await app.store.dispatch('onLanguageSwitched')
  }
}

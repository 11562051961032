import menuCodes from '@/plugins/enums/menu'

export const state = () => ({
  isCityConfirmationOpen: false,
  isOpenedSidebar: false,
  isOpenedSearch: false,
  menu: [],
  logo: {},
  searchStringCurrent: '',
})

export const getters = {
  getMenuMain: (state) => {
    const menuBlock = state.menu.find(item => item.code === menuCodes.MENU_MAIN)
    return menuBlock ? menuBlock.items : []
  },
  getMenuHelp: (state) => {
    const menuBlock = state.menu.find(item => item.code === menuCodes.MENU_HELP)
    return menuBlock ? menuBlock.items : []
  },
  getMenuFooter: (state) => {
    const menuBlock = state.menu.find(item => item.code === menuCodes.MENU_FOOTER)
    return menuBlock ? menuBlock.items : []
  },
}

export const mutations = {
  SET_IS_OPENED_SIDEBAR(state, payload) {
    state.isOpenedSidebar = payload
  },

  SET_IS_OPENED_SEARCH(state, payload) {
    state.isOpenedSearch = payload
  },

  SET_MENU(state, payload) {
    state.menu = payload
  },

  SET_SEARCH_STRING(state, payload) {
    state.searchStringCurrent = payload
  },

  SET_LOGO(state, payload) {
    state.logo = payload
  },
}

export const actions = {
  setIsOpenedSidebar({ commit }, payload) {
    commit('SET_IS_OPENED_SIDEBAR', payload)
  },

  setIsOpenedSearch({ commit }, payload) {
    commit('SET_IS_OPENED_SEARCH', payload)
  },

  setSearchStringCurrent({ commit }, payload) {
    commit('SET_SEARCH_STRING', payload)
  },

  async fetchMenu({ commit }) {
    const menu = await this.$api.general.getMenu()

    commit('SET_MENU', menu)
  },

  async nuxtServerInit({ dispatch, commit }) {
    const { logotype: logo } = await this.$api.general.getLogo()

    commit('SET_LOGO', { pc: logo?.logotype, mobile: logo?.mobile })

    await Promise.allSettled([
      dispatch('fetchMenu'),
      dispatch('catalog/getAllCategories'),
      // dispatch('catalog/getFrequentCategories', null, { root: true }),
    ])
  },

  async nuxtClientInit({ dispatch }) {
    await dispatch('cart/getCartItemsCount', null, { root: true })
    dispatch('geolocation/setSelectedCityByIp', null, { root: true })
  },

  async onLanguageSwitched({ dispatch, state }) {
    const setLocaleToProfilePromise = state.auth.hasSession
      ? this.$api.locales.setLocaleToProfile(this.$i18n.locale)
      : Promise.resolve()

    await Promise.allSettled([
      dispatch('fetchMenu'),
      dispatch('catalog/getAllCategories'),
      dispatch('geolocation/setSelectedCityByIpWithoutCookie', null, { root: true }),
      setLocaleToProfilePromise,
    ])
  },
}

<template>
  <div class="header-top-select__item-wrap">
    <nuxt-link
      v-for="locale in availableLocales"
      :key="locale.code"
      :to="switchLocalePath(locale.code)"
      class="header-top-select__item"
    >
      {{ locale.name }}
    </nuxt-link>
  </div>
</template>

<script>
export default {
  name: 'Language',
  props: {
    locales: { type: Array, required: true },
  },
  computed: {
    i18nLocalesCode() {
      return this.$i18n.locales.map(el => el.code)
    },
    availableLocales() {
      return this.locales.filter(el => this.i18nLocalesCode.includes(el.code) && el.code !== this.$i18n.locale)
    },
  },
}
</script>

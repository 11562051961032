import env from '@/.env.json';

const configDadata = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Token ${env.DADATA_API_KEY}`,
  },
};

export default axios => ({
  findAddressDadata(payload) {
    try {
      return axios.$post('/suggest/address', payload, configDadata);
    } catch (error) {
      throw new Error(`API Request Error (findAddressDadata): ${error}`);
    }
  },
  findAddressById(payload) {
    try {
      return axios.$post('/dadataApiUrl/findById/address', payload, configDadata);
    } catch (error) {
      throw new Error(`API Request Error (findAddressById): ${error}`);
    }
  },
  geolocateAddressDadata(payload) {
    try {
      return axios.$post('/geolocate/address', payload, configDadata);
    } catch (error) {
      throw new Error(`API Request Error (geolocateAddressDadata): ${error}`);
    }
  },
  getSelectedCityByIpDadata(payload) {
    try {
      return axios.$post('/dadataApiUrl/iplocate/address', payload, configDadata);
    } catch (error) {
      throw new Error(`API Request Error (getSelectedCityByIpDadata): ${error}`);
    }
  },
  getIpUrl() {
    try {
      return axios.$get(`${env.GEO_API}/getIpUrl/`);
    } catch (error) {
      throw new Error(`API Request Error (getIpUrl): ${error}`);
    }
  },
  setSelectedCity(payload) {
    try {
      return axios.$put('/v1/auth/city', payload);
    } catch (error) {
      throw new Error(`API Request Error (setSelectedCity): ${error}`);
    }
  },

  findCompanyById(query, count = 1) {
    try {
      return axios.$post('/dadataApiUrl/findById/party', { query, count }, configDadata);
    } catch (error) {
      throw new Error(`API Request Error (findCompanyById): ${error}`);
    }
  },

  findBankById(query, count = 1) {
    try {
      return axios.$post('/dadataApiUrl/findById/bank', { query, count }, configDadata);
    } catch (error) {
      throw new Error(`API Request Error (findBankById): ${error}`);
    }
  },
});

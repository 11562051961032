import createPersistedState from 'vuex-persistedstate';

export default ({store}) => {
  createPersistedState({
    key: 'WestWing',
    paths: [
      'favorites.favoritesData',
      'geolocation.isConfirmedCity',
    ],
  })(store)
};

import {mapActions, mapMutations, mapState} from 'vuex';
import appHeight from '~/mixins/appHeight';

export default {
  mixins: [appHeight],
  computed: {
    ...mapState('auth', ['hasSession', 'firstLoad', 'newReferrerCode']),
  },
  methods: {
    ...mapMutations('auth', {
      setFirstLoad: 'SET_FIRST_LOAD',
    }),
    ...mapActions({
      checkSession: 'auth/checkSession',
      getLastPage: 'auth/getLastPage',
      removeLastPage: 'auth/removeLastPage',
      setReferralSession: 'auth/setReferralSession',
      fetchUser: 'auth/fetchUser',
      getFavoritesAll: 'favorites/getFavoritesAll',
    }),
  },
  async mounted() {
    if (!this.firstLoad) return

    if (!this.hasSession) {
      const lastPage = await this.getLastPage()

      if (lastPage) {
        this.removeLastPage()
      }

      const isLogin = await this.checkSession({ force: true, favorites: false })
      this.setFirstLoad(false)

      if (isLogin) {
        if (lastPage) {
          this.$router.push({ path: lastPage })
        }

        this.fetchUser()
        this.getFavoritesAll()
      }
    }

    this.newReferrerCode && this.setReferralSession(this.newReferrerCode)
  },
}

import { MAX_AGE_COOKIE_CITY } from '@/plugins/config';
import { cookieNames } from '@/plugins/enums/cookie';
import { suggestionTypes } from '@/plugins/enums/suggestions';
import CountryCodes from '~/constants/country-codes';

export const state = () => ({
  selectedCity: null,
  cityFias: null,
  visibleLocationModal: false,
  isConfirmedCity: false,
  countryCode: CountryCodes.UZ,
});

export const getters = {
  getCountryCode: (state) => state.countryCode,
  getSelectedCity: (state) => ($i18n) => {
    return (state.selectedCity && state.selectedCity.name) || $i18n.t('auth.region.chooseCity');
  },
  getSelectedCityType: (state) => (state.selectedCity?.type ? `${ state.selectedCity?.type }.` : ''),
  getSelectedCityCoords: (state) => [ Number(state.selectedCity?.geo_lat), Number(state.selectedCity?.geo_lon) ],
};

export const mutations = {
  SET_SELECTED_CITY: (state, payload) => {
    state.selectedCity = payload;
  },
  SET_IS_CONFIRMED_CITY(state, payload) {
    state.isConfirmedCity = payload;
  },
  SET_CITY_FIAS(state, payload) {
    state.cityFias = payload;
  },
  SET_VISIBLE_LOCATION_MODAL(state, payload) {
    state.visibleLocationModal = payload;
  },
};

export const actions = {
  findAddress({ commit, state }, payload) {
    const data = {
      query: payload.query,
      count: payload.count,
      locations: { ...payload.locations, country_iso_code: state.countryCode },
      from_bound: { value: payload.fromBound },
      to_bound: { value: payload.toBound },
    };

    if (payload.type) {
      const fromBound = { value: payload.type };
      let toBound;
      switch (payload.type) {
        case suggestionTypes.REGION:
          toBound = { value: suggestionTypes.AREA };
          break;
        case suggestionTypes.CITY:
          toBound = { value: suggestionTypes.SETTLEMENT };
          break;
        case suggestionTypes.HOUSE:
          break;
        default:
          toBound = { value: payload.type };
      }
      data.from_bound = fromBound;
      data.to_bound = toBound;
    }

    if (payload.locationsBoost) {
      data.locations_boost = [
        {
          kladr_id: state.selectedCity?.kladr_id,
        },
      ];
    }

    if (this.$i18n.locale !== 'ru') data.language = 'en';

    return this.$api.geolocation.findAddressDadata(data);
  },
  setIsConfirmedCity({ commit }, payload) {
    commit('SET_IS_CONFIRMED_CITY', payload);
  },
  async geolocateAddress({ commit }, payload) {
    try {
      const { suggestions } = await this.$api.geolocation.geolocateAddressDadata(payload);
      return suggestions;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  async setSelectedCity({ commit }, payload) {
    try {
      const data = {
        name: payload.city.name,
        fias_id: payload.city.fias_id,
        region_fias_id: payload.city.region_fias_id,
      };
      commit('SET_SELECTED_CITY', payload.city);
      if (payload.setCookie) {
        this.$cookies.set(cookieNames.GEOLOCATION, JSON.stringify(payload.city), {
          maxAge: MAX_AGE_COOKIE_CITY,
          path: '/',
        });
      }
      // Почему-то зависает при принудительной загрузке в корзине (Nuxt SSR)
      await this.$api.geolocation.setSelectedCity(data);
    } catch (error) {
      console.error(error);
    }
  },
  async getSelectedCityByIp() {
    const ip = (await this.$api.geolocation.getIpUrl()).replace(/[^.\d]/g, '');

    try {
      if (ip) {
        const payload = { ip };
        if (this.$i18n.locale !== 'ru') payload.language = 'en';

        const { location } = await this.$api.geolocation.getSelectedCityByIpDadata(payload);
        if (location) return normalizeCity(location.data);
      }

      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  },
  async getSelectedCityByCoords() {
    try {
      const coords = await getCoords();
      const payload = { ...coords, count: 1 };
      if (this.$i18n.locale !== 'ru') payload.language = 'en';

      const { suggestions } = await this.$api.geolocation.geolocateAddressDadata(payload);
      if (suggestions.length) return normalizeCity(suggestions[0].data);

      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  },
  async setSelectedCityByIp({ dispatch }) {
    try {
      let city = this.$cookies.get(cookieNames.GEOLOCATION);
      if (!city) city = await getCity(dispatch);
      if (!city) city = getDefaultCity.call(this);

      await dispatch('setSelectedCity', { city, setCookie: false });
    } catch (error) {
      console.error(error);
    }
  },

  async setSelectedCityByIpWithoutCookie({ dispatch }) {
    try {
      let city = await getCity(dispatch);
      if (!city) city = getDefaultCity.call(this);

      await dispatch('setSelectedCity', { city, setCookie: false });
    } catch (error) {
      console.error(error);
    }
  },

  async selectCity({ commit, dispatch, rootState, rootGetters }, suggestion) {
    try {
      const { suggestions } = await dispatch('findAddress', {
        type: suggestionTypes.CITY,
        query: suggestion.value,
        count: 1,
      });
      const selectedCitySuggestion = suggestions[0];
      if (selectedCitySuggestion) {
        const selectedData = selectedCitySuggestion.data;
        await dispatch('setSelectedCity', {
          city: {
            name: selectedData.settlement || selectedData.city,
            type: selectedData.settlement_type || selectedData.city_type,
            fias_id: selectedData.settlement_fias_id || selectedData.city_fias_id,
            geo_lat: selectedData.geo_lat,
            geo_lon: selectedData.geo_lon,
            region_fias_id: selectedData.region_fias_id,
            kladr_id: selectedData.kladr_id ?? null,
          },
          setCookie: true,
        });

        commit('SET_CITY_FIAS', {
          city: selectedData.settlement || selectedData.city,
          city_guid: selectedData.settlement_fias_id || selectedData.city_fias_id,
          country_code: 'RU',
          post_index: selectedData.postal_code,
          region: selectedData.region,
          region_guid: selectedData.region_fias_id,
        });

        const checkoutData = rootState.checkout.checkoutData;

        if (checkoutData) {
          const getAddresses = rootGetters['checkout/getAddresses'];

          const addressByCity = getAddresses.find((item) => {
            return item.city_guid === selectedData.settlement_fias_id || item.city_guid === selectedData.city_fias_id;
          });

          let data = {};

          if (addressByCity !== undefined) {
            data = { address: addressByCity, save2Lk: true };
          } else {
            data = {
              address: {
                city: selectedData.settlement || selectedData.city,
                city_guid: selectedData.settlement_fias_id || selectedData.city_fias_id,
                country_code: 'RU',
                post_index: selectedData.postal_code,
                region: selectedData.region,
                region_guid: selectedData.region_fias_id,
              },
              save2Lk: false,
            };
          }
          await dispatch('checkout/setAddress', data, { root: true });
        }

        commit('SET_IS_CONFIRMED_CITY', false);
      }
    } catch (error) {
      console.error(error);
    }
  },
};

async function getCity(dispatch) {
  let city = await dispatch('getSelectedCityByIp');
  if (!city) city = await dispatch('getSelectedCityByCoords');

  return city;
}

function getDefaultCity() {
  return {
    name: this.$i18n.t('geolocation.defaultCity.name'),
    type: this.$i18n.t('geolocation.defaultCity.type'),
    fias_id: 'relation:2216724',
    geo_lat: '41.311158',
    geo_lon: '69.279737',
  };
}

const normalizeCity = (data) => ({
  name: data.settlement || data.city,
  type: data.settlement_type || data.city_type,
  fias_id: data.settlement_fias_id || data.city_fias_id,
  geo_lat: data.geo_lat,
  geo_lon: data.geo_lon,
  kladr_id: data.kladr_id,
});

function getCoords() {
  return new Promise((resolve, reject) => {
    if (!('geolocation' in navigator)) {
      reject(new Error('Geolocation is not available.'));
    }

    navigator.geolocation.getCurrentPosition(
      (pos) => resolve({ lat: pos.coords.latitude, lon: pos.coords.longitude }),
      (err) => reject(err),
    );
  });
}

export const billingOperationType = Object.freeze({
  EARNING: 1, // начисление
  PAYMENT: 2, // оплата заказа
  REFUND: 3, // возврат
  WITHDRAWTOCARD: 4, // вывод средств на карту
  ADJUSTMENT: 5, // корректировка
  WITHDRAWTOACCOUNT: 6, // вывод средств на счёт
});

export function billingOperationTypeNameGenerator() {
  return Object.freeze({
    [billingOperationType.EARNING]: this.$t('shared.billingOperationTypeName.1'),
    [billingOperationType.PAYMENT]: this.$t('shared.billingOperationTypeName.2'),
    [billingOperationType.REFUND]: this.$t('shared.billingOperationTypeName.3'),
    [billingOperationType.WITHDRAWTOCARD]: this.$t('shared.billingOperationTypeName.4'),
    [billingOperationType.ADJUSTMENT]: this.$t('shared.billingOperationTypeName.5'),
    [billingOperationType.WITHDRAWTOACCOUNT]: this.$t('shared.billingOperationTypeName.6'),
  });
}

export const preferencesTypes = Object.freeze({
  PERSONAL: 'personal',
  PRO: 'pro',
});

export const preferencesIdByType = Object.freeze({
  personal: 1,
  pro: 2,
});

export const billingIdentificationStatus = Object.freeze({
  SUCCESS: 'success', // удачно
  FAIL: 'fail', // неудачно
  ERROR: 'error', // ошибка
});

export const referralOrderSortFields = Object.freeze({
  NAME: 'name',
  QTY: 'qty',
  SOURCE: 'source',
  ORDER_DATE: 'order_date',
  CUSTOMER_ID: 'customer_id',
  PRICE_PRODUCT: 'price_product',
  PRICE_COMMISSION: 'price_commission',
});

export const referralSource = Object.freeze({
  PROMOCODE: 1, // промокод
  LINK: 2, // реферальная ссылка
});

export function referralSourceNameGenerator() {
  return Object.freeze({
    [referralSource.PROMOCODE]: this.$t('profile.promocodes.promocode'),
    [referralSource.LINK]: this.$t('profile.referralLink'),
  });
}

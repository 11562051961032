<template>
  <div v-click-outside="closeModal" class="header-top-select">
    <button
      :class="{ 'header-top__link--open': isShowCurrencies }"
      class="header-top__link header-top__link--toggle font font_m"
      type="button"
      @click="isShowCurrencies = !isShowCurrencies"
    >
      {{ selectedCurrency?.code }}
    </button>

    <transition name="fade">
      <Currency
        v-if="isShowCurrencies"
        class="header-top-select__popup"
        @close="closeModal"
      />
    </transition>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import { mapGetters } from 'vuex'
import Currency from '~/layouts/components/Header/Currency'

export default {
  name: 'HeaderTopCurrency',
  components: { Currency },
  directives: { ClickOutside },
  data: () => ({
    isShowCurrencies: false,
  }),
  computed: {
    ...mapGetters('currency', [ 'selectedCurrency' ]),
  },
  methods: {
    closeModal() {
      this.isShowCurrencies = false
    },
  },
}
</script>

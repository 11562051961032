<template>
  <Nuxt />
</template>

<script>
import login from '~/mixins/login';

export default {
  name: 'ErrorLayout',
  mixins: [login],
}
</script>

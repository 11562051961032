import Vue from 'vue'
import { capitalize } from '~/helpers/string'
import { cookieNames } from '~/plugins/enums/cookie'

const prefix = '[Currency error]:'

const priceTypeError = value => {
  const error = `Invalid price: type check failed for price. 
  Expected Object, got ${ capitalize(typeof value) } with value ${ value }.`

  return new TypeError(`${ prefix } ${ error }`)
}
const priceReferenceError = value => {
  const error = `Invalid price: object check failed for price. 
  Object should be contain "value" and "currencyId", got Object with value ${ JSON.stringify(value) }.`

  return new ReferenceError(`${ prefix } ${ error }`)
}

export default async ({ app, store, $cookies, $api }) => {
  try {
    await store.dispatch('currency/getCurrencies')

    let currencyId = $cookies.get(cookieNames.CURRENCY)

    if (!currencyId) {
      currencyId = store.getters['currency/currencies'].find(el => el.default).id
      $cookies.set(cookieNames.CURRENCY, currencyId)
    }

    await store.dispatch('currency/setSelectedCurrency', currencyId)
  } catch (e) {
    console.error(e)
  }

  Vue.filter('price', function (price) {
    try {
      if (typeof price !== 'object') throw priceTypeError(price)
      if (!('value' in price && 'currencyId' in price)) throw priceReferenceError(price)

      const currencies = store.getters['currency/currencies']
      const currency = currencies.find(el => el.id === price.currencyId)

      const isNegativeNumber = price.value < 0
      const value = Math.abs(parseFloat(price.value)).toFixed(currency.precision)
      const valueFormatted = new Intl.NumberFormat('ru-RU', {}).format(value)
      const symbol = currency.symbol || currency.abbreviation
      const separator = currency.symbolDelimiter ? '\u202F' : ''

      const priceArr = [ valueFormatted ]

      if (currency.isSymbolRight) priceArr.push(symbol)
      else priceArr.unshift(symbol)

      const result = priceArr.join(separator)

      return isNegativeNumber ? `-\u00A0${ result }` : result
    } catch (e) {
      console.error(e.message)
      return price?.value ?? price
    }
  })

  Vue.prototype.$currency = {
    selected: store.getters['currency/selectedCurrency'],
    available: store.getters['currency/availableCurrencies'],
    getCurrencySymbol: store.getters['currency/getCurrencySymbol'],
    setCurrency: async (id) => {
      $cookies.set(cookieNames.CURRENCY, id)
      await store.dispatch('currency/setSelectedCurrency', id)
      if (store.state.auth.hasSession) await $api.currency.setCurrencyToProfile(id)
      app.router.go()
    },
  }
}

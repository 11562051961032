import { pointType, receiveMethods, requestStatus } from '~/plugins/enums/checkout'
import { generateThankPageUrl } from '~/plugins/helpers/order'

function pickupPointTypesGenerator() {
  return [
    {
      id: pointType.TYPE_PICKUP_POINT,
      title: this.$t('checkout.pointType.1'),
    },
    {
      id: pointType.TYPE_POSTOMAT,
      title: this.$t('checkout.pointType.2'),
    },
    {
      id: pointType.TYPE_RU_POST_OFFICE,
      title: this.$t('checkout.pointType.3'),
    },
    {
      id: pointType.SERVICE_TERMINAL,
      title: this.$t('checkout.pointType.4'),
    },
  ]
}

export const state = () => ({
  checkoutData: {},
  checkoutType: null,
  checkoutStatus: requestStatus.SUCCESS,

  checkoutProductsErrors: {
    recipientName: '',
    recipientPhone: '',
    recipients: '',
    address: '',
    pickPoint: '',
    receiveMethods: '',
  },
  checkoutServicesErrors: {
    recipients: '',
    members: {},
  },
})

export const getters = {
  getAddresses: (state) => state.checkoutData?.addresses || [],
  recipients: (state) => state.checkoutData?.recipients || [],
  payments: (state) => state.checkoutData?.paymentMethods || [],
  confirmationTypes: (state) => state.checkoutData?.confirmationTypes || [],
  receiveMethods: (state) => state.checkoutData?.receiveMethods || [],
  addresses: (state) => state.checkoutData?.addresses || [],
  deliveryTypes: (state) => state.checkoutData?.deliveryTypes || [],
  pickupPoints: (state) => state.checkoutData?.pickupPoints || [],
  metroLines: (state) => state.checkoutData?.metroLines || [],
  metroStations: (state) => state.checkoutData?.metroStations || [],
  publicEvents: (state) => state.checkoutData?.publicEvents || [],

  pickupPointTypes: (state, getters) => (app) => {
    const pickupPointTypes = pickupPointTypesGenerator.call(app)
    return pickupPointTypes.filter((t) => getters.pickupPoints.some((p) => p.methodID === t.id))
  },

  currentRecipient: (state) => state.checkoutData?.input?.recipient || { id: null },
  currentPaymentId: (state) => state.checkoutData?.input?.paymentMethodID || 1,
  currentConfirmationId: (state) => state.checkoutData?.input?.confirmationTypeID || 1,
  currentReceiveMethodID: (state) => state.checkoutData?.input?.receiveMethodID || 1,
  currentDeliveryTypeId: (state) => state.checkoutData?.input?.deliveryType?.id || null,
  currentDeliveryType: (state) => state.checkoutData?.input?.deliveryType || {},
  currentPickupPoint: (state) => state.checkoutData?.input?.pickupPoint || null,
  selectedAddress: (state) => state.checkoutData?.input?.address || {},
  certificates: (state) => state.checkoutData?.input?.certificates || [],

  bonusSpent: (state) => state.checkoutData?.summary?.bonusSpent,
  maxBonus: (state) => state.checkoutData.maxBonus || 0,
  availableBonus: (state) => state.checkoutData.availableBonus,

  certificateDiscount: (state) => state.checkoutData?.summary?.certDiscount?.value || 0,
  maxCertificateDiscount: (state) => state.checkoutData?.maxCertificateDiscount,
  agreement: (state) => state.checkoutData?.input?.agreement || false,

  isDelivery: (_, getters) => {
    const selectedReceiveMethodID = getters.currentReceiveMethodID

    return selectedReceiveMethodID === receiveMethods.DELIVERY || selectedReceiveMethodID === receiveMethods.EXPRESS
  },

  getDeliveryPrice: (state) => state.checkoutData?.summary?.delivery,
  getTotalPrice: (state) => state.checkoutData.summary?.total,
  getTotalBonuses: (state) => state.checkoutData.summary?.bonusGet,
  getDiscounts: (state) => state.checkoutData.summary?.discounts || [],
  getCertificatePayment: (state) => state.checkoutData.summary?.certDiscount ?? {},
  getBonusPayment: (state) => state.checkoutData.summary?.bonusDiscount ?? {},
  getTotalDiscount: (state) =>
    state.checkoutData.summary?.discounts?.reduce((sum, item) => {
      return sum + item.value.value
    }, 0) || 0,
  checkoutPromocode: (state) => state.checkoutData?.input?.promoCode || null,
}

export const mutations = {
  SET_STATUS(state, payload) {
    state.checkoutStatus = payload
  },
  SET_DATA(state, payload = {}) {
    state.checkoutData = payload
  },
  SET_CHECKOUT_ADDRESS(state, payload) {
    state.checkoutData.addresses = payload
  },
  SET_TYPE(state, payload = null) {
    state.checkoutType = payload
  },
  SET_RECIPIENT(state, { index, recipient }) {
    const existRecipient = state.checkoutData.recipients[index]

    const newRecipient = {
      id: index + 1,
      ...recipient,
    }

    if (existRecipient) Object.assign(existRecipient, newRecipient)

    state.checkoutData.input.recipient = newRecipient
  },
  ADD_RECIPIENT(state, { index, recipient }) {
    const newRecipient = {
      id: index + 1,
      ...recipient,
    }

    state.checkoutData.recipients.push(newRecipient)
    state.checkoutData.input.recipient = newRecipient
  },
  SET_PAYMENT_METHOD(state, payload) {
    state.checkoutData.input.paymentMethodID = payload
  },
  SET_CONFIRMATION_TYPE(state, payload) {
    state.checkoutData.input.confirmationTypeID = payload
  },
  SET_AGREEMENT(state, payload) {
    state.checkoutData.input.agreement = payload
  },
  SET_RECEIVE_METHOD(state, payload) {
    state.checkoutData.input.receiveMethodID = payload
  },
  SET_DELIVERY_TYPE(state, payload) {
    state.checkoutData.input.deliveryType = payload
  },
  CHANGE_CHUNK_DATE(state, payload) {
    const chunkItem = state.checkoutData.input?.deliveryType?.items?.find((item) => {
      return item.id === payload.id
    })
    if (!chunkItem) return
    chunkItem.selectedDate = payload.selectedDate || null
    chunkItem.selectedTime = payload.selectedTime || null
  },
  SET_PICKUP_POINT(state, payload) {
    state.checkoutData.input.pickupPoint = payload
  },
  SET_CHECKOUT_PRODUCTS_ERRORS(state, { key, error }) {
    state.checkoutProductsErrors[key] = error
  },
  CLEAR_CHECKOUT_PRODUCTS_ERRORS(state) {
    Object.keys(state.checkoutProductsErrors).forEach((key) => {
      state.checkoutProductsErrors[key] = ''
    })
  },
  SET_CHECKOUT_SERVICES_ERRORS(state, { key, error }) {
    state.checkoutServicesErrors[key] = error
  },
  CLEAR_CHECKOUT_SERVICES_ERRORS(state) {
    Object.keys(state.checkoutServicesErrors).forEach((key) => {
      state.checkoutServicesErrors[key] = ''
    })
  },
}

export const actions = {
  updateCheckoutData({ commit }, data) {
    commit('SET_DATA', data)
  },
  async fetchCheckoutData({ commit, getters }, { code }) {
    const method = code === 'services' ? 'getCheckoutServicesData' : 'getCheckoutProductsData'

    await checkoutRequest.call(this, commit, { method })
    commit('SET_TYPE', code)
  },
  async applyBonus({ commit }, payload) {
    await checkoutRequest.call(this, commit, { method: 'bonus', payload })
  },
  async addCertificate({ commit }, payload) {
    await checkoutRequest.call(this, commit, { method: 'addCertificate', payload })
  },
  async addServiceCertificate({ commit }, payload) {
    await checkoutRequest.call(this, commit, { method: 'addServiceCertificate', payload })
  },
  async setAddress({ state, commit, getters }, { address, save2Lk = true }) {
    const data = {
      save2Lk,
      address,
      data: state.checkoutData,
    }

    commit('SET_CHECKOUT_PRODUCTS_ERRORS', {
      key: 'address',
      error: '',
    })

    await checkoutRequest.call(this, commit, { method: 'setAddress', payload: data })

    if (!save2Lk) {
      const addresses = getters.getAddresses.filter((item) => item.area_id)

      commit('SET_CHECKOUT_ADDRESS', addresses)
    }
  },
  async setReceiveMethod({ state, getters, commit }, payload) {
    const data = {
      method: payload,
      data: {
        recipients: getters.recipients,
        addresses: getters.addresses,
        input: state.checkoutData.input,
      },
    }

    commit('SET_RECEIVE_METHOD', payload.id)
    commit('CLEAR_CHECKOUT_PRODUCTS_ERRORS')

    await checkoutRequest.call(this, commit, { method: 'setReceiveMethod', payload: data })

    if (getters.isDelivery && !getters.currentDeliveryType?.id) {
      getters.deliveryTypes[0] && commit('SET_DELIVERY_TYPE', getters.deliveryTypes[0])
    }
  },
  async setPickupPoint({ commit, state, getters }, payload) {
    const data = {
      pickupPoint: payload,
      data: {
        recipients: getters.recipients,
        addresses: getters.addresses,
        input: state.checkoutData.input,
      },
    }

    commit('SET_PICKUP_POINT', payload)
    commit('SET_CHECKOUT_PRODUCTS_ERRORS', {
      key: 'pickPoint',
      error: '',
    })

    await checkoutRequest.call(this, commit, { method: 'setPickupPoint', payload: data })
  },
  async commitProductsData({ state, dispatch }) {
    const data = {
      recipients: state.checkoutData.recipients,
      addresses: state.checkoutData.addresses,
      input: state.checkoutData.input,
    }

    if (await dispatch('validateProductsCheckout')) {
      await checkoutConfirm.call(this, dispatch, { method: 'commitCheckoutProductsData', payload: data })
    }
  },
  async commitServicesData({ state, dispatch }) {
    if (await dispatch('validateServicesCheckout')) {
      await checkoutConfirm.call(this, dispatch, { method: 'commitCheckoutServicesData', payload: state.checkoutData })
    }
  },
  async updateServiceTicket({ state, commit, getters }, { offerId, ticket }) {
    commit('CLEAR_CHECKOUT_SERVICES_ERRORS')

    const event = getters.publicEvents.find((e) => e.offerId === offerId)

    if (event) {
      const updatedTickets = event.tickets.map((item) => {
        if (item.id !== ticket.id) return item

        return ticket
      })

      const data = {
        data: state.checkoutData,
        publicEvent: {
          offerId,
          tickets: updatedTickets,
        },
      }

      await checkoutRequest.call(this, commit, { method: 'updateServiceTicket', payload: data })
    }
  },
  async addServiceTicket({ state, commit, getters }, { offerId, ticket }) {
    commit('CLEAR_CHECKOUT_SERVICES_ERRORS')

    const event = getters.publicEvents.find((e) => e.offerId === offerId)

    if (event) {
      const data = {
        data: state.checkoutData,
        publicEvent: {
          offerId,
          tickets: [ ...event.tickets, ticket ],
        },
      }

      await checkoutRequest.call(this, commit, { method: 'updateServiceTicket', payload: data })
    }
  },

  setRecipient({ commit, dispatch }, payload) {
    commit('SET_RECIPIENT', payload)
    dispatch('recipientValidate')
  },

  recipientValidate({ getters, commit }) {
    let isValid = true

    commit('SET_CHECKOUT_PRODUCTS_ERRORS', { key: 'recipients', error: '' })
    commit('SET_CHECKOUT_PRODUCTS_ERRORS', { key: 'recipientName', error: '' })
    commit('SET_CHECKOUT_PRODUCTS_ERRORS', { key: 'recipientPhone', error: '' })

    if (!getters.recipients.length || !getters.currentRecipient?.last_name || !getters.currentRecipient?.first_name) {
      commit('SET_CHECKOUT_PRODUCTS_ERRORS', {
        key: 'recipients',
        error: this.$i18n.t('checkout.error.addRecipient'),
      })

      isValid = false
    }

    if (!getters.currentRecipient?.last_name) {
      commit('SET_CHECKOUT_PRODUCTS_ERRORS', {
        key: 'recipientName',
        error: this.$i18n.t('checkout.error.lastnameIsRequired'),
      })

      isValid = false
    }

    if (!getters.currentRecipient?.first_name) {
      commit('SET_CHECKOUT_PRODUCTS_ERRORS', {
        key: 'recipientName',
        error: this.$i18n.t('checkout.error.firstnameIsRequired'),
      })

      isValid = false
    }

    if (!getters.currentRecipient?.phone?.length) {
      commit('SET_CHECKOUT_PRODUCTS_ERRORS', {
        key: 'recipientPhone',
        error: this.$i18n.t('checkout.error.phoneIsRequired'),
      })

      isValid = false
    }

    return isValid
  },

  validateProductsCheckout({ getters, commit, dispatch }) {
    let isValid = true
    commit('CLEAR_CHECKOUT_PRODUCTS_ERRORS')

    isValid = dispatch('recipientValidate')

    if (!getters.receiveMethods.length) {
      commit('SET_CHECKOUT_PRODUCTS_ERRORS', {
        key: 'receiveMethods',
        error: this.$i18n.t('checkout.error.deliveryNotAvailable'),
      })

      isValid = false
    }

    if (getters.isDelivery) {
      const curAddress = getters.selectedAddress
      if (!getters.addresses.length || !curAddress.area_id) {
        commit('SET_CHECKOUT_PRODUCTS_ERRORS', {
          key: 'address',
          error: this.$i18n.t('checkout.error.selectAddress'),
        })

        isValid = false
      }
    } else if (!getters.currentPickupPoint) {
      commit('SET_CHECKOUT_PRODUCTS_ERRORS', {
        key: 'pickPoint',
        error: this.$i18n.t('checkout.error.pickPointIsRequired'),
      })

      isValid = false
    }

    return isValid
  },
  validateServicesCheckout({ getters, commit }) {
    let isValid = true

    commit('CLEAR_CHECKOUT_PRODUCTS_ERRORS')
    commit('CLEAR_CHECKOUT_SERVICES_ERRORS')

    if (!getters.recipients.length) {
      commit('SET_CHECKOUT_PRODUCTS_ERRORS', {
        key: 'recipients',
        error: this.$i18n.t('checkout.error.addRecipient'),
      })

      isValid = false
    }

    const memberError = {}

    getters.publicEvents.forEach((item) => {
      const { count, id } = item.cartItem

      if (item.tickets.length < count) {
        memberError[id] = this.$i18n.t('checkout.error.participantInfoIsRequired')
        isValid = false
      }
    })

    commit('SET_CHECKOUT_SERVICES_ERRORS', {
      key: 'members',
      error: memberError,
    })

    return isValid
  },
}

async function checkoutConfirm(dispatch, { method, payload }) {
  try {
    const { orderId, paymentUrl } = await this.$api.checkout[method](payload)

    if (orderId && paymentUrl) {
      const backUrl = generateThankPageUrl(orderId)

      dispatch('cart/clearCart', null, { root: true })

      window.history.replaceState(null, '', backUrl)
      document.location.href = paymentUrl
    }
  } catch (error) {
    console.log(error)
  }
}

async function checkoutRequest(commit, { method, payload = null }) {
  try {
    commit('SET_STATUS', requestStatus.PENDING)
    const data = await this.$api.checkout[method](payload)

    commit('SET_DATA', data)
  } catch (error) {
    commit('SET_STATUS', requestStatus.ERROR)
    console.log(error)
  } finally {
    commit('SET_STATUS', requestStatus.SUCCESS)
  }
}

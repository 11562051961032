export default function(to, from, savedPosition) {
        if (to.matched.some((m) => m.meta.scrollTop)) {
          return { y: 0 };
        }

        if (to.name === from.name && to.matched.some((m) => m.meta.samePagePosition)) {
          return {
            y: window.scrollY
          };
        }
      }

<template>
  <div class="sign-up">
    <div class="container--modal-control sign-up__top-control">
      <div class="sign-up__wrapper">
        <div class="sign-up__top-menu font font_m font_bold font_uppercase">
          {{ $t('auth.enterSmsCode') }}
        </div>
        <button class="close-button" type="button" @click="toStart">
          <svg-icon height="14" name="arrow-left" width="16" />
        </button>
      </div>
    </div>
    <div class="container--modal sign-up__body">
      <h2 class="sign-up__title"></h2>
      <p class="sign-up__text font font_m">
        {{ $t('auth.sentToNumber', { phone: phoneFormat(acceptedPhone) }) }}
      </p>
      <form autocomplete="off" class="sign-up__form" @submit.prevent="onSubmitCode">
        <div class="sign-up__form-control">
          <InputMask
            key="sms-code"
            v-model="code"
            v-focus
            :error="codeError"
            :options="codeMaskOptions"
            data-test="fill-code"
            type="password"
          />
        </div>

        <Alert v-show="showErrorAlert" class="merge-carts-alert" type="error" @close="closeErrorAlert()">
          <template #content>
            <p class="font font_m">
              {{ $t('shared.operationError') }}
            </p>
          </template>
        </Alert>

        <div class="sign-up__recall-time font font_m font_grey">
          <span v-if="timerVisible">
            {{ $t('shared.code.sendAgain') }}
            <Timer ref="timerCode" :format="'s'" :seconds="timeCodeSMS" @timerEnd="timerEnd()" />
            {{ $t('shared.sec') }}
          </span>
          <button
            v-else
            :disabled="isDisabledSubmitButton"
            class="sign-up__recall-button font font_m font_uppercase font_grey"
            type="button"
            @click.stop="goToSendingSms"
          >
            {{ $t('shared.code.sendNew') }}
          </button>
        </div>

        <div class="sign-up__form-action">
          <Button :disabled="isDisabledNextButton" data-test="confirmation" full size="xl" type="submit">
            {{ $t('shared.next') }}
          </Button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { minLength, required } from 'vuelidate/lib/validators';
import { mapActions, mapMutations, mapState } from 'vuex';
import Alert from '@/components/elements/Alert';
import Button from '@/components/elements/Button';
import InputMask from '@/components/elements/InputMask';
import Timer from '@/components/elements/Timer';
import { TIME_CODE_SMS } from '@/plugins/config';
import { authModalPages } from '@/plugins/enums/auth';
import { mergeOptions } from '@/plugins/enums/cart';
import { phoneFormat } from '@/plugins/formatting';
import { codeMaskOptions } from '@/plugins/settings';

export default {
  name: 'AuthModalSignUpCode',
  components: {
    InputMask,
    Alert,
    Timer,
    Button,
  },
  data() {
    return {
      code: null,
      failCode: false,
      codeMaskOptions: { ...codeMaskOptions },
      isDisabledSubmitButton: false,
      timeCodeSMS: TIME_CODE_SMS,
      showErrorAlert: false,
      timerVisible: true,
    };
  },
  computed: {
    // вернуть если нужно дать пользователю выбор города
    // ...mapState('geolocation', ['isConfirmedCity']),
    ...mapState('auth', ['acceptedPhone']),
    codeError() {
      const code = this.$v.code;
      let text = '';
      if (code.$dirty) {
        if (!code.required) text = this.$t('shared.errors.field.required');
        if (!code.minLength) text = this.$t('shared.errors.codeInput.invalid');
      }
      const fail = this.$v.failCode;
      if (fail.$dirty && !fail.valid) text = this.$t('shared.errors.code.invalid');
      return text;
    },
    isDisabledNextButton() {
      return this.isDisabledSubmitButton || this.$v.code.$dirty;
    },
  },
  watch: {
    code() {
      if (this.$v.code.$dirty) this.$v.code.$reset();
      if (this.$v.failCode.$dirty) this.$v.failCode.$reset();
    },
  },
  validations: {
    code: {
      required,
      minLength: minLength(4),
    },
    failCode: {
      valid: (value) => value !== true,
    },
  },
  mounted() {
    this.timerStart();
  },
  beforeDestroy() {
    this.timerStop();
  },
  methods: {
    ...mapMutations('auth', {
      setCurrentAuthModalPage: 'SET_CURRENT_AUTH_MODAL_PAGE',
    }),
    ...mapActions('auth', ['loginByPhoneCheckCode', 'loginByPhoneSendSms', 'fetchUser']),
    ...mapActions('cart', ['getCartItems', 'setMergedCart']),
    ...mapActions('profile', ['loadProfile']),
    phoneFormat,
    async onSubmitCode() {
      // Подтверждение кода
      this.$v.code.$touch();
      if (!this.$v.code.$invalid) {
        try {
          this.isDisabledSubmitButton = true;

          const data = {
            code: this.code,
            phone: this.acceptedPhone,
          };

          await this.loginByPhoneCheckCode(data);
          this.failCode = false;
        } catch (error) {
          this.failCode = true;
          this.isDisabledSubmitButton = false;
        }

        this.$v.failCode.$touch();

        if (this.failCode) return;

        try {
          const isDifferentCarts = await this.$api.cart.checkCartsDifference();

          if (isDifferentCarts) {
            // вернуть если нужно дать пользователю выбор
            // this.viewMergeCartsModal = true;
            await this.$api.cart.sendMergeCartsOption(mergeOptions.BOTH_CARTS);
            this.setMergedCart(true);
          }

          this.getCartItems();
          this.loadProfile();
          await this.fetchUser();
          this.closeModal();
          // вернуть если нужно дать пользователю выбор города
          // if (this.isConfirmedCity) {
          //   this.closeModal();
          // } else {
          //   this.setCurrentAuthModalPage(authModalPages.SELECT_LOCATION);
          // }
        } catch (error) {
          this.showErrorAlert = true;
        }

        this.isDisabledSubmitButton = false;
      }
    },
    async goToSendingSms() {
      try {
        this.isDisabledSubmitButton = true;
        await this.loginByPhoneSendSms({
          phone: this.acceptedPhone,
        });
        this.failCode = false;
        this.timerStart();
      } catch (error) {
        console.log(error);
        this.failCode = true;
        this.$v.failCode.$touch();
      } finally {
        this.isDisabledSubmitButton = false;
      }
    },
    toStart() {
      this.setCurrentAuthModalPage(authModalPages.INDEX);
    },
    closeModal() {
      this.$emit('closeModal');
    },
    timerStart() {
      this.timerVisible = true;
      this.$nextTick(() => {
        this.$refs.timerCode.start();
      });
    },
    timerStop() {
      if (this.$refs.timerCode) {
        this.$refs.timerCode.stop();
      }
      this.timerVisible = false;
    },
    timerEnd() {
      this.timerVisible = false;
    },
    closeErrorAlert() {
      this.showErrorAlert = false;
    },
  },
};
</script>

export const state = () => ({
  currencies: [],
  selectedCurrencyId: null,
})

export const getters = {
  currencies: state => state.currencies,
  selectedCurrency: state => state.currencies.find(el => el.id === state.selectedCurrencyId),
  availableCurrencies: state => state.currencies.filter(el => el.id !== state.selectedCurrencyId),
  getCurrencySymbol: id => state => {
    const currency = state.currencies.find(el => el.id === id)
    return currency.symbol || currency.abbreviation
  },
}

export const mutations = {
  SET_CURRENCIES(state, payload) {
    state.currencies = payload
  },
  SET_SELECTED_CURRENCY_ID(state, payload) {
    state.selectedCurrencyId = payload
  },
}

export const actions = {
  async getCurrencies({ commit }) {
    try {
      const currencies = await this.$api.currency.getCurrencies()
      commit('SET_CURRENCIES', currencies)
    } catch (error) {
      console.error(error)
    }
  },

  setSelectedCurrency({ state, commit }, currencyId) {
    commit('SET_SELECTED_CURRENCY_ID', currencyId)
  },
}

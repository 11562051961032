import _isDate from 'lodash/isDate';

export function dateFormat(value, options = { month: 'long', day: 'numeric' }, locale = 'ru') {
  if (Date.parse(value)) {
    const date = new Date(value);
    const formatter = new Intl.DateTimeFormat(locale, options);
    return formatter.format(date);
  }
  return '';
}

export function weightFormat(value, decimalPlaces = 1) {
  return ((value).toFixed(decimalPlaces) * 10 / 10).toString().split('.').join(',');
}

/**
 *
 * @param {String} value Дата в формате timestamp
 * @param {Boolean} dayShort Указывает формат даты. true в случае необходимости строк 'Вчераэ, 'Сегодня' и т.п.
 * @param {Boolean} monthShort Указывает формат месяца.
 * Для значения true месяц вернется в формате 'мар', 'июл' и т.п., а в случае false в формате 'марта', 'июля' и т.п.
 * @param {String} preposition Предлог (например, 'от'), который добавляется перед конечной строкой
 * @return {String}
 */
export function dateFormatShort(value, dayShort = false, monthShort = true, preposition = '') {
  if (Date.parse(value)) {
    const locale = this.$i18n.locale
    const date = new Date(value);
    const dateNumber = date.getDate();

    if (dayShort) {
      const diffDays = new Date().getDate() - dateNumber;
      const diffMonths = new Date().getMonth() - date.getMonth();
      const diffYears = new Date().getFullYear() - date.getFullYear();

      if (diffYears === 0 && diffDays === 0 && diffMonths === 0) {
        return this.$t('shared.today');
      } else if (diffYears === 0 && diffDays === 1) {
        return this.$t('shared.yesterday');
      }
    }

    const monthFormatter = new Intl.DateTimeFormat(locale, {
      month: 'long',
    });

    let month = getMonthNameInGenitiveCase.call(this, date);
    if (monthShort) month = monthFormatter.format(date).substr(0, 3);

    const dayFormatter = new Intl.DateTimeFormat(locale, {
      day: 'numeric',
    });
    const resultString = `${dayFormatter.format(date)} ${month}`;

    return preposition.length ? `${this.$t('shared.priceFrom')} ${resultString}` : resultString;
  }

  return '';
}

export function weekdayFormatShort(value) {
  if (Date.parse(value)) {
    const locale = this.$i18n.locale
    const date = new Date(value);
    const weekdayFormatter = new Intl.DateTimeFormat(locale, { weekday: 'short' });
    return weekdayFormatter.format(date);
  }
  return '';
}

/**
 *
 * @param {Number} value Число к которому прикреплятся склоненная фраза
 * @param {Array} wordVariants Массив склонений слова в определенном порядке. Пример: ['минута', 'минут', 'минуты']
 * @return {String}
 */
export function phraseDeclension(value, wordVariants, valueFormat, withValue = true ) {
  const check = value % 10;

  let selectedWord;

  if (check === 1) {
    selectedWord = wordVariants[0];
  } else if (check === 0 || check >= 5 || value >= 11 && value <= 14) {
    selectedWord = wordVariants[1];
  } else {
    selectedWord = wordVariants[2];
  }

  if (!withValue) {
    return selectedWord;
  }

  if (typeof valueFormat === 'function') {
    value = valueFormat(value);
  }

  return `${value} ${selectedWord}`;
}

export function phoneFormat(value) {
  if (value) {
    return value.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3-$4-$5');
  }
  return '';
}

export function dataURItoBlob(dataURI) {

  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ab], {type: mimeString});

  return blob;
}

export function formattedDateTime(date, time) {
  const locale = this.$i18n.locale
  const dateTime = new Date(date + 'T' + time);

  const formattedDate = dateFormat(
    dateTime,
    { month: 'long', day: '2-digit' },
    locale,
  );

  const weekDay = weekdayFormatShort.call(this, dateTime);

  const formattedTime = dateFormat(
    dateTime,
    { hour: 'numeric', minute: 'numeric', hourCycle: 'h23' },
    locale,
  );

  return `${formattedDate} (${weekDay}), ${formattedTime}`;
}

export function dateToString(date) {
  date = _isDate(date) ? date : new Date(date);

  const day = `0${date.getDate()}`.slice(-2);
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
}

function round(n, precision) {
  const prec = 10 ** precision;
  return Math.round(n * prec) / prec;
}

export function shortNumberFormat(n) {
  const abbrev = [
    this.$t('shared.abbrev.digit.thousand'),
    this.$t('shared.abbrev.digit.million'),
    this.$t('shared.abbrev.digit.billion'),
  ];
  let base = Math.floor(Math.log(Math.abs(n)) / Math.log(1000));
  const suffix = abbrev[Math.min(2, base - 1)];
  base = abbrev.indexOf(suffix) + 1;
  return suffix ? `${round(n / 1000 ** base, 2)} ${suffix}` : `${n}`;
}

function getMonthNameInGenitiveCase(date = new Date) {
  return Array(12)
    .fill()
    .map((_, i) => this.$t(`shared.monthNameInGenitiveCase.${i}`))[date.getMonth()];
}

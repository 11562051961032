<template>
  <ul class="header-user header__buttons font">
    <template v-if="hasSession">
      <client-only>
        <li class="header-user__item header-user__account">
          <nuxt-link :to="localePath({ name: 'profile' })" class="header-user__client font_m">
            <span class="header-user__client-initials">
              {{ getAvatarPlaceholder }}
            </span>
          </nuxt-link>

          <HeaderMainActionsAccountMenu />
        </li>

        <li class="header-user__item header-user__favorite">
          <nuxt-link
            :aria-label="$t('shared.breadcrumbs.favorites')"
            :to="localePath({ name: 'favorites' })"
            class="header-user__favorite-button"
          >
            <div v-if="getFavoritesCount" class="header-user__favorite-counter">
              {{ getFavoritesCount }}
            </div>
            <svg-icon
              v-if="getFavoritesCount"
              class="header-user__favorite-ico"
              height="23"
              name="favorite-fill"
              width="24"
            />
            <svg-icon v-else class="header-user__favorite-ico" height="23" name="favorite" width="24" />
          </nuxt-link>
        </li>
      </client-only>
    </template>

    <li v-else class="header-user__item header-user__login">
      <button :aria-label="$t('shared.login')" class="header-user__button" data-test="log-in" @click="openAuthModal">
        <svg-icon height="24" name="account-middle" width="24" />
      </button>
    </li>

    <li class="header-user__item header-user__search">
      <button :aria-label="$t('header.search')" class="header-user__button" data-test="show-search" @click="showSearch">
        <svg-icon height="21" name="search" width="21" />
      </button>
    </li>
    <li class="header-user__item header-user__cart">
      <nuxt-link :aria-label="$t('header.cart')" :to="localePath({ name: 'cart' })" class="header-user__button">
        <span class="header-user__cart-count">
          {{ getCartAllItemsCount.all }}
        </span>
        <svg-icon height="24" name="cart" width="27" />
      </nuxt-link>
    </li>
  </ul>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import HeaderMainActionsAccountMenu from '~/layouts/components/Header/Main/Actions/AccountMenu';

export default {
  name: 'HeaderMainActions',
  components: { HeaderMainActionsAccountMenu },
  data() {
    return {
      isPendingLogout: false,
    };
  },
  computed: {
    ...mapState('auth', ['hasSession']),
    ...mapState({ isOpenedSearch: 'isOpenedSearch' }),

    ...mapGetters({
      getCartAllItemsCount: 'cart/getCartAllItemsCount',
      getFavoritesCount: 'favorites/getFavoritesCount',
      getAvatarPlaceholder: 'auth/getAvatarPlaceholder',
    }),
  },
  methods: {
    ...mapActions({
      setIsOpenedSearch: 'setIsOpenedSearch',
    }),
    ...mapMutations('auth', {
      setIsOpenedAuthModal: 'SET_IS_OPENED_AUTH_MODAL',
    }),
    openAuthModal() {
      this.setIsOpenedAuthModal(true);
    },
    showSearch() {
      this.setIsOpenedSearch(true);
    },
    hideSearch() {
      this.setIsOpenedSearch(false);
    },
  },
};
</script>

<style>
.header-user__cart-count {
  position: absolute;
  top: 0;
  left: 17px;
  transform: translateX(-50%);
  min-width: 15px;
  height: 15px;
  padding: 0 3px;
  border-radius: 30px;
  background: var(--color-basic);
  color: var(--color-white);
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
}

.header-user__cart .header-user__button {
  display: block;
  width: 28px;
  height: 24px;
  position: relative;
}

.header__buttons {
  flex: 1;
}

.header-user {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  margin: 0;
}

.header-user__item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-left: 30px;
}

.header-user__item:last-child {
  padding-right: 0;
}

.header-user__favorite,
.header-user__login {
  display: none;
}

.header-user__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: transparent;
}

.header-user__account {
  display: none;
}

.header-user__cart svg,
.header-user__search svg {
  fill: var(--color-white);
}

@media all and (min-width: 1024px) {
  .header-user__search {
    display: none;
  }

  .header-user__cart-count {
    background: var(--color-basic-black);
  }

  .header-user__favorite,
  .header-user__login {
    display: block;
  }

  .header-user__favorite-button {
    display: block;
    position: relative;
  }

  .header-user__favorite-counter {
    font-weight: 500;
    font-size: 9px;
    line-height: 9px;
    color: var(--color-white);

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-51%, -60%);
  }

  .header-user__favorite-ico {
    display: block;
    fill: var(--color-basic-black);
  }

  .header-user__account {
    display: block;
    position: relative;
  }

  .header-user__client {
    display: flex;
    align-items: center;
  }

  .header-user__client::after {
    content: '';
    flex-shrink: 0;
    margin-left: 4px;
    width: 10px;
    height: 7px;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.825 0.158203L5 3.97487L1.175 0.158203L0 1.3332L5 6.3332L10 1.3332L8.825 0.158203Z' fill='%23828187'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    transition: transform 0.3s;
  }

  .header-user__account:hover .header-user__client::after,
  .header-user__account:focus-within .header-user__client::after {
    transform: rotate(180deg);
  }

  .header-user__client-initials {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border: 2px solid var(--color-basic-black);
    border-radius: 50px;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-basic-black);
    transition: background 0.3s, color 0.3s;
  }

  .header-user__account:hover .header-user__client-initials,
  .header-user__account:focus-within .header-user__client-initials {
    background: var(--color-basic-black);
    color: var(--color-white);
  }

  .header-user__account:hover .header-user__detail-wrapper {
    visibility: visible;
    opacity: 1;
  }

  .header-user__cart svg {
    fill: var(--color-basic-black);
  }
}
</style>

<template>
  <transition name="slide">
    <div
      v-if="popUpState"
      class="popup"
      v-click-outside="closePopupHandler"
    >
      <slot />
    </div>
  </transition>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'CustomPopup',
  directives: {
    ClickOutside,
  },
  props: {
    popUpState: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    closePopupHandler(e) {
      this.$emit('closePopUp', e)
    },
  },
}
</script>

<style lang="scss" scoped>
.slide-enter-active, .slide-leave-active{
  transition: opacity .2s, top .3s;
}
.slide-enter, .slide-leave-to {
  opacity: 0;
  top: 8.35rem;
}
</style>

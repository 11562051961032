export const orderStatus = Object.freeze({
  /* предзаказ: ожидаем поступления товара */
  PRE_ORDER: 0,

  /* оформлен */
  CREATED: 1,

  /* ожидает проверки АОЗ */
  AWAITING_CHECK: 2,

  /* проверка АОЗ */
  CHECKING: 3,

  /* Ожидает подтверждения мерчантом */
  AWAITING_CONFIRMATION: 4,

  /* в обработке */
  IN_PROCESSING: 5,

  /* передан на доставку */
  TRANSFERRED_TO_DELIVERY: 6,

  /* в процессе доставки */
  DELIVERING: 7,

  /* находится в Пункте Выдачи */
  READY_FOR_RECIPIENT: 8,

  /* доставлен */
  DONE: 9,

  /* возвращен */
  RETURNED: 10,

  /* отменен */
  CANCELED: 1000,
});

export const orderPaymentStatus = Object.freeze({
  /* Не оплачен */
  NOT_PAID: 1,

  /* Оплачен */
  PAID: 2,

  /* Просрочен */
  TIMEOUT: 3,

  /* Средства захолдированы */
  HOLD: 4,

  /* Ошибка */
  ERROR: 5,

  /* Ожидает оплаты */
  WAITING: 6,

  /* Оплата возвращена */
  RETURNED: 7,
});

export const sortFields = Object.freeze({
  NUMBER: 'number',
  CREATED_AT: 'created_at',
});

export const deliveryStatus = Object.freeze({
  // внутренние статусы [1, 20]
  /** создан */
  STATUS_CREATED: 1,
  /** в обработке */
  STATUS_ASSEMBLING: 5,
  /** в обработке */
  STATUS_ASSEMBLED: 6,
  /** Передана в службу доставки */
  STATUS_SHIPPED: 7,

  // статусы доставки в случае "нормального" процесса доставки [21, 40]
  /** принят на склад в пункте отправления */
  STATUS_ON_POINT_IN: 21,
  /** прибыла в город назначения */
  STATUS_ARRIVED_AT_DESTINATION_CITY: 22,
  /** прибыл на склад в пункте назначения */
  STATUS_ON_POINT_OUT: 23,
  /** готов к выдаче в пункте назначения */
  STATUS_READY_FOR_RECIPIENT: 24,
  /** передана на доставку в пункте назначения */
  STATUS_DELIVERING: 25,
  /** доставлен получателю */
  STATUS_DONE: 26,

  // статусы по возвратам [41, 60]
  /** Ожидается отмена */
  STATUS_CANCELLATION_EXPECTED: 41,
  /** ожидается возврат от клиента */
  STATUS_RETURN_EXPECTED_FROM_CUSTOMER: 42,
  /** Возвращена */
  STATUS_RETURNED: 43,

  /** отменена */
  STATUS_CANCEL: 1000,
});

export const filterField = Object.freeze({
  ALL_TIME: 'all_time',
  YEAR: 'year',
  MONTH: 'month',
  DAY: 'day',
});

export const cancelReasons = Object.freeze({
  // Нарушен срок доставки
  DELIVERY_VIOLATED: 1,

  // Передумал
  CHANGE_MIND: 2,

  // Нашел дешевле
  CHEAPER: 3,

  // Нашел доставку быстрее
  FASTER: 4,

  // Другое
  ANOTHER: 5,
});

export const paymentMethods = Object.freeze({
  google_pay: 'Google Pay',

  apple_pay: 'Apple Pay',

  visa: 'VISA',

  master_card: 'MasterCard',
});

export const orderType = Object.freeze({
  CERTIFICATE: 'certificate',
  MASTERCLASS: 'masterclass',
  PRODUCT: 'product',
});

export function orderTypeNameGenerator() {
  return Object.freeze({
    [orderType.CERTIFICATE]: this.$t('profile.certificate.title'),
    [orderType.MASTERCLASS]: this.$t('shared.masterClass'),
    [orderType.PRODUCT]: this.$t('profile.products'),
  });
}

export const businessTypeId = Object.freeze({
  ACQUISITION: 1,
  ONGOING: 2,
  PROMO1: 3,
  PROMO2: 4,
  PROMO3: 5,
});

export function businessTypeNameGenerator() {
  return Object.freeze({
    [businessTypeId.ACQUISITION]: this.$t('shared.businessTypeName.1'),
    [businessTypeId.ONGOING]: this.$t('shared.businessTypeName.2'),
    [businessTypeId.PROMO1]: this.$t('shared.businessTypeName.3'),
    [businessTypeId.PROMO2]: this.$t('shared.businessTypeName.4'),
    [businessTypeId.PROMO3]: this.$t('shared.businessTypeName.5'),
  });
}

<template>
  <ul class="header-top__info">
    <li v-for="(link, idx) in links" :key="`info${idx}`" class="header-top__info-item">
      <nuxt-link
        :class="{
          [`header-top__link--ico-${link.icon}`]: true,
        }"
        :to="localePath(link.to)"
        class="header-top__link header-top__link--ico font font_m"
      >
        {{ link.name }}
      </nuxt-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'HeaderTopLinks',
  props: {
    links: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.header-top__info {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 28px;
}
</style>

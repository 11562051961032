<template>
  <nuxt-link
    :style="logoImage.style"
    :to="localePath({ name: 'main' })"
    class="logo-wrapper"
    itemscope
    itemtype="https://schema.org/Organization"
  >
    <div class="logo-wrapper__body">
      <div class="logo">
        <div class="logo__img">
          <img :src="logoImage.src" itemprop="logo" />
        </div>
      </div>
      <div v-if="isText && logoText" class="logo__text font font_xs font_grey font_center">
        {{ logoText }}
      </div>
    </div>
  </nuxt-link>
</template>

<script>
import { mapState } from 'vuex';
import { generateFileOriginalPath } from '~/plugins/helpers/file';

export default {
  name: 'Logo',
  props: {
    customLogo: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    isText: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({ logo: 'logo' }),
    isMainPage() {
      return this.$route.name.split('___')[0] === 'main';
    },
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet';
    },
    logoPC() {
      return this.logo?.pc;
    },
    logoMobile() {
      return this.logo?.mobile;
    },
    currentLogo() {
      return (this.mqIsMobile || this.small) && this.logoMobile ? this.logoMobile : this.logoPC;
    },
    logoText() {
      return this.currentLogo?.text;
    },
    logoImage() {
      return {
        src: this.customLogo || this.generateFileOriginalPath(this.currentLogo?.id),
      };
    },
  },
  methods: {
    generateFileOriginalPath,
  },
};
</script>

<style lang="scss" scoped>
.logo {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  width: 100%;

  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 50%;
    height: 100%;

    @include bp('min', 1024px) {
      height: 5.6rem;
    }

    @include bp('max', 1024px) {
      position: absolute;
      left: 50%;
      width: 38.1%;
      transform: translateX(-50%);
    }

    &__body {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    display: none;

    @include bp('min', $md2) {
      display: block;
    }
  }

  &__img {
    position: relative;
    width: 100%;

    @include bp('min', 1024px) {
      height: 100%;
    }

    @include bp('max', 1024px) {
      padding-top: 2.7rem;
    }

    img {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>

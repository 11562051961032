<template>
  <div v-show="!isCookiesAccepted" class="cookies-wrapper">
    <div class="cookies">
      <div class="cookies__body">
        <div class="font">
          {{ cookieText }} {{ $t('elements.cookiesAccept.agree') }}
          <a class="cookies__link" :href="localePath('/privacy_policy')" rel="noreferrer noopener" target="_blank">
            {{ $t('elements.cookiesAccept.privacyPolicy') }}
          </a>
        </div>
        <Button class="cookies__accept" theme="link" @click="acceptCookiesHandler">
          <svg-icon height="14" name="close" width="16" />
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '~/components/elements/Button';
import { MAX_AGE_COOKIE_AGREE } from '~/plugins/config';
import { cookieNames } from '~/plugins/enums/cookie';

export default {
  components: { Button },
  data() {
    return {
      isCookiesAccepted: 1,
    };
  },
  computed: {
    mqIsMobile() {
      return this.$mq === 'mobile' || this.$mq === 'mobileXs';
    },
    cookieText() {
      return this.$t(`elements.cookiesAccept.cookies.${this.mqIsMobile ? 'mobile' : 'desktop'}`);
    },
  },
  mounted() {
    this.isCookiesAccepted = this.getCookieAgree() || 0;
    this.setCookieAgree(this.isCookiesAccepted);
  },
  methods: {
    setCookieAgree(agree) {
      this.$cookies.set(cookieNames.AGREE_COOKIE, JSON.stringify(agree), {
        maxAge: MAX_AGE_COOKIE_AGREE,
        path: '/',
      });
    },
    getCookieAgree() {
      return +this.$cookies.get(cookieNames.AGREE_COOKIE);
    },
    acceptCookiesHandler(event) {
      if (!event.target?.dataset?.more) {
        this.isCookiesAccepted = 1;
        this.setCookieAgree(this.isCookiesAccepted);
      }
    },
  },
};
</script>

<style scoped></style>

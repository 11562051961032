<template>
  <div class="layout">
    <Header :is-show-hamburger-button="true" />
    <SidebarWrapper />
    <div class="container layout__content">
      <main class="layout__main layout__main--hidden-sidebar">
        <Nuxt />
      </main>
    </div>
    <Footer />
    <LocationModal v-if="visibleLocationModal" />
    <CookiesAccept />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Header from './components/Header';
import Footer from './components/Footer';
import SidebarWrapper from './components/Sidebar/Wrapper';
import LocationModal from '@/layouts/components/LocationModal';
import login from '~/mixins/login';
import CookiesAccept from '~/components/elements/CookiesAccept.vue';

export default {
  name: 'HiddenSidebarLayout',
  components: {CookiesAccept, Header, Footer, SidebarWrapper, LocationModal },
  mixins: [login],
  computed: {
    ...mapState({ isOpenedSidebar: 'isOpenedSidebar' }),
    ...mapState('geolocation', ['visibleLocationModal']),
  },
};
</script>

<style scoped>
.layout__content {
  min-height: 70vh;
}

.layout__main {
  padding-top: 1.6rem;
  flex-shrink: 0;
}

@media all and (min-width: 1280px) {
  .layout__content {
    padding: 0 var(--container-padding-desktop);
  }

  .layout__main {
    padding-top: 1.6rem;
    flex-grow: 1;
    flex-shrink: 1;
  }
}
</style>

<template>
  <div class="container header-main">
    <button v-if="isShowCategories" class="header-main__back" type="button" @click="isShowCategories = false">
      {{ $t('shared.breadcrumbs.catalog') }}
    </button>
    <MenuToggle v-else v-model="isOpenedMobileMenu" class="header-main__menu-toggle">
      {{ $t('header.menu') }}
    </MenuToggle>

    <Search :is-scroll-offset="isScrollOffset" class="header__search" />

    <Logo class="header__logo" :is-text="true" />

    <HeaderMainActions />

    <AuthModal v-show="isOpenedAuthModal" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MenuToggle from '~/layouts/components/Header/MenuToggle.vue';
import Logo from '~/components/elements/Logo.vue';
import AuthModal from '~/components/auth/Modal/index.vue';
import HeaderMainActions from '~/layouts/components/Header/Main/Actions/index.vue';
import Search from '~/layouts/components/Search';

export default {
  name: 'HeaderMain',
  components: { Search, HeaderMainActions, AuthModal, Logo, MenuToggle },
  props: {
    value: { type: Boolean, required: true },
    openedMobileMenu: { type: Boolean, required: true },
    isScrollOffset: { type: Boolean, required: true },
  },
  computed: {
    ...mapState('auth', ['isOpenedAuthModal']),
    isShowCategories: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    isOpenedMobileMenu: {
      get() {
        return this.openedMobileMenu;
      },
      set(value) {
        this.$emit('changeMobileMenu', value);
      },
    },
  },
};
</script>

<style scoped>
.header-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 16px;
  height: var(--height-nav-mobile);
  background: var(--color-basic);
}

.header-main__menu-toggle {
  flex: 1;
}

.header-main__back {
  display: flex;
  align-items: center;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: var(--color-white);
  flex: 1;
}

.header-main__back::before {
  content: '';
  display: block;
  width: 17px;
  height: 14px;
  margin-right: 8px;
  background-image: url("data:image/svg+xml,%3Csvg width='17' height='14' viewBox='0 0 17 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.0275002 7L0 7.04605V7.0275L0.0275002 7Z' fill='white'/%3E%3Cpath d='M0.0275002 7L0 6.9725V6.95395L0.0275002 7Z' fill='white'/%3E%3Cpath d='M3.86312 5.98438L8.4375 1.41L7.0275 0L0.0275002 7L7.0275 14L8.4375 12.59L3.86312 8.01562H17V5.98438H3.86312Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
}

@media all and (min-width: 1024px) {
  .header-main {
    min-height: var(--height-nav-mobile);
    margin-top: 16px;
    margin-bottom: 16px;
    background: var(--color-white);
  }

  .header-main__menu-toggle {
    display: none !important;
  }

  .header-main .header__search {
    position: relative;
    flex: 1;
    display: flex !important;
    align-items: center;
  }

  .menu__item--scroll > div {
    margin: 0 10px;
    position: relative;
    display: inline-block;
  }

  .menu__item--scroll svg {
    fill: var(--color-white);
  }

  .header-main__back {
    display: none;
  }
}
</style>

<template>
  <div class="layout-simple container" :class="{ 'container--checkout-page': $route.name === 'checkout' }">
    <header class="layout-simple__header">
      <Logo class="layout-simple__logo" small-icon="logo-iwengo-small-colored" />
    </header>
    <main class="layout-simple__container">
      <Nuxt />
    </main>
    <Footer />
    <CookiesAccept />
  </div>
</template>

<script>
import Footer from './components/Footer';
import Logo from '@/components/elements/Logo';
import login from '~/mixins/login';
import CookiesAccept from '~/components/elements/CookiesAccept.vue';

export default {
  name: 'SimpleLayout',
  components: {
    CookiesAccept,
    Footer,
    Logo,
  },
  mixins: [login],
};
</script>

<style>
html,
body,
#__nuxt,
#__layout {
  height: 100%;
}

.layout-simple {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.layout-simple__header {
  display: flex;
  justify-content: center;
  padding: 24px 8px 40px;
}

.container--checkout-page {
  padding: 0;
}

.layout-simple__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  margin-bottom: 56px;
}

@media all and (min-width: 1280px) {
  .container--checkout-page {
    padding-left: 40px;
    padding-right: 40px;
  }

  .layout-simple__header {
    padding: 34px 0 70px;
  }
}

@media all and (max-width: 1024px) {
  .layout-simple__container {
    margin-bottom: 32px;
  }
}
</style>

import { productGroupBase, productGroupTypes } from '@/plugins/enums/product';
import { sortDirections, sortOptions } from '@/plugins/enums/sort';
import { mapFilterSegments } from '@/plugins/helpers/catalog';

export const state = () => ({
  items: [],
  allCategories: [],
  frequentCategories: [],
  currentItemsFilters: {},
  currentCategory: {},
  filters: [],
  filtersStateMap: {},
  productGroup: {
    type: productGroupTypes.CATALOG,
    base: productGroupBase.FILTERS,
    excluded_filters: [],
    filters: {},
  },
  visibleFilter: false,
  categories: [],
  isVisibleBuyGiftcardModal: false,
});

export const getters = {
  formattedAllCategories: (state) => {
    function transform(array, resultObj = {}) {
      array.forEach((category) => {
        if (category?.items?.length) {
          transform(category.items, resultObj);
        }
        resultObj[category.code] = category;
      }, {});

      return resultObj;
    }

    return transform(state.allCategories);
  },
  routeSegments(state, getters, rootState) {
    const pathMatch = rootState.route.params.pathMatch;
    return pathMatch ? pathMatch.split('/').filter((s) => s !== '') : [];
  },
  filterSegments: (state, getters) => {
    const urlSegments = getters.routeSegments;
    return mapFilterSegments(urlSegments);
  },
  getSubCategories: (state) => (category) => {
    if (category) {
      let parent;

      function findCategories(data, code) {
        let found = data.find((item) => item.code === code);

        if (!found) {
          let i = 0;
          while (!found && i < data.length) {
            if (data[i].items && data[i].items.length) {
              parent = data[i];
              found = findCategories(data[i].items, code);
            }
            i++;
          }
        }
        return found;
      }

      const currentCategories = findCategories(state.allCategories, category);

      let result;
      if (currentCategories?.items) {
        result = currentCategories.items;
      } else if (parent) {
        result = parent.items;
      } else {
        result = state.frequentCategories;
      }
      return result;
    } else {
      return state.frequentCategories;
    }
  },
  activeTags: (state, getters) => {
    const activeTags = [];
    const { filters = [] } = state;
    const { filterSegments = {} } = getters;
    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i];
      if (Array.isArray(filter.items))
        for (let j = 0; j < filter.items.length; j++) {
          const item = filter.items[j];
          if (filterSegments[filter.name] && filterSegments[filter.name][item.code])
            activeTags.push({ ...item, segment: `${filter.name}-${item.code}` });
        }
      else if (filter.type === 'range') {
        if (filterSegments[filter.name] && filter.name === 'price') {
          const values = filterSegments[filter.name];
          activeTags.push({
            ...filter,
            values,
            translationArgs: ['catalog.priceFilterTag', { from: values[0], to: values[1] }],
            name: null,
            segment: `${filter.name}-from_${values[0]}_to_${values[1]}`,
          });
        }
      }
    }
    return activeTags;
  },
};

export const mutations = {
  SET_FREQUENT_CATEGORIES(state, payload) {
    state.frequentCategories = payload;
  },
  SET_ALL_CATEGORIES(state, payload) {
    state.allCategories = payload;
  },
  SET_CURRENT_CATEGORY(state, payload) {
    state.currentCategory = payload;
  },
  SET_CURRENT_ITEMS_FILTERS(state, payload) {
    state.currentItemsFilters = payload;
  },
  SET_FILTERS(state, payload) {
    state.filters = payload;
    state.filtersStateMap = {};
    for (const dataFilter of state.filters) {
      state.filtersStateMap[dataFilter.name] = state.filtersStateMap[dataFilter.name] || {
        isExpanded: true,
        showMore: false,
      };
    }
  },
  CHANGE_FILTER_STATE(state, { name, value }) {
    state.filtersStateMap = {
      ...state.filtersStateMap,
      [name]: value,
    };
  },
  SET_VISIBLE_FILTER(state, payload) {
    state.visibleFilter = payload;
  },
  SET_CATEGORIES(state, payload) {
    state.categories = payload;
  },
  SET_IS_VISIBLE_BUY_GIFTCARD_MODAL(state, payload) {
    state.isVisibleBuyGiftcardModal = payload;
  },
};

export const actions = {
  updateFilters({ commit }, params = {}) {
    const { page = 1, filter = [], orderField = sortOptions.POPULARITY, orderDirection = sortDirections.DESC } = params;

    const filters = {
      page,
      filter,
      orderField,
      orderDirection,
    };

    commit('SET_CURRENT_ITEMS_FILTERS', filters);
  },

  async getFrequentCategories({ commit }) {
    try {
      const categories = await this.$api.catalog.getFrequentCategories();
      commit('SET_FREQUENT_CATEGORIES', categories);
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  async getAllCategories({ commit }) {
    try {
      const categories = await this.$api.catalog.getAllCategories();
      commit('SET_ALL_CATEGORIES', categories);
    } catch (error) {
      console.error(error);
      return false;
    }
  },

  async getCategories({ commit }, { nodeCode, maxDepth, filter }) {
    try {
      const categories = await this.$api.catalog.getCategories(nodeCode, maxDepth, filter);
      commit('SET_CATEGORIES', categories);
    } catch (error) {
      console.error(error);
    }
  },

  async fetchFilters({ commit }, payload) {
    try {
      const data = await this.$api.catalog.fetchFilters(payload);
      commit('SET_FILTERS', data);
      return data;
    } catch (error) {
      console.error(error);
      return [];
    }
  },

  setIsVisibleBuyGiftcardModal({ commit }, payload) {
    commit('SET_IS_VISIBLE_BUY_GIFTCARD_MODAL', payload);
  },
};

import {httpCodes} from '~/plugins/enums/http';
import {promocodesFailNames} from '~/plugins/enums/failErrors';

export const promocodeDriver = Object.freeze({
  CART: 1,
  CHECKOUT: 2,
})

export const state = () => ({
  type: promocodeDriver.CHECKOUT,
  isPromocodePending: false,
  promocodeError: null,
})

export const getters = {
  isPromocodeDisabled: state => state.isPromocodePending,
  promocode: (state, getters, rootState, rootGetters) => {
    return state.type === promocodeDriver.CART
      ? rootGetters['cart/cartPromocode']
      : rootGetters['checkout/checkoutPromocode']
  },
}

export const mutations = {
  SET_PROMOCODE_TYPE(state, payload) {
    state.type = payload
  },
  SET_PROMOCODE_LOADING(state, payload) {
    state.isPromocodePending = payload
  },
  SET_PROMOCODE_ERROR(state, payload) {
    state.promocodeError = payload
  },
}

export const actions = {
  async addPromocode({ commit, state, dispatch, rootState }, { promoCode }) {
    try {
      commit('SET_PROMOCODE_LOADING', true)
      commit('SET_PROMOCODE_ERROR', null)

      let response
      const data = state.type === promocodeDriver.CART ? rootState.cart.cartData : rootState.checkout.checkoutData

      if (state.type === promocodeDriver.CART) {
        response = await this.$api.cart.addCartPromocode({ promoCode, data });
        await dispatch('cart/updateCartData', response, { root: true });

      } else {
        response = await this.$api.checkout.addCheckoutPromocode({ promoCode, data });
        await dispatch('checkout/updateCheckoutData', response, { root: true });
      }
    } catch (error) {
      const { status } = error.response;

      switch (status) {
        case httpCodes.BAD_REQUEST:
          commit('SET_PROMOCODE_ERROR', promocodesFailNames.INVALID)
          break;
        case httpCodes.NOT_FOUND:
          commit('SET_PROMOCODE_ERROR', promocodesFailNames.NOT_EXIST)
          break;
      }

      state.promocodeError &&  setTimeout(() => commit('SET_PROMOCODE_ERROR', null), 5000)
    } finally {
      commit('SET_PROMOCODE_LOADING', false)
    }
  },
  async deletePromocode({ commit, state, dispatch, rootState }) {
    try {
      commit('SET_PROMOCODE_LOADING', true)
      commit('SET_PROMOCODE_ERROR', null)

      let response
      const data = state.type === promocodeDriver.CART ? rootState.cart.cartData : rootState.checkout.checkoutData

      if (state.type === promocodeDriver.CART) {
        response = await this.$api.cart.deleteCartPromocode({  data });
        await dispatch('cart/updateCartData', response, { root: true });
      } else {
        response = await this.$api.checkout.deleteCheckoutPromocode({ data });
        await dispatch('checkout/updateCheckoutData', response, { root: true });
      }
    } catch (error) {
      console.error(error);
    } finally {
      commit('SET_PROMOCODE_LOADING', false)
    }
  },
}

<template>
  <transition name="slide">
    <div
      class="main-sidebar"
      :class="[
        {
          'main-sidebar--slider':
            mqIsMobile || (!mqIsMobile && !isAlwaysOnDesktop),
        },
        { 'main-sidebar--always-on-desktop': !mqIsMobile && isAlwaysOnDesktop },
        { 'main-sidebar--scroll-lock': mqIsMobile && visibleLocationModal },
      ]"
    >
      <div class="main-sidebar__wrapper">
        <div v-if="mqIsMobile" class="main-sidebar__header">
          <!--<Logo class="main-sidebar__logo" />-->
          <button
            class="close-button main-sidebar__close-button"
            type="button"
            @click="closeSidebar"
          >
            <span class="visually-hidden">{{ $t('shared.closeMenu') }}</span>
          </button>
        </div>

        <SidebarMenu :main-items="menuMain" :secondary-items="menuHelp" />

        <template v-if="!hasSession">
          <p class="main-sidebar__login-description">
            {{ $t('sidebar.description') }}
          </p>
          <div class="main-sidebar__login-buttons">
            <Button
              theme="tertiary"
              class="main-sidebar__login-button"
              @click="openAuthModal"
            >
              {{ $t('shared.login') }}
            </Button>
            <Button
              theme="secondary"
              only-icon
              :aria-label="$t('shared.loginWithFacebook')"
              class="main-sidebar__social-login-button"
              @click="onLoginBySocial('facebook')"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.2988 15.8787 19.6351 10.6667 19.9781V12H13.3333V10.6667H10.6667V8.66667C10.6667 7.5621 11.5621 6.66667 12.6667 6.66667H13.3333V5.33333H12.6667C10.8257 5.33333 9.33333 6.82572 9.33333 8.66667V10.6667H6.66667V12H9.33333V19.9781C4.12131 19.6351 0 15.2988 0 10Z"
                  fill="#12121D"
                />
              </svg>
            </Button>
            <Button
              theme="secondary"
              only-icon
              :aria-label="$t('shared.loginWithGoogle')"
              class="main-sidebar__social-login-button"
              @click="onLoginBySocial('google')"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 10C0 4.47715 4.47715 0 10 0C12.798 0 15.3286 1.15 17.1428 3.00142L16.1905 3.93462C14.6167 2.32858 12.4252 1.33333 10 1.33333C5.21353 1.33333 1.33333 5.21353 1.33333 10C1.33333 14.7865 5.21353 18.6667 10 18.6667C14.5622 18.6667 18.301 15.1416 18.6414 10.6667H10.6667V9.33333H20V10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
                  fill="#12121D"
                />
              </svg>
            </Button>
            <Button
              theme="secondary"
              only-icon
              :aria-label="$t('shared.loginWithVK')"
              class="main-sidebar__social-login-button"
              @click="onLoginBySocial('vkontakte')"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.4994 3.91609C10.2234 3.91609 9.99942 3.69679 9.99942 3.42658C9.99942 1.53413 11.5674 0 13.4994 0C13.7764 0 13.9994 0.219301 13.9994 0.489511C13.9994 2.38196 12.4324 3.91609 10.4994 3.91609Z"
                  fill="#12121D"
                />
                <path
                  d="M10.4994 3.91609C10.2234 3.91609 9.99942 3.69679 9.99942 3.42658C9.99942 1.53413 11.5674 0 13.4994 0C13.7764 0 13.9994 0.219301 13.9994 0.489511C13.9994 2.38196 12.4324 3.91609 10.4994 3.91609Z"
                  fill="#12121D"
                />
                <path
                  d="M17.7998 14.2238C17.9928 14.3657 18.0558 14.6232 17.9468 14.8347C16.1868 18.2828 14.7828 20 13.4998 20C12.9028 20 12.3159 19.8111 11.7439 19.4429C10.9109 18.9055 9.83589 18.8829 8.9799 19.3842C8.28691 19.7905 7.62692 20 6.99993 20C5.11296 20 2 14.3109 2 11.1888C2 7.85621 3.78497 5.31467 6.49994 5.31467C7.77692 5.31467 8.8799 5.49873 9.80589 5.87271C10.1989 6.03132 10.6429 6.01957 11.0259 5.83943C11.7779 5.486 12.7688 5.31467 13.9998 5.31467C15.5018 5.31467 16.8108 6.04698 17.8998 7.46852C18.0648 7.68488 18.0208 7.99132 17.7998 8.15384C16.5848 9.04573 15.9998 10.0482 15.9998 11.1888C15.9998 12.3294 16.5848 13.3319 17.7998 14.2238Z"
                  fill="#12121D"
                />
                <path
                  d="M17.7998 14.2238C17.9928 14.3657 18.0558 14.6232 17.9468 14.8347C16.1868 18.2828 14.7828 20 13.4998 20C12.9028 20 12.3159 19.8111 11.7439 19.4429C10.9109 18.9055 9.83589 18.8829 8.9799 19.3842C8.28691 19.7905 7.62692 20 6.99993 20C5.11296 20 2 14.3109 2 11.1888C2 7.85621 3.78497 5.31467 6.49994 5.31467C7.77692 5.31467 8.8799 5.49873 9.80589 5.87271C10.1989 6.03132 10.6429 6.01957 11.0259 5.83943C11.7779 5.486 12.7688 5.31467 13.9998 5.31467C15.5018 5.31467 16.8108 6.04698 17.8998 7.46852C18.0648 7.68488 18.0208 7.99132 17.7998 8.15384C16.5848 9.04573 15.9998 10.0482 15.9998 11.1888C15.9998 12.3294 16.5848 13.3319 17.7998 14.2238Z"
                  fill="#12121D"
                />
              </svg>
            </Button>
          </div>
        </template>

        <p class="main-sidebar-links">
          {{ $t('sidebar.links.1') }}
          <br />
          {{ $t('sidebar.links.2') }}
          <br />
          {{ $t('sidebar.links.3') }}
          <br />
          {{ $t('sidebar.links.4') }}
        </p>

        <div class="main-sidebar-copyright">
          <ul class="main-sidebar-copyright-app-list">
            <li class="main-sidebar-copyright-app-list__item">
              <a class="main-sidebar-copyright-app-list__link" href="#">
                <span class="visually-hidden">
                  {{ $t('shared.download.appStore') }}
                </span>
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.39962 3.13287C7.17883 3.13287 6.99963 2.95743 6.99963 2.74126C6.99963 1.2273 8.25401 0 9.79959 0C10.0212 0 10.1996 0.175441 10.1996 0.391609C10.1996 1.90557 8.946 3.13287 7.39962 3.13287Z"
                    fill="#1D1929"
                  />
                  <path
                    d="M7.39962 3.13287C7.17883 3.13287 6.99963 2.95743 6.99963 2.74126C6.99963 1.2273 8.25401 0 9.79959 0C10.0212 0 10.1996 0.175441 10.1996 0.391609C10.1996 1.90557 8.946 3.13287 7.39962 3.13287Z"
                    fill="#1D1929"
                  />
                  <path
                    d="M13.2399 11.379C13.3943 11.4926 13.4447 11.6986 13.3575 11.8677C11.9495 14.6262 10.8264 16 9.79997 16C9.32237 16 8.85278 15.8488 8.39519 15.5543C7.7288 15.1244 6.86881 15.1063 6.18402 15.5074C5.62963 15.8324 5.10163 16 4.60004 16C3.09046 16 0.600098 11.4487 0.600098 8.95104C0.600098 6.28497 2.02808 4.25174 4.20005 4.25174C5.22163 4.25174 6.10402 4.39898 6.84481 4.69817C7.1592 4.82505 7.5144 4.81565 7.8208 4.67154C8.42239 4.3888 9.21518 4.25174 10.2 4.25174C11.4015 4.25174 12.4487 4.83758 13.3199 5.97482C13.4519 6.14791 13.4167 6.39305 13.2399 6.52307C12.2679 7.23658 11.7999 8.03859 11.7999 8.95104C11.7999 9.86349 12.2679 10.6655 13.2399 11.379Z"
                    fill="#1D1929"
                  />
                  <path
                    d="M13.2399 11.379C13.3943 11.4926 13.4447 11.6986 13.3575 11.8677C11.9495 14.6262 10.8264 16 9.79997 16C9.32237 16 8.85278 15.8488 8.39519 15.5543C7.7288 15.1244 6.86881 15.1063 6.18402 15.5074C5.62963 15.8324 5.10163 16 4.60004 16C3.09046 16 0.600098 11.4487 0.600098 8.95104C0.600098 6.28497 2.02808 4.25174 4.20005 4.25174C5.22163 4.25174 6.10402 4.39898 6.84481 4.69817C7.1592 4.82505 7.5144 4.81565 7.8208 4.67154C8.42239 4.3888 9.21518 4.25174 10.2 4.25174C11.4015 4.25174 12.4487 4.83758 13.3199 5.97482C13.4519 6.14791 13.4167 6.39305 13.2399 6.52307C12.2679 7.23658 11.7999 8.03859 11.7999 8.95104C11.7999 9.86349 12.2679 10.6655 13.2399 11.379Z"
                    fill="#1D1929"
                  />
                </svg>
              </a>
            </li>
            <li class="main-sidebar-copyright-app-list__item">
              <a class="main-sidebar-copyright-app-list__link" href="#">
                <span class="visually-hidden">
                  {{ $t('shared.download.googlePlay') }}
                </span>
                <svg
                  width="14"
                  height="12"
                  viewBox="0 0 14 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.99998 2.26665C5.87406 2.26665 4.81082 2.53503 3.87075 3.01131L2.11042 0.370819L1.2229 0.962499L2.95843 3.56579C1.2072 4.82421 0.0666504 6.8789 0.0666504 9.19999V11.8667H13.9333V9.19999C13.9333 6.87891 12.7928 4.82422 11.0416 3.5658L12.7771 0.962499L11.8896 0.370819L10.1292 3.01132C9.18916 2.53504 8.12591 2.26665 6.99998 2.26665ZM4.33332 8.66665H3.26665V7.59999H4.33332V8.66665ZM9.66665 8.66665H10.7333V7.59999H9.66665V8.66665Z"
                    fill="#1D1929"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';
import SidebarMenu from './Menu';
import Button from '@/components/elements/Button';
// import Logo from '@/components/elements/Logo';

import './index.css';

export default {
  name: 'Sidebar',
  components: {
    SidebarMenu,
    Button,
    // Logo,
  },
  props: {
    isAlwaysOnDesktop: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('auth', ['hasSession']),
    ...mapState('geolocation', ['visibleLocationModal']),
    ...mapState({ isOpenedSidebar: 'isOpenedSidebar' }),
    ...mapGetters({
      menuMain: 'getMenuMain',
      menuHelp: 'getMenuHelp',
    }),
    mqIsMobile() {
      return (
        this.$mq === 'mobileXs' ||
        this.$mq === 'mobile' ||
        this.$mq === 'tablet' ||
        this.$mq === 'laptop'
      );
    },
  },
  watch: {
    '$route.path'() {
      this.closeSidebar();
    },
  },
  beforeDestroy() {
    this.closeSidebar();
  },
  methods: {
    ...mapActions({ setIsOpenedSidebar: 'setIsOpenedSidebar' }),
    ...mapMutations('auth', {
      setIsOpenedAuthModal: 'SET_IS_OPENED_AUTH_MODAL',
    }),
    ...mapActions('auth', ['getSocialLink']),
    async onLoginBySocial(driver) {
      try {
        const data = {
          backUrl: '/',
          driver,
          redirectUrl: `${document.location.origin}/social-login`,
        };
        const socialUrl = await this.getSocialLink(data);
        document.location.href = socialUrl;
      } catch (error) {
        return false;
      }
    },
    openAuthModal() {
      this.closeSidebar();
      this.setIsOpenedAuthModal(true);
    },
    closeSidebar() {
      this.setIsOpenedSidebar(false);
    },
  },
};
</script>

<template>
  <div
    class="custom-select"
    :class="{
      'custom-select--numeric': isNumeric,
      'custom-select--float': float,
      'custom-select--active': $attrs.value,
      'custom-select--white': styleLight,
      'custom-select--invalid': error,
      'custom-select--sm': small,
    }"
  >
    <label v-if="$slots.default" class="custom-select__label" :for="selectId">
      <slot />
    </label>
    <p v-if="error" class="custom-select__message" role="alert">
      <slot name="error" :error="error">
        {{ error }}
      </slot>
    </p>
    <client-only>
      <v-select
        :id="selectId"
        v-model="$attrs.value"
        :class="className"
        :options="options"
        :label="labelOptionName"
        :disabled="disabled"
        :clearable="false"
        :searchable="false"
        @input="$emit('input', $attrs.value)"
      >
        <template #open-indicator="{ attributes }">
          <span v-if="!disabled" v-bind="attributes">
            <svg-icon name="arrow-down" width="9" height="6" />
          </span>

          <svg
            v-if="disabled"
            v-bind="attributes"
            width="8"
            height="6"
            viewBox="0 0 8 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.7766 0.5H6.22356C7.03693 0.5 7.51006 1.41937 7.03729 2.08124L4.81381 5.19411C4.41499 5.75246 3.58517 5.75246 3.18635 5.19411L0.962871 2.08124C0.490108 1.41937 0.963232 0.5 1.7766 0.5Z"
              fill="#b2b2b2"
            />
          </svg>
        </template>
      </v-select>
    </client-only>
  </div>
</template>

<script>
import 'vue-select/dist/vue-select.css';

export default {
  name: 'CustomSelect',
  inheritAttrs: false,
  props: {
    labelOptionName: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: '',
    },
    isNumeric: {
      type: Boolean,
      default: false,
    },
    styleLight: {
      type: Boolean,
      default: false,
    },
    sizeSm: {
      type: Boolean,
      default: false,
    },
    float: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: null,
    };
  },
  computed: {
    selectId() {
      return `v-select-${this.id}`;
    },
  },
  mounted() {
    this.id = this._uid;
  },
};
</script>

<style scoped>
.v-select {
  width: 100%;
}

.custom-select {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  font-size: 16px;
}

.custom-select--numeric {
  display: inline-flex;
  min-width: 80px;
}

.custom-select >>> .vs__dropdown-toggle {
  height: 56px;
  padding: 0 22px 0 20px;
  border-radius: 0;
  flex-wrap: nowrap;
  border: 1px solid var(--color-basic-black);
  transition: border 0.3s linear;
}

.custom-select--numeric >>> .vs__dropdown-toggle {
  height: 32px;
  padding: 0 12px 0 14px;
}

.custom-select >>> .vs__selected,
.custom-select__label {
  font-size: var(--font-text-m-size);
  line-height: var(--font-text-m-height);
  letter-spacing: 0.02em;
  font-weight: 400;
  pointer-events: none;
  white-space: nowrap;
}

.custom-select__label {
  position: relative;
  color: var(--color-basic-gray);
  margin-bottom: 8px;
}

.custom-select >>> .vs__dropdown-menu {
  width: max-content;
  max-height: 264px;
  min-width: 100%;
  top: calc(100% + 6px);
  border: 0;
  border-radius: 4px;
  box-shadow: 0 4px 30px var(--color-gray-extrafade);
  padding: 0;
  overflow-y: auto;
}

.custom-select >>> .vs__dropdown-option {
  line-height: 24px;
  color: var(--color-basic-black);
  padding: 10px 45px 10px 20px;
  transition: background-color 0.3s ease-out;
}

.custom-select >>> .vs__dropdown-option--highlight {
  color: inherit;
  background-color: var(--color-basic-gray-secondary);
}

.custom-select >>> .vs__dropdown-option--selected {
  position: relative;
}

.custom-select >>> .vs__dropdown-option--selected::after {
  content: '';
  position: absolute;
  width: 14px;
  height: 11px;
  top: calc(50% - 5.5px);
  right: 25px;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.00016 7.16997L2.53477 4.70458C2.14522 4.31503 1.51359 4.3143 1.12266 4.70247C0.729784 5.09259 0.728266 5.72808 1.11977 6.11958L5.00016 9.99997L13.2949 1.7052C13.6844 1.31571 13.6844 0.684222 13.2949 0.294733C12.9056 -0.0945742 12.2745 -0.0947821 11.8849 0.294269L5.00016 7.16997Z' fill='%2312121D'/%3E%3C/svg%3E%0A");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.custom-select >>> .vs__search {
  position: absolute;
  flex-grow: 0;
  padding: 0;
}

.custom-select >>> .vs__selected {
  position: relative;
  color: var(--color-basic-black);
  letter-spacing: 0.02em;
  border: 0;
  margin: 0;
  padding: 0;
  margin-right: 20px;
  opacity: 1;
}

.custom-select >>> .vs__selected-options {
  flex-wrap: nowrap;
  padding: 0;
  max-width: 100%;
  overflow: hidden;
}

.custom-select >>> .vs__actions {
  padding: 0;
}

.custom-select >>> .vs__open-indicator {
  display: flex;
  align-items: center;
}

.custom-select__message {
  margin-top: 6px;
  font-size: var(--font-text-xs-size);
  line-height: var(--font-text-xs-height);
  letter-spacing: 0.07px;
  color: var(--color-basic-secondary-light);
}

.custom-select--invalid .custom-select__message {
  color: var(--color-accent-third);
}

.custom-select__label {
  display: inline-block;
  color: var(--color-basic-gray);
  margin-bottom: 8px;
  font-size: var(--font-text-m-size);
  line-height: var(--font-text-m-height);
}

.custom-select--float .custom-select >>> .vs__selected,
.personal .custom-select >>> .vs__selected {
  font-size: 18px;
  line-height: 21px;
}

.custom-select > .vs--disabled >>> .vs__selected {
  color: var(--color-select-text-disabled);
}

.custom-select > .vs--disabled,
.custom-select > .vs--disabled >>> .vs__dropdown-toggle,
.custom-select > .vs--disabled >>> .vs__search {
  background-color: var(--color-select-bg-disabled);
  cursor: not-allowed;
}

.personal .custom-select--float >>> .vs__selected,
.custom-select--float >>> .vs__selected {
  margin-top: 16px;
  font-size: var(--font-text-m-size);
  line-height: var(--font-text-m-height);
}

.custom-select--float.custom-select--active >>> .custom-select__label {
  top: 8px;
  left: 20px;
  font-size: var(--font-text-sm-size);
  line-height: var(--font-text-sm-height);
  margin: 0;
}

.custom-select--float > .custom-select__label {
  position: absolute;
  top: 16px;
  left: 20px;
  font-size: var(--font-text-m-size);
  line-height: var(--font-text-m-height);
  margin: 0;
  color: var(--color-basic-gray);
  z-index: 1;
  white-space: nowrap;
  transition: 0.1s;
}

.personal .custom-select >>> .vs__dropdown-toggle {
  height: 56px;
}

.custom-select--white >>> .vs__dropdown-toggle {
  background-color: var(--color-white-basic);
  border-color: var(--color-gray-basic);
}

.custom-select--float >>> .vs__selected {
  margin-top: 20px;
  font-size: var(--font-text-m-size);
  line-height: var(--font-text-m-height);
}

.custom-select--float.custom-select--active >>> .custom-select__label {
  top: 8px;
  left: 20px;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
}

.custom-select--float.custom-select--invalid .custom-select__message {
  position: absolute;
  top: 18px;
  left: 20px;
  font-size: var(--font-text-m-size);
  line-height: var(--font-text-m-height);
  margin: 0;
  z-index: 1;
}

.custom-select--float.input--active > .custom-select__label {
  z-index: 1;
}

.custom-select--float.custom-select--invalid .custom-select__label {
  display: none;
}

.custom-select--float.custom-select--active >>> .custom-select__label,
.custom-select--float.custom-select--invalid .custom-select__message {
  top: 8px;
  left: 20px;
  font-size: var(--font-text-sm-size);
  line-height: var(--font-text-sm-height);
  margin: 0;
}

.custom-select--sm >>> .vs__dropdown-toggle {
  height: 40px;
  padding: 0 16px;
}

.custom-select--sm.custom-select--float > .custom-select__label {
  top: 12px;
}

@media all and (min-width: 1280px) {
  .custom-select {
    flex-direction: row;
    align-items: center;
  }

  .select-date__times >>> .vs__dropdown-toggle {
    height: 56px;
    border: 1px solid var(--color-checkbox-disabled);
    background-color: var(--color-white-basic);
  }

  .select-date__times.custom-select--float > .custom-select__label {
    font-size: 14px;
    line-height: 116%;
  }

  .custom-select__label {
    font-size: var(--font-text-m-size);
    line-height: var(--font-text-m-height);
    margin-top: 4px;
    margin-right: 12px;
  }
}
</style>

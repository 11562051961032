
export default axios => ({
  getCurrencies() {
    try {
      return axios.$get('/v1/currencies');
    } catch (error) {
      throw new Error(`API Request Error (getCurrencies): ${error}`);
    }
  },
  setCurrencyToProfile(currencyId) {
    try {
      return axios.$put('/v1/lk/profile/personal', { currencyId })
    } catch (error) {
      throw new Error(`API Request Error (setCurrencyToProfile): ${ error }`)
    }
  },
});

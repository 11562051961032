<template>
  <div class="sign-up">
    <div class="container--modal-control sign-up__top-control">
      <div class="sign-up__wrapper">
        <div class="sign-up__top-menu font font_m font_bold font_uppercase">
          {{ $t('shared.login') }}
        </div>
        <button class="close-button" type="button" @click="closeModal">
          <svg-icon height="15" name="close" width="15" />
        </button>
      </div>
    </div>
    <div class="container--modal sign-up__body">
      <div class="font font_m">
        {{ $t('auth.loginViaSms') }}
      </div>
      <form class="sign-up__form" @submit.prevent="onSubmitPhone">
        <div class="sign-up__form-control">
          <InputMask
            key="login-phone"
            v-model="phone"
            v-focus
            :error="phoneError"
            :label="true"
            :options="phoneMaskOptions"
            data-test="phone"
            input-class="sign-up__form-input"
            label-class="font font_bold font_m"
          >
            {{ $t('shared.phoneNumber') }}
          </InputMask>
          <div class="font font_sm font_grey">
            {{ $t('auth.enterPhoneForGettingCode') }}
          </div>
          <div class="sign-up__form-agreement-body">
            <CustomCheck
              id="login-agreement-check"
              v-model="currentAgreement"
              data-test="login-agreement-check"
              name="condition"
            >
              <p class="sign-up__form-agreement font font_grey">
                {{ $t('shared.privacyPolicy.agree') }}
                <nuxt-link :to="localePath('/public_offer')" class="sign-up__form-link" target="_blank">
                  {{ $t('shared.privacyPolicy.offer') }}
                </nuxt-link>
                {{ $t('shared.and') }}
                <nuxt-link :to="localePath('/privacy_policy')" class="sign-up__form-link" target="_blank">
                  {{ $t('shared.privacyPolicy.privacyPolicy') }}
                </nuxt-link>
              </p>
            </CustomCheck>
          </div>
        </div>
        <div class="sign-up__form-action">
          <Button
            v-if="!isStartPhone"
            :disabled="isDisabledNextButton"
            data-test="get-code"
            full
            size="xl"
            type="submit"
          >
            {{ $t('shared.next') }}
          </Button>
        </div>
      </form>
    </div>

    <AuthModalFooter v-if="isStartPhone" :disabled="isDisabledNextButton" />
  </div>
</template>

<script>
import { minLength, required } from 'vuelidate/lib/validators';
import { mapActions, mapMutations } from 'vuex';
import AuthModalFooter from '@/components/auth/Modal/Footer';
import Button from '@/components/elements/Button';
import InputMask from '@/components/elements/InputMask';
import { authModalPages } from '@/plugins/enums/auth';
import { phoneMaskOptions } from '@/plugins/settings';
import CustomCheck from '~/components/elements/CustomCheck';

export default {
  name: 'AuthModalSignUpPhone',
  components: {
    CustomCheck,
    InputMask,
    AuthModalFooter,
    Button,
  },
  data() {
    return {
      authModalPages,
      phoneMaskOptions: { ...phoneMaskOptions },
      phone: '+998',
      failPhone: false,
      isDisabledGetCodeBtn: false,
      currentAgreement: true,
    };
  },
  validations: {
    phone: {
      required,
      minLength: minLength(12),
    },
    failPhone: {
      valid: (value) => value === true,
    },
  },
  computed: {
    phoneError() {
      const phone = this.$v.phone;
      let text = '';
      if (phone.$dirty && phone.$invalid) {
        if (!phone.required) text = this.$t('shared.errors.field.required');
        if (!phone.minLength) text = this.$t('shared.errors.phone.invalid');
      }

      if (!this.currentAgreement) text = this.$t('shared.errors.agreement.required');

      return text;
    },
    isStartPhone() {
      return this.phone === '+998';
    },
    isDisabledNextButton() {
      return this.isDisabledGetCodeBtn || this.$v.phone.$dirty || !!this.phoneError.length;
    },
  },
  watch: {
    phone() {
      if (this.$v.failPhone.$dirty) this.$v.failPhone.$reset();
      if (this.$v.phone.$dirty) this.$v.phone.$reset();
    },
  },
  methods: {
    ...mapMutations('auth', {
      setCurrentAuthModalPage: 'SET_CURRENT_AUTH_MODAL_PAGE',
      setAcceptedPhone: 'SET_ACCEPTED_PHONE',
    }),
    ...mapActions('auth', ['loginByPhoneSendSms']),
    toStart() {
      this.setCurrentAuthModalPage(authModalPages.INDEX);
    },
    onSubmitPhone() {
      this.$v.phone.$touch();
      if (!this.$v.phone.$invalid) {
        this.goToSendingSms();
      }
    },
    closeModal() {
      this.$emit('closeModal');
    },
    async goToSendingSms() {
      try {
        this.isDisabledGetCodeBtn = true;
        const data = {
          phone: this.phone,
        };
        await this.loginByPhoneSendSms(data);
        this.isDisabledGetCodeBtn = false;
        this.failPhone = false;
        this.setAcceptedPhone(this.phone);
        this.setCurrentAuthModalPage(authModalPages.SIGN_UP_CODE);
      } catch (error) {
        console.log(error);

        this.isDisabledGetCodeBtn = false;
        this.failPhone = true;
        this.$v.failPhone.$touch();
      }
    },
  },
};
</script>

<template>
  <div class="header-top-select__item-wrap">
    <span
      v-for="currency in $currency.available"
      :key="currency.id"
      class="header-top-select__item"
      @click="onSelectCurrency(currency.id)"
    >
      {{ currency.code }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'Currency',
  methods: {
    onSelectCurrency(id) {
      this.$currency.setCurrency(id)
      this.$emit('close')
    },
  },
}
</script>

<template>
  <div class="layout-error container">
    <header class="layout-error__header">
      <!--<Logo />-->
    </header>
    <main>
      <BackButton :route="backButtonRoute" class="layout-error__back">
        <template #icon>
          <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.5 7.77587L12.5 12.2228C12.5 13.0362 11.5806 13.5093 10.9188 13.0366L7.80589 10.8131C7.24754 10.4143 7.24754 9.58444 7.80589 9.18562L10.9188 6.96214C11.5806 6.48938 12.5 6.9625 12.5 7.77587Z"
              fill="#99999E"
            />
          </svg>
        </template>
      </BackButton>

      <template v-if="error.statusCode === 404">
        <h1 class="layout-error__title layout-error__title--desktop">
          {{ $t('shared.pageNotFound') }}
        </h1>
        <NotFound />
      </template>

      <template v-else>
        <h1 class="layout-error__title">
          {{ $t('shared.errorHasOccurred') }}
        </h1>
        <Button :to="localePath({ name: 'main' })">
          {{ $t('shared.goBackToMainPage') }}
        </Button>
      </template>
    </main>
  </div>
</template>

<script>
// import Logo from '@/components/elements/Logo';
import BackButton from '@/components/elements/BackButton';
import Button from '@/components/elements/Button';
import NotFound from '@/components/elements/NotFound';
import login from '~/mixins/login';

export default {
  name: 'Error',
  components: {
    // Logo,
    BackButton,
    NotFound,
    Button,
  },
  mixins: [login],
  layout: 'errorLayout',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    backButtonRoute() {
      return {
        name: 'main',
        title: this.$t('shared.goToMain'),
      };
    },
  },
};
</script>

<style scoped>
.layout-error {
  margin-bottom: 50px;
}

.layout-error__header {
  display: flex;
  justify-content: center;
  padding: 24px 0;
  margin-bottom: 24px;
}

@media all and (max-width: 1279px) {
  .layout-error__title--desktop {
    display: none;
  }

  .layout-error__back {
    display: none;
  }
}

@media all and (min-width: 1280px) {
  .layout-error__header {
    justify-content: flex-start;
    padding: 34px 0;
    margin-bottom: 38px;
  }

  .layout-error__back {
    margin-bottom: 8px;
  }

  .layout-error__title {
    margin: 0 0 36px;
    font-weight: 700;
    font-size: 34px;
    line-height: 117%;
    letter-spacing: -0.03em;
  }
}
</style>

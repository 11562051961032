<template>
  <ul class="mobile-menu__list">
    <li v-for="category in activeCategories" :key="`cat${category.id}`" class="mobile-menu__item">
      <div class="mobile-menu__item-wrapper">
        <nuxt-link
          :to="
            localePath({
              name: 'catalog.category',
              params: { category: category.code },
            })
          "
          class="mobile-menu__link"
          @click.native="onLinkClick"
        >
          {{ category.name }}
        </nuxt-link>
        <button
          v-if="category.items && category.items.length > 0"
          class="mobile-menu__link mobile-menu__link--chevron"
          type="button"
          @click="showCategories($event, category.code)"
        ></button>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'HeaderMobileMenuList',
  props: {
    activeCategories: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onLinkClick() {
      this.$emit('linkClick');
    },
    showCategories(event, parent) {
      this.$emit('showCategories', event, parent);
    },
  },
};
</script>

<style scoped>
.mobile-menu__item-wrapper {
  display: flex;
  width: 100%;
}
</style>

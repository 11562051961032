<template>
  <header v-scroll-lock="isOpenedMobileMenu" class="header">
    <HeaderTop />
    <HeaderMain
      v-model="isShowCategories"
      :is-scroll-offset="isScrollOffset"
      :opened-mobile-menu="isOpenedMobileMenu"
      @changeMobileMenu="onChangeMobileMenu"
    />
    <HeaderBottom
      v-model="isShowCategories"
      :is-scroll-offset="isScrollOffset"
      @linkClick="onLinkClick"
    />

    <transition name="fade">
      <HeaderMobileMenu
        v-show="isOpenedMobileMenu"
        v-model="isShowCategories"
        @linkClick="onLinkClick"
      />
    </transition>
  </header>
</template>

<script>
import HeaderTop from '~/layouts/components/Header/Top';
import HeaderMain from '~/layouts/components/Header/Main';
import HeaderBottom from '~/layouts/components/Header/Bottom';
import HeaderMobileMenu from '~/layouts/components/Header/MobileMenu';

export default {
  name: 'MainHeader',
  components: {
    HeaderMobileMenu,
    HeaderBottom,
    HeaderMain,
    HeaderTop,
  },
  props: {
    isShowHamburgerButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isScrollOffset: false,
      isOpenedMobileMenu: false,
      isShowCategories: false,
    };
  },
  computed: {
    mqIsMobile() {
      return this.$mq === 'mobile' || this.$mq === 'tablet' || this.$mq === 'mobileXs';
    },
  },
  watch: {
    mqIsMobile(value) {
      if (value) {
        if (this.isShowCategories) {
          this.isShowCategories = false
          this.isOpenedMobileMenu = true
        }
        return
      }

      if (this.isOpenedMobileMenu) {
        this.isOpenedMobileMenu = false
        this.isShowCategories = true
      }
    },
    $route() {
      this.scrollToTop();
      this.onLinkClick();
    },
    isOpenedMobileMenu(value) {
      value && (this.isShowCategories = false)
    },
  },
  mounted() {
    this.scrollToTop();
    window.addEventListener('scroll', () =>  {
      this.isScrollOffset = window.scrollY > 110;
    });
  },
  methods: {
    scrollToTop()
    {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    onLinkClick() {
      this.isShowCategories = false;
      this.isOpenedMobileMenu = false;
    },
    onChangeMobileMenu(value) {
      this.isOpenedMobileMenu = value;
    },
  },
};
</script>

<style>
@media all and (max-width: 1023px) {
  .header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    height: 50px;
  }
}
</style>



<template>
  <div class="location-selection">
    <div class="container--modal location-selection__container">
      <div class="location-selection__header">
        <button :aria-label="$t('shared.back')" class="location-selection__back" @click="close()">
          <span class="visually-hidden">
            {{ $t('shared.back') }}
          </span>
        </button>
        <h3 class="location-selection__title font font_title-m font_bold">
          {{ $t('auth.region.chooseCity') }}
        </h3>
      </div>

      <div class="location-selection__search">
        <input
          v-model="searchString"
          :placeholder="$t('auth.region.search')"
          class="location-selection__search-input"
        />
      </div>
      <div class="location-selection__list">
        <ul v-if="suggestions.length">
          <li v-for="suggestion in suggestions.slice(0, 9)" :key="suggestion.value" class="location-selection__item">
            <button class="location-selection__button font font_m" type="button" @click="selectCityHandler(suggestion)">
              {{ suggestion.address }}
            </button>
          </li>
        </ul>
        <p v-else class="location-selection__list--empty">
          {{ $t('auth.region.notFound') }}
        </p>
        <div v-if="isAddressPending" class="location-selection__list-dots">
          <LoaderDots class="cart__loader" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { suggestionTypes } from '@/plugins/enums/suggestions'
import LoaderDots from '~/components/elements/LoaderDots'
import './index.css'

export default {
  name: 'LocationSelection',
  components: { LoaderDots },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      focus: false,
      searchString: null,
      suggestions: [],
      isAddressPending: false,
    }
  },
  computed: {
    ...mapGetters({ getAddresses: 'checkout/getAddresses' }),
    ...mapState({ checkoutData: 'checkout/checkoutData' }),
    ...mapState('geolocation', [ 'visibleLocationModal' ]),
  },
  watch: {
    searchString(value) {
      this.debounce_onCityInputChange(value)
    },
    visibleLocationModal(value) {
      value && !this.suggestions.length && this.onCityInputChange()
    },
  },
  created() {
    this.onCityInputChange()
  },
  beforeMount() {
    this.debounce_onCityInputChange = _debounce(this.onCityInputChange, 300)
  },
  methods: {
    ...mapActions({
      setSelectedCity: 'geolocation/setSelectedCity',
      findAddress: 'geolocation/findAddress',
      setAddress: 'checkout/setAddress',
      setIsConfirmedCity: 'geolocation/setIsConfirmedCity',
      selectCity: 'geolocation/selectCity',
    }),
    ...mapMutations({
      setCityFias: 'geolocation/SET_CITY_FIAS',
    }),
    async onCityInputChange(query = '') {
      try {
        const suggestQuery = query || suggestionTypes.CITY
        const { suggestions } = await this.findAddress({
          fromBound: suggestionTypes.AREA,
          toBound: suggestionTypes.SETTLEMENT,
          query: suggestQuery,
          count: 20,
        })
        // TODO сделал фильтрацию, чтобы убрать дубли городов
        this.suggestions = suggestions
          .filter(({ data: { geo_lat: lat, geo_lon: lon } }, idx) => {
            return suggestions.findIndex(el => el.data.geo_lat === lat && el.data.geo_lon === lon) === idx
          })
          .map(el => ({
            ...el,
            address: new Set([
              el.data.settlement_with_type,
              el.data.city_with_type,
              el.data.area_with_type,
              el.data.region_with_type,
            ]).filter(Boolean).join(', '),
          }))
      } catch (error) {
        console.error(error)
        return null
      }
    },
    async selectCityHandler(suggestion) {
      if (this.isAddressPending) return
      this.isAddressPending = true

      await this.selectCity(suggestion)

      this.isAddressPending = false
      this.close()
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>

<template>
  <ul class="menu__list header-bottom__menu-list">
    <li class="menu__item">
      <MenuToggle v-model="isShowCategories" :hide-text-on-start="false">
        {{ $t('shared.breadcrumbs.catalog') }}
      </MenuToggle>
    </li>

    <li v-for="(menuItem, menuIdx) in menuMain" :key="`m-${menuIdx}`" class="menu__item">
      <a v-if="isCurrentRoute(menuItem.url)" class="menu__link font_sm font_uppercase">
        {{ menuItem.name }}
      </a>

      <nuxt-link v-else :to="localePath(menuItem.url)" class="menu__link font_sm font_uppercase">
        {{ menuItem.name }}
      </nuxt-link>
    </li>
    <li class="menu__item menu__item--scroll">
      <div>
        <button :aria-label="$t('header.search')" data-test="show-search" @click="showSearch">
          <svg-icon fill="#ffffff" height="21" name="search" width="21" />
        </button>
      </div>

      <template v-if="hasSession">
        <client-only>
          <div>
            <nuxt-link :to="localePath({ name: 'profile' })">
              <svg-icon height="24" name="account-middle" width="24" />
            </nuxt-link>
          </div>
        </client-only>
      </template>
      <div v-else>
        <button :aria-label="$t('shared.login')" data-test="log-in" @click="openAuthModal">
          <svg-icon height="24" name="account-middle" width="24" />
        </button>
      </div>

      <div>
        <nuxt-link :aria-label="$t('header.cart')" :to="localePath({ name: 'cart' })">
          <span class="header-user__cart-count">
            {{ getCartAllItemsCount.all }}
          </span>
          <svg-icon height="24" name="cart" width="27" />
        </nuxt-link>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import MenuToggle from '~/layouts/components/Header/MenuToggle';

export default {
  name: 'HeaderBottomMenu',
  components: {
    MenuToggle,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState('auth', ['hasSession']),
    ...mapGetters({
      menuMain: 'getMenuMain',
      getCartAllItemsCount: 'cart/getCartAllItemsCount',
    }),
    isShowCategories: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    ...mapActions({
      setIsOpenedSearch: 'setIsOpenedSearch',
    }),
    ...mapMutations('auth', {
      setIsOpenedAuthModal: 'SET_IS_OPENED_AUTH_MODAL',
    }),
    isCurrentRoute(url) {
      const isCatalogUrl = url === '/catalog';

      return (
        url === this.$route.path || (isCatalogUrl && this.$route.path.includes(url) && !this.$route.params.product)
      );
    },
    showSearch() {
      this.setIsOpenedSearch(true);
    },
    openAuthModal() {
      this.setIsOpenedAuthModal(true);
    },
  },
};
</script>

<style scoped>
@media all and (min-width: 1024px) {
  .menu__item--scroll {
    display: none;
    white-space: nowrap;
  }

  .menu__item--scroll > div {
    margin: 0 10px;
    position: relative;
    display: inline-block;
  }

  .menu__item--scroll svg {
    fill: var(--color-white);
  }

  .header-bottom--scroll .menu__item--scroll {
    display: block;
  }

  .header-bottom--scroll .header-user__cart-count {
    background: var(--color-basic);
  }

  .menu__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .menu__link {
    display: inline-block;
    margin-left: 4px;
    font-weight: 800;
    font-size: 14px;
    color: var(--color-white);
    line-height: 20px;
  }

  .header-bottom__menu-list {
    height: var(--height-nav-mobile);
  }
}
</style>
